import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  isEmpty
} from './_helpers';
import { Icon } from '../css/_styledComponents';

const StyledButton = styled.button`
  position: relative;
  display: inline-block;
  background-color: transparent;
  ${props => (props.styleType === 'text' ? `
    color: var(--color-primary);
  ` : `
    color: var(--color-white);
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: block;
      border-radius: ${props.size === 'sm' && !props.hasText ? 'var(--radius-small)' : 'var(--radius-main)'};
      ${props.theme === 'gold' ? 'background-color: var(--color-primary);' : ''}
      ${props.theme === 'red' ? 'background-color: var(--color-warning);' : ''}
      ${props.theme === 'yellow' ? 'background-color: var(--color-stoplight-yellow);' : ''}
      ${props.theme === 'green' ? 'background-color: var(--color-secondary);' : ''}
    }
  `)}
  font-family: var(--font-family-body);
  border: none;
  margin: 0px 2px;
  ${props => (props.align === 'left' ? `
    margin-right: auto;
    margin-left: 0.5em;
  ` : ``)}
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  line-height: 1;
  span {
    position: relative;
  }
  &:focus {
    ${props => (props.styleType === 'text' ? `
      color: hsl(var(--color-primary-triadic1-hue), calc(var(--color-primary-triadic1-saturation) - 1%), calc(var(--color-primary-triadic1-lightness) + 6%));
    ` : `
      &:before {
        background-color: hsl(var(--color-primary-triadic1-hue), calc(var(--color-primary-triadic1-saturation) - 1%), calc(var(--color-primary-triadic1-lightness) + 6%));
        ${props.theme === 'green' ? 'background-color: hsl(var(--color-primary-hue), calc(var(--color-primary-saturation) + 10%), calc(var(--color-primary-lightness) + 6%));' : ''}
      }
    `)}
  }
  &:hover {
    &:before {
      animation: bounce .25s ease;
    }
    .btnIcon:before {
      transition: all .55s ease;
      animation: bounceBig 0.5s ease;
    }
    ${props => (props.styleType === 'text' ? `
      color: hsl(var(--color-secondary-hue), var(--color-secondary-saturation), calc(var(--color-secondary-lightness) + 12%));
      .btnIcon:before {
        background-color: hsl(var(--color-primary-triadic1-hue), calc(var(--color-primary-triadic1-saturation) - 1%), calc(var(--color-primary-triadic1-lightness) + 6%));
      }
    ` : `
      &:before {
        background-color: hsl(var(--color-secondary-hue), var(--color-secondary-saturation), calc(var(--color-secondary-lightness) + 12%));
        ${props.theme === 'green' ? 'background-color: hsl(var(--color-primary-hue), calc(var(--color-primary-saturation) + 10%), calc(var(--color-primary-lightness) + 6%));' : ''}
      }
    `)}
  }
  &:disabled {
    cursor: default;
    .btnIcon:before {
      animation: none;
      background-color: var(--color-disabled);
    }
    ${props => (props.styleType === 'text' ? `
      color: var(--color-disabled);
    ` : `
      &:before {
        animation: none;
        background-color: var(--color-disabled);
      }
    `)}
    ${props => (props.styleType !== 'text' ? `
      .btnIcon:before {
        background-color: var(--color-white);
      }
    ` : `
    `)}
  }
  {/* defaults and prop overrides */}
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  ${props => props.icon && `
    ${props.hasText ? `
     padding-left: ${props.size === 'sm' ? '22px' : '32px'};
    ` : `
      padding-left: ${props.size === 'sm' ? '10px' : '21px'};
      padding-right: ${props.size === 'sm' ? '10px' : '21px'};
    `}
  `}
  ${props => (props.icon && props.hasText && props.iconPos === 'right' ? `
    padding-left: 10px;
  ` : ``)}
  font-size: 1.6rem;
  height: 42px;
  ${props => (
    props.size === 'sm' && `
      height: 20px;
      font-size: 1rem
    `
  )};
`;

export class Button extends React.PureComponent {
  render () {
    const {
      iconSize,
      children,
      iconPos,
      type,
      icon
    } = this.props;
    const hasIcon = !isEmpty(icon);
    const iconComp = hasIcon && (
      <Icon
        className="btnIcon"
        icon={icon.src_color}
        color={type === 'text' ? 'var(--color-primary)' : 'var(--color-white)'}
        style={{
          pointerEvents: 'none',
          position: 'absolute',
          top: '50%',
          transform: `translate(${children ? '0' : '-50%'}, -50%)`,
          ...(iconPos === 'right' && children
            ? { right: '0' }
            : { left: children ? '5px' : '50%' })
        }}
        {...iconSize && { iconSize }}
        useMask
      />
    );
    return (
      <StyledButton
        {...this.props}
        hasText={!!children}
        /**
         * If a <button> is a child of <form>, the `type` prop will default to `submit`
         * if not passed or invalid (eg, `type="text"`). So if we are passing `type="text"`
         * as a prop into <Button />, it must actually be passed to <StyledButton /> as
         * `type="button"` instead to prevent the default <form> POST behavior (which is
         * either form submission or appending form field params to the url).
         * To keep necessary styling in tact, `styleType` is passed as the original `type` value.
        */
        type={(type === 'submit' || type === 'reset') ? type : 'button'}
        styleType={type}
      >
        {iconPos === 'left' && hasIcon && iconComp}
        {children && (
          <span style={{ ...(iconPos === 'right' && hasIcon && { paddingRight: '1.25em' }) }}>
            {children}
          </span>
        )}
        {iconPos === 'right' && hasIcon && iconComp}
      </StyledButton>
    );
  }
}

Button.propTypes = {
  size: PropTypes.string,
  iconSize: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  iconPos: PropTypes.oneOf(['left', 'right']),
  type: PropTypes.oneOf(['button', 'submit', 'reset', 'text']),
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  theme: PropTypes.string
};

Button.defaultProps = {
  size: null,
  iconSize: null,
  icon: null,
  iconPos: 'left',
  type: 'button',
  children: null,
  theme: 'gold'
};

export default Button;
