import React from 'react';
import PropTypes from 'prop-types';
import D3Chart from './D3/D3Chart';
import D3Donut from './D3/D3Donut';
import D3Radial from './D3/D3Radial';
import D3Tree from './D3/D3Tree';
import D3PieChart from './D3/D3PieChart';

import {
  isEmpty,
  isEqual,
  setColors
} from './_helpers';
import { addToLocalDB, getFromLocalDB } from './_dataCompression';
import '../css/D3Styles.css';

import D3Legend from './D3/D3Legend';
import { Checkbox } from './Checkbox';
import { FlexBoxWrap } from '../css/_styledComponents';
import { NoData } from './NoData';

export class ChartWrapper extends React.Component {
  constructor (props) {
    super(props);
    const { type } = props;
    this.mounted = false;
    this.chartArea = React.createRef();
    this.pieTypes = ['pie', 'radial', 'donut'];
    this.useCheckbox = type !== 'tree' && !this.pieTypes.includes(type);
    this.state = {
      dataWithColors: null,
      legend: null,
      hideEmpty: true,
      enableCheckbox: true
    };
  }

  componentDidMount () {
    this.mounted = true;
    const { data } = this.props;
    !isEmpty(data) && this.setDataWithColors();
  }

  componentDidUpdate (prevProps) {
    const { data } = this.props;
    !isEqual(data, prevProps.data) && this.setDataWithColors();
  }

  componentWillUnmount () {
    this.mounted = false;
  }

  updateState = (state, callback = null) => {
    this.mounted && this.setState(state, callback);
  }

  setDataWithColors = async () => {
    const {
      axiosRequest,
      data = {},
      type = '',
      legendPosition = 'bottom'
    } = this.props || {};
    const {
      header = {}
    } = data || {};
    const {
      lines = [],
      mirrorColors = [],
      label = ''
    } = header;
    // set colors here so all sub components use same ones
    let dataWithColors;
    if (this.pieTypes.includes(type)) {
      dataWithColors = { ...data };
      dataWithColors.colors = {};
      (this.getDonutDataArray(data)).forEach((d) => {
        dataWithColors.colors[d.name] = d.fill;
      });
    } else if (type === 'tree') {
      // no colors for the tree, we only want the data not headers right now
      dataWithColors = { ...data, colors: {} };
    } else {
      const existingColors = await getFromLocalDB('guidColorMap', { axiosRequest });
      const options = {
        ...(!isEmpty(existingColors) && { existingColors }),
        ...(!isEmpty(mirrorColors) && { mirrorColors })
      };
      const colors = setColors(data.data, lines, label, options);
      await addToLocalDB('guidColorMap', colors, { axiosRequest });
      dataWithColors = {
        ...data,
        colors,
        ...(legendPosition === 'right' && {
          header: {
            ...data?.header,
            height: '340'
          }
        })
      };
    }
    const allData = this.useCheckbox ? this.clearEmptyData(dataWithColors.data, header) : {};
    const { filteredData, enableCheckbox } = allData || {};
    const formattedDataWithColors = {
      ...dataWithColors,
      ...(this.useCheckbox && { data: filteredData })
    };
    const legend = this.pieTypes.includes(type)
      ? this.donutLegend(formattedDataWithColors)
      : formattedDataWithColors;
    this.updateState({
      dataWithColors: formattedDataWithColors,
      legend,
      enableCheckbox: this.useCheckbox ? enableCheckbox : false
    });
  }

  clearEmptyData = (data, header) => {
    const { hideEmpty } = this.state;
    const { label: headerLabel = 'noLabel' } = header || {};
    const allKeys = isEmpty(data?.[0])
      ? []
      : Object.keys(data?.[0]);
    const filteredKeys = allKeys.filter(aKeyToCheck => data
      .some(aDataObject => aDataObject[aKeyToCheck]));
    if (!hideEmpty) {
      return { enableCheckbox: filteredKeys.length !== allKeys.length, filteredData: data };
    }
    const filteredData = filteredKeys.length === allKeys.length
      ? data
      : data.map(aRowValue => filteredKeys.reduce((acc, filteredKey) => {
        acc[filteredKey] = aRowValue[filteredKey];
        return acc;
      }, {}));
    const removeEmptyColumns = hideEmpty
      ? filteredData.reduce((acc, column) => {
        const { [headerLabel]: label, ...rest } = column;
        return Object.entries(rest)
          .some(([key, value]) => !isEmpty(value)) ? [...acc, column] : acc;
      }, [])
      : filteredData;
    // if a date based chart has NO data, don't show chart, if it has at least some data
    // don't remove any dates to keep the logical flow of the chart
    const specialCaseForDates = isEmpty(removeEmptyColumns) || (!isEmpty(removeEmptyColumns) && headerLabel !== 'date')
      ? removeEmptyColumns
      : filteredData;
    return {
      enableCheckbox: filteredKeys.length !== allKeys.length,
      filteredData: specialCaseForDates
    };
  }

  donutLegend = (data) => {
    const newData = { ...data };
    newData.data = [];
    (this.getDonutDataArray(data)).forEach((d) => {
      newData.data.push({ [d.name]: d.value });
    });
    return newData;
  }

  getDonutDataArray = data => (data.data ? data.data.rings || data.data || [] : [])

  handleChangeCheckbox = (id, checked) => {
    this.updateState({ [id]: checked }, () => this.setDataWithColors());
  }

  render () {
    const {
      id,
      type,
      data,
      wrapperStyle,
      callback,
      preserveAspect,
      options,
      legendPosition
    } = this.props;
    const { header } = data || {};
    const {
      legendPreText,
      showLegend = true,
      height
    } = header || {};
    const {
      legend,
      dataWithColors,
      hideEmpty,
      enableCheckbox
    } = this.state;
    return (
      <div
        className="d3ChartWrapper"
        id={id}
        style={{
          position: 'relative',
          ...(!preserveAspect && { minHeight: `${height}px`, height: '100%' }),
          display: 'flex',
          alignItems: 'flex-start',
          flexDirection: legendPosition === 'right' ? 'row' : 'column',
          flexWrap: 'wrap',
          gap: '10px',
          ...wrapperStyle
        }}
      >
        {this.useCheckbox
          ? (
            <FlexBoxWrap style={{ width: '100%', flexDirection: 'column', gap: '0.2em' }}>
              { enableCheckbox && (
                <Checkbox
                  callback={this.handleChangeCheckbox}
                  height="20"
                  type="mini"
                  name="hideEmpty"
                  id="hideEmpty"
                  label="Hide Empty Graph Data"
                  checked={hideEmpty}
                  wrapperStyle={{ width: 'fit-content' }}
                />
              )}
              {type === 'composed' && (
                <>
                  { !isEmpty(dataWithColors?.data)
                    ? (
                      <D3Chart
                        chartData={dataWithColors}
                        options={{
                          ...options,
                          stacked: true
                        }}
                        legendPosition={legendPosition}
                        preserveAspect={preserveAspect}
                        callback={callback}
                      />
                    )
                    : (
                      <NoData customMessage="No graph data available" />
                    )
                  }
                </>
              )}
            </FlexBoxWrap>
          )
          : (
            <>
              {type === 'donut' && dataWithColors && (
                <D3Donut
                  chartData={dataWithColors}
                  callback={callback}
                  preserveAspect={preserveAspect}
                  options={options}
                />
              )}
              {type === 'pie' && dataWithColors && (
                <D3PieChart
                  chartData={dataWithColors}
                  callback={callback}
                  preserveAspect={preserveAspect}
                  options={options}
                />
              )}
              {type === 'radial' && (
                <D3Radial
                  chartData={data}
                  callback={callback}
                  options={options}
                />
              )}
              {type === 'tree' && dataWithColors && (
                <D3Tree
                  treeId={id}
                  chartData={dataWithColors}
                  callback={callback}
                  options={options}
                />
              )}
            </>

          )
        }
        {(type !== 'tree' && showLegend) && !isEmpty(legend?.data) && (
          <D3Legend
            payload={legend}
            preText={legendPreText}
            preserveAspect={preserveAspect}
            legendPosition={legendPosition}
            {...options?.postText ? { postText: options.postText } : {}}
          />
        )}

      </div>
    );
  }
}

ChartWrapper.propTypes = {
  wrapperStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  data: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  options: PropTypes.oneOfType([PropTypes.object]),
  id: PropTypes.string,
  type: PropTypes.string,
  axiosRequest: PropTypes.func,
  callback: PropTypes.func,
  preserveAspect: PropTypes.bool,
  legendPosition: PropTypes.string
};
ChartWrapper.defaultProps = {
  wrapperStyle: {},
  data: {},
  options: {},
  id: null,
  type: 'composed',
  axiosRequest: () => {},
  callback: () => {},
  preserveAspect: true,
  legendPosition: 'bottom'
};

export default ChartWrapper;
