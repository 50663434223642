import React from 'react';
import PropTypes from 'prop-types';
import crabExternalCommunicationTemplate from './data/sharedBoarding/templates/crabExternalCommunicationTemplate';
import ticketExternalCommunicationTemplate from './data/tickets/templates/ticketsExternalCommunicationTemplate';
import AddExternalCommunicationForm from './AddExternalCommunicationForm';
import { apiCall } from './_api';
import { Spinner, Button, CustomApiErr } from '../index';
import { ToolTip } from './ToolTip';
import { icons } from '../images/_icons';
import { notesCSS } from './_styles';
import { DataBox } from './DataBox';
import {
  isEmpty,
  transformData,
  envIsLocalOnly,
  formatDateForFEView
} from './_helpers';

class DataBoxExternalCommunication extends React.Component {
  constructor (props) {
    super(props);
    this.mounted = false;
    this.maxCommunications = 5;
    this.state = {
      spinnerLoading: false,
      communicationList: [],
      err: false,
      status: null,
      showMore: false
    };
  }

  componentDidMount () {
    this.mounted = true;
    this.setCommunicationList();
  }

  componentDidUpdate (prevProps) {
    const { guid, timestamp } = this.props;
    if (
      (prevProps.guid !== guid && !isEmpty(guid)) ||
      (timestamp > prevProps.timestamp)
    ) {
      this.setCommunicationList();
    }
  }

  componentWillUnmount () {
    this.mounted = false;
  }

  getCommunicationList = async () => {
    const {
      options = {},
      guid,
      requestGuidType,
      communicationEndpoint
    } = this.props;
    const {
      utils,
      getMockData
    } = options || {};
    const sharedOptions = {
      fullPageLoad: false,
      url: communicationEndpoint,
      method: 'get',
      tokenRequired: true,
      requestGuid: { [requestGuidType]: guid }
    };
    const apiRes = await apiCall({
      ...sharedOptions,
      ...(envIsLocalOnly() && {
        stubData: await getMockData({
          ...sharedOptions
        })
      })
    }, utils);
    return apiRes;
  };

  setCommunicationList = async () => {
    const {
      employeeList,
      userType,
      userEmail,
      guid,
      requestGuidType
    } = this.props;
    if (!isEmpty(guid)) {
      this.updateState({ spinnerLoading: true });
      const apiResponse = await this.getCommunicationList();
      const resData = apiResponse?.data || {};
      if (apiResponse?.errorDetails instanceof Error) {
        this.updateState({
          ...apiResponse.state,
          communicationList: []
        });
      }
      this.updateState({
        ...apiResponse?.state,
        communicationList: transformData({
          data: {
            ...resData,
            ...(userType === 'employee' && {
              employeeList
            }),
            userEmail,
            userType,
            requestGuidType,
            showMessageLink: requestGuidType !== 'applicationPendId'
          },
          toSchema: 'frontend',
          version: '1.0',
          template: crabExternalCommunicationTemplate
        })
      });
    }
  }

  updateState = async (state) => {
    this.mounted && this.setState(state);
  }

  addExternalCommunicationHandler = () => {
    const {
      userType,
      siteModal,
      guid,
      requestGuidType,
      options,
      axiosRequest,
      communicationEndpoint
    } = this.props;
    const guidKey = requestGuidType || 'pendId';
    const formProps = {
      userType,
      axiosRequest,
      requestGuid: { [guidKey]: guid },
      callback: this.handleCallback,
      options,
      communicationEndpoint,
      template: requestGuidType === 'ticketId' ? ticketExternalCommunicationTemplate : crabExternalCommunicationTemplate
    };
    siteModal(true, 'Add An External Communication', <AddExternalCommunicationForm {...formProps} />, {
      size: 'medium',
      closeModalCallback: this.handleCloseModal
    });
  }

  handleCloseModal = () => {
    const { siteModal } = this.props;
    siteModal(false);
  }

  handleCallback = () => {
    const { callback } = this.props;
    this.handleCloseModal();
    this.setCommunicationList();
    callback && callback();
  }

  handleShowMore = () => {
    this.updateState(prev => ({ showMore: !prev.showMore }));
  }

  render () {
    const {
      spinnerLoading,
      communicationList,
      err,
      status,
      showMore
    } = this.state;
    const {
      type,
      wrapperStyle,
      userType,
      page,
      pendMessageCallback,
      canAdd,
      collapsible
    } = this.props;
    return (
      <DataBox
        id="external-communication"
        type={type}
        {...userType === 'employee' && {
          className: 'warning'
        }}
        style={wrapperStyle}
        title={(
          <>
            External Communication
            {userType === 'employee' && (
              <div style={{ position: 'relative', marginLeft: '1em' }}>
                <ToolTip
                  infoTip
                  iconColor="var(--color-white)"
                  infoTipDisplay={{
                    ...icons.centered,
                    transform: 'translate(-50%, -100%)'
                  }}
                >
                  These communications are visible to partners.
                </ToolTip>
              </div>
            )}
          </>
        )}
        {...((page === 'crm' && userType === 'employee' && canAdd) || (page === 'portal' && canAdd)) && {
          // If we're on the CRM and the user is Operations, or if we're on the portal,
          // display the add button.
          buttonCallback: this.addExternalCommunicationHandler
        }}
        collapsible={collapsible}
      >
        <Spinner loading={spinnerLoading} />
        <div className="sticky-note-container">
          { !isEmpty(communicationList) && communicationList instanceof Array
            ? (communicationList.slice(
              0,
              showMore
                ? communicationList.length
                : this.maxCommunications
            ).map((item, index) => (
              <div id={`${item.communicationId}`} className="sticky-note" style={{ ...notesCSS.stickyNote, ...(userType === 'partner' && { padding: 0 }) }} key={`${item.communicationId}`}>
                <div style={{ display: 'flex' }}>
                  <div className="sticky-date" style={notesCSS.stickyDate}>{formatDateForFEView(item?.timestamp, { includeTime: true })}</div>
                  {!isEmpty(item.showMessageButton?.value) && (
                    <Button
                      id={`pend-message-button-${index + 1}`}
                      type="text"
                      onClick={() => pendMessageCallback(item.showMessageButton.value)}
                      style={{ fontSize: '1.2rem', height: 'auto' }}
                    >
                      {item.showMessageButton?.title || 'Pend Message'}
                    </Button>
                  )}
                </div>
                <div className="sticky-arrow-container" style={{ ...notesCSS.stickyArrowContainer, ...(index === communicationList.length - 1 && { borderLeftWidth: '0', borderLeftStyle: 'none', borderLeftColor: 'unset' }) }}>
                  <div className="sticky-arrow" style={(index === communicationList.length - 1) ? { ...notesCSS.stickyArrow, display: 'none' } : notesCSS.stickyArrow}>&#9650;</div>
                  <div className="sticky-description" style={{ ...notesCSS.stickyDescription, ...(item.markAsYou && { backgroundColor: 'var(--color-sage)', border: '1px solid var(--color-granite-green)' }) }}>
                    <span className="sticky-owner" style={{ fontWeight: '800', wordBreak: 'break-all' }}>{ item.markAsYou ? 'You' : item.from }</span>
                    {' - '}
                    {item.displayedDescription || 'No text'}
                  </div>
                </div>
              </div>
            )))
            : <CustomApiErr customErr={err} status={status} loading={spinnerLoading} />
          }
          {communicationList && communicationList.length > this.maxCommunications && (
            <Button
              id="show-more-communications-button"
              type="text"
              onClick={this.handleShowMore}
              style={notesCSS.showMore}
            >
              {showMore ? 'Show less' : 'Show more'}
            </Button>
          )}
        </div>
      </DataBox>
    );
  }
}

DataBoxExternalCommunication.propTypes = {
  type: PropTypes.oneOf(['default', 'white']),
  guid: PropTypes.string,
  options: PropTypes.shape({
    utils: PropTypes.shape({ // REQUIRED, utils to handle the store
      isTokenValid: PropTypes.func, // REQUIRED, to check valid token in the store
      handleApiError: PropTypes.func, // REQUIRED, to handle api error in the store
      createCsrfHeader: PropTypes.func // REQUIRED, to check user's token from the store
    }),
    getMockData: PropTypes.func // mock data getter method, only used in local dev
  }),
  axiosRequest: PropTypes.func.isRequired,
  siteModal: PropTypes.func,
  pendMessageCallback: PropTypes.func,
  callback: PropTypes.func,
  timestamp: PropTypes.number,
  userEmail: PropTypes.string,
  userType: PropTypes.oneOf(['partner', 'employee']),
  page: PropTypes.string,
  canAdd: PropTypes.bool,
  requestGuidType: PropTypes.string,
  communicationEndpoint: PropTypes.string,
  employeeList: PropTypes.oneOfType([PropTypes.array]),
  wrapperStyle: PropTypes.oneOfType([PropTypes.object]),
  collapsible: PropTypes.bool
};
DataBoxExternalCommunication.defaultProps = {
  type: 'default',
  guid: null,
  options: {
    utils: {
      isTokenValid: () => {},
      handleApiError: () => {},
      createCsrfHeader: () => {}
    },
    getMockData: null
  },
  siteModal: () => {},
  pendMessageCallback: () => {},
  callback: null,
  timestamp: 0,
  userEmail: '',
  userType: 'partner',
  page: 'portal',
  canAdd: false,
  requestGuidType: '',
  communicationEndpoint: '',
  employeeList: [],
  wrapperStyle: {},
  collapsible: false
};

export default DataBoxExternalCommunication;
