import { isEmpty } from '@f1/shared/src/_helpers';
import { mockRelationships } from '../_mockDataHelpers';
import { createMockPartnerPortalAccessList } from '../delegate/mockDelegate';
import { creatMockMerchantPortalAccess } from '../merchant/delegate';
import { partnerPortalGuids } from '../guids';

// -- START Partner AND Merchant Portal --
export const users = {
  invalidUser: {
    email: 'invalidUser@test.com',
    phone: '12223334444',
    pw: 'theWrongPassword@123',
    code: '1234'
  },
  merchant: {
    phoneUnconfirmed: {
      email: 'f1paymentsengineering+1@gmail.com',
      pw: '123F1payments!@#',
      phone: '15125536624',
      code: '1234'
    },
    emailUnconfirmed: {
      email: 'f1paymentsengineering+2@gmail.com',
      pw: '123F1payments!@#',
      phone: '15125536624',
      code: '1234'
    },
    singleMid: {
      email: 'f1paymentsengineering+3@gmail.com',
      phone: '15125536624',
      pw: '123F1payments!@#'
    },
    multiMid: {
      email: 'f1paymentsengineering+4@gmail.com',
      pw: '123F1payments!@#'
    },
    withDelegateAccess: {
      email: 'f1paymentsengineering+merchant_withdelegateaccess@gmail.com',
      phone: '15125536624',
      pw: '123F1payments!@#'
    },
    noDelegateAccess: {
      email: 'f1paymentsengineering+merchant_nodelegateaccess@gmail.com',
      phone: '15125536624',
      pw: '123F1payments!@#'
    }
  },
  partner: {
    noSubpartnersNoMids: {
      email: 'createtestusershandler_nomerchantnosubpartner@gmail.com',
      pw: '123F1payments!@#'
    },
    noMerchantNoRelatonships: {
      email: 'createtestusershandler_nomerchantnorelationships@gmail.com',
      pw: '123F1payments!@#'
    },
    noPermissions: {
      email: 'noPermissionsTestUser@example.com',
      pw: '123F1payments!@#'
    },
    delegateWithPermissionToCreateDelegates: {
      email: 'createtestusershandler_withdelegateandadddelegateaccess@gmail.com',
      pw: '123F1payments!@#'
    },
    delegateWithDelegates: {
      email: 'createtestusershandler_withdelegates@gmail.com',
      pw: '123F1payments!@#'
    },
    noDelegateAccess: {
      email: 'createtestusershandler_nodelegateaccess@gmail.com',
      pw: '123F1payments!@#'
    },
    delegateWithPermissionToDeleteSelf: {
      email: 'createtestusershandler_withdeleteaccess@gmail.com',
      pw: '123F1payments!@#'
    },
    hasMerchantsNoSubpartners: {
      email: 'createtestusershandler_withdirectmerchantsandnosubpartners@gmail.com',
      pw: '123F1payments!@#'
    },
    hasSubpartnersButNoMerchants: {
      email: 'createtestusershandler_withsubpartnersandnodirectmerchants@gmail.com',
      pw: '123F1payments!@#'
    },
    hasSubpartnersWithMerchants: {
      email: 'createtestusershandler_withmultilevel@gmail.com',
      pw: '123F1payments!@#'
    },
    hasReportData: {
      email: 'createtestusershandler_withreportdata@gmail.com',
      pw: '123F1payments!@#'
    },
    hasAllDashboardData: {
      email: 'createtestusershandler_withalldashboarddata@gmail.com',
      pw: '123F1payments!@#'
    }
  },
  garbageAccount: {
    email: 'garbage@f1payments.com',
    pw: 'f1Garbage#1',
    phone: '15555555555'
  }
};

const mockFrontendCsrfToken = 'mockFrontendCsrfToken'; // Must be this exact value for FTs

const userToMerchantGuidMap = { // Top-level `merchantGuidToDba` object
  [users.merchant.emailUnconfirmed.email]: { '8a8eda8f-2315-4d5f-b22d-af2aecea4b74': 'WKkpmQGoUbAF' },
  [users.merchant.singleMid.email]: { '8a8eda8f-2315-4d5f-b22d-af2aecea4b74': 'WKkpmQGoUbAF' },
  [users.merchant.multiMid.email]: {
    '84d2dfa3-f22b-43fc-b631-024bbd3c0e53': 'HOBhusRpqwoE',
    '6fd90b65-5453-4a8f-869a-24e0d0b35042': 'VcITPEFSTltF',
    '7b154a14-2f97-4657-987e-ed5c5ae9b833': 'iBYUppkANdge',
    'bcafe682-210c-4377-91c7-ef60193e8597': 'TYpwylgHvSNR',
    '932be7e3-ae6a-441a-9022-d0427bfa0bf1': 'KrUPeqonBLkX',
    '2a389c0e-1ae0-4b21-915b-f021bc7cbb15': 'XKdFTBAuHvmF'
  },
  [users.merchant.withDelegateAccess.email]: { '83c30b5a-e330-40c8-9189-4c920aee71b1': 'WKkpmQGoUbAF' },
  [users.merchant.noDelegateAccess.email]: { 'a52dddba-ac2d-48cd-9ff5-93a879dab6f8': 'WKkpmQGoUbAF' },
  [users.partner.hasSubpartnersWithMerchants.email]: {
    '831baaf3-43cb-4102-bb7d-e9237bc11bc8': 'FKrIUfRJkQbPJZa',
    'cfdc43ad-bcf4-47bd-92a9-8385123f9c20': 'TwPegK0aRtKFpWw',
    '3a7655ef-b3a4-445d-8fec-b8ea75ae3c44': '4gEnDgwptflvkP3'
  },
  [users.partner.hasAllDashboardData.email]: {
    '6758be57-3521-4a7c-acf6-6119abcd30ed': 'CTUH_ADD_1_closed-4',
    '1c66c251-bd77-45ad-b55f-d43f8d9c3e35': 'CTUH_ADD_1_noStart',
    '62ad63f7-9d2d-49c4-8ce6-18ecbc932beb': 'CTUH_ADD_1_closed-2',
    'f0c4a921-5dd3-4b95-adca-92f7852db0df': 'CTUH_ADD_2_closed-4',
    '18a2a63d-1742-4799-8ef6-4bba1f65d6c1': 'CTUH_ADD_2_TPPS_txnBarGraph',
    [partnerPortalGuids.merchantGuids.secondWithData]: 'CTUH_ADD_1_opened-1',
    'a46f0195-3be1-4445-953f-1c6b78d120d8': 'CTUH_ADD_2_closed-6',
    'f680866d-8393-471c-ae7f-98284b60ab66': 'CTUH_ADD_2_closed-2',
    '3b103038-7a07-498a-9a7f-9c528ffc6797': 'CTUH_ADD_2_TPPS_txnBarGraph',
    '98c1b001-598c-4364-a9e7-8e09f6513b22': 'CTUH_ADD_2_opened-4',
    'c3edde32-3696-4894-814b-43f7baadf40e': 'CTUH_ADD_2_closed-1',
    'f6d29a7b-0618-486b-97d4-1a7a3eb8a4bb': 'CTUH_ADD_2_opened-3',
    'ffe6add5-aa9d-4257-8513-aeb45173ef8d': 'CTUH_ADD_2_opened-6',
    '1f0b2083-8c66-4574-bdb0-b25a19712fdc': 'CTUH_ADD_2_TPPS_income',
    'd229718d-9960-4e53-b0dd-3e4cf4c1c0df': 'CTUH_ADD_2_opened-1',
    'b2164c74-662f-4e92-8a69-a7046e04bb1f': 'CTUH_ADD_2_shutdown',
    '2879b631-f000-4702-b7b6-e3eb6046a706': 'CTUH_ADD_1_TPPS_income',
    '105c2c01-e537-4608-872f-37666fc4f42a': 'CTUH_ADD_2_closed-3',
    'b4b4a37f-5eb6-44ee-b73f-5b519ecc40c3': 'CTUH_ADD_2_opened-3',
    [partnerPortalGuids.merchantGuids.default]: 'CTUH_ADD_1_opened-0',
    'f893e585-a2b7-47cd-8524-37491bdfd15f': 'GSzZjzd1J6VOiWO',
    '822bbb27-9671-4b6a-8040-5b9788075249': 'CTUH_ADD_2_closed-0',
    '77f302f2-5d4f-4742-8b63-7307429a12ba': 'CTUH_ADD_1_TPPS_txnBarGraph',
    '726fad40-e3e4-43cf-b16d-f6012620e23a': 'CTUH_ADD_2_TPPS_income',
    'ab6bc83b-f107-4eda-a686-9efccbd6f780': 'fB31ksdPB3CEkBe',
    '041417d0-1b83-4a02-823b-f786944a5fae': 'CTUH_ADD_2_noStart',
    '5a21bc56-160f-4d94-b5df-96d96aa1bae1': 'CTUH_ADD_1_stopped',
    '3c1c45c3-992d-46cb-96b9-aa64106fb3ca': 'CTUH_ADD_2_opened-2',
    '0721550f-ea2c-4af1-8f16-f14ae4b69428': 'CTUH_ADD_1_opened-5',
    '071d33c0-5801-4b05-b699-af3863b9d853': 'CTUH_ADD_1_closed-1',
    'd70bf380-3a4a-4622-9670-5f3a6cf81c49': 'CTUH_ADD_2_opened-1',
    '47f2780f-a4aa-4e11-9d32-2224ad5abe30': 'CTUH_ADD_2_noStart',
    '66c9c005-e67e-4b66-8c74-33470250ab1b': 'CTUH_ADD_2_opened-6',
    '16360978-715f-4e85-bd35-22c3539600b2': 'CTUH_ADD_2_closed-1',
    '51f94b3e-ddf1-46bc-a576-2c4dc736a2cb': 'CTUH_ADD_2_closed-3',
    '10a8005a-dcb6-474f-99f0-2dce6f69d6f0': 'CTUH_ADD_2_opened-2',
    'e18291cf-759e-4e42-8a65-6c3945683320': 'CTUH_ADD_2_closed-4',
    '4fc8f6da-06e9-47d7-b7e5-63cf754f209a': 'CTUH_ADD_2_opened-4',
    'bc4ea2b7-5232-4137-aeeb-0659f34a1152': 'CTUH_ADD_1_shutdown',
    '8ab408e5-b318-4589-bba7-37c3750d2f20': 'CTUH_ADD_1_opened-2',
    '2bfc6521-3f0b-4e46-ab1f-bd193005bd54': 'CTUH_ADD_2_shutdown',
    '938e4a42-c848-41c4-a2eb-747b31a8dde0': 'CTUH_ADD_1_testTxn',
    '53ace820-0bed-4aaa-8eb0-d7060f68042e': 'CTUH_ADD_1_closed-3',
    '82530e9a-aa76-42dd-8570-c93207b340f5': 'CTUH_ADD_1_closed-6',
    'e04b4105-eedf-4a0f-8e01-bca76a40fa8c': 'CTUH_ADD_2_opened-0',
    'cef60ea9-d046-4336-a21f-54e4e4a47721': 'CTUH_ADD_2_testTxn',
    'fc10b25f-bbcd-441c-b670-07e28a63d9e7': 'CTUH_ADD_1_closed-0',
    '13370db1-fee4-4b68-95a2-ff8de8f17d7b': 'CTUH_ADD_2_stopped',
    '6b2ca5c6-e76d-4eb5-82b2-ff664ee0e209': 'CTUH_ADD_2_closed-5',
    '26d44ab3-f393-4ef9-a9ed-d7927a5a177f': 'CTUH_ADD_2_closed-5',
    '492ca204-76c2-424b-9f51-5c491f0c38a6': 'CTUH_ADD_2_opened-5',
    '83d67c25-f49e-4a06-8bea-fd4022ebad40': 'CTUH_ADD_1_opened-3',
    '2f158d23-0730-4b13-bfed-ec4737ba8170': 'CTUH_ADD_2_closed-2',
    'c7e33651-4c24-4e4e-bc8b-a4c1fa977da1': 'CTUH_ADD_2_testTxn',
    'b7bf597e-f612-4462-b063-21bd706051d5': 'CTUH_ADD_1_opened-4',
    '1eaf6d1d-7661-4c1b-94cf-65da03010589': 'CTUH_ADD_2_stopped',
    '05c30d6b-1eaa-4dea-8d28-73ac6cbcf285': 'CTUH_ADD_2_opened-5',
    'e5496a79-4069-44bb-bc86-bfb6ea84e650': 'CTUH_ADD_1_closed-5',
    'b0d42870-41d7-476a-9ee5-b631c3e55f2f': 'CTUH_ADD_2_closed-0',
    '566dc791-c69d-451e-bd4b-e661d5dde29d': 'CTUH_ADD_2_opened-0',
    '453e2eea-8826-4eba-940b-8175fadb9528': 'CTUH_ADD_1_opened-6',
    'b1d09bfb-06cb-46e3-9937-11fe14f0e7d6': 'CTUH_ADD_2_closed-6',
    [partnerPortalGuids.merchantGuids.merchant03]: 'FE mock merchant - Netevia/Elevated',
    [partnerPortalGuids.merchantGuids.merchant04]: 'FE mock merchant - Netevia/Preferred',
    'mock-repay-merchant-guid-1': 'FE mock merchant - Repay/Preferred',
    'mock-repay-merchant-guid-2': 'FE mock merchant - Repay/Elevated',
    'mock-repay-merchant-guid-inactive': 'FE mock merchant - Repay/Preferred - Inactive',
    '43334724-d030-40f2-bd63-ed83b53d0e54': 'FE - Priority/Axiom/Elevated',
    '22c288f3-0a99-4a97-8755-795811923915': 'FE - Priority/Synovus/Elevated',
    'cc7d8717-aa0d-4757-8fd0-1fb4a140c9d1': 'FE - Priority/Wells Fargo/Elevated'
  },
  [users.partner.noDelegateAccess.email]: {},
  [users.partner.delegateWithDelegates.email]: {},
  [users.partner.delegateWithPermissionToCreateDelegates.email]: {},
  [users.partner.noMerchantNoRelatonships.email]: {}
};

const createMockMerchantDetails = (email) => { // Top-level `merchantDetails`
  const merchantGuidToDba = userToMerchantGuidMap[email] || {};
  const staticMidMap = { // `merchantGuid` that has a hardcoded `mid` (For FTs/unit tests)
    '83d67c25-f49e-4a06-8bea-fd4022ebad40': '123456789012'
  };
  return !isEmpty(merchantGuidToDba)
    ? Object.entries(merchantGuidToDba).map(([merchantGuid, dbaName], index) => ({
      merchantGuid,
      dbaName,
      legalName: `${dbaName} Legal`,
      mid: staticMidMap[merchantGuid] || `1111111111${index}`
    }))
    : [];
};
// -- END Partner AND Merchant Portal --

// -- START Merchant Portal --
const merchantUsersResponse = {
  [users.merchant.emailUnconfirmed.email]: {
    merchantGuidToDba: userToMerchantGuidMap[users.merchant.emailUnconfirmed.email],
    merchantDetails: createMockMerchantDetails(users.merchant.emailUnconfirmed.email),
    merchantAccess: [{
      merchantGuid: '8a8eda8f-2315-4d5f-b22d-af2aecea4b74',
      dbaName: 'WKkpmQGoUbAF',
      merchantPortalAccess: creatMockMerchantPortalAccess({
        // for local testing, feel free to change these permissions
        dashboardAccess: false,
        financialAccess: false,
        reportAccess: false,
        documentAccess: false,
        delegateAccess: false
      })
    }],
    merchantDataAndAccess: {
      '8a8eda8f-2315-4d5f-b22d-af2aecea4b74': { dbaName: 'WKkpmQGoUbAF', merchantPortalAccess: {} }
    },
    relationshipDetails: [],
    partnerPortalAccessList: [],
    identityToken: {
      f1Email: { email: users.merchant.emailUnconfirmed.email },
      email: users.merchant.emailUnconfirmed.email,
      emailVerified: false,
      phoneNumber: '+15125536624',
      firstName: null,
      lastName: null
    },
    csrfToken: mockFrontendCsrfToken
  },
  [users.merchant.singleMid.email]: { // merchant with single mid
    merchantGuidToDba: userToMerchantGuidMap[users.merchant.singleMid.email],
    merchantDetails: createMockMerchantDetails(users.merchant.singleMid.email),
    merchantAccess: [{
      merchantGuid: '8a8eda8f-2315-4d5f-b22d-af2aecea4b74',
      dbaName: 'WKkpmQGoUbAF',
      merchantPortalAccess: creatMockMerchantPortalAccess()
    }],
    merchantDataAndAccess: {
      '8a8eda8f-2315-4d5f-b22d-af2aecea4b74': {
        dbaName: 'WKkpmQGoUbAF',
        merchantPortalAccess: creatMockMerchantPortalAccess()
      }
    },
    relationshipDetails: [],
    partnerPortalAccessList: [],
    identityToken: {
      f1Email: { email: users.merchant.singleMid.email },
      email: users.merchant.singleMid.email,
      emailVerified: true,
      phoneNumber: '+15125536624',
      firstName: null,
      lastName: null
    },
    csrfToken: mockFrontendCsrfToken
  },
  [users.merchant.multiMid.email]: { // merchant with multiple mids
    merchantGuidToDba: userToMerchantGuidMap[users.merchant.multiMid.email],
    merchantDetails: createMockMerchantDetails(users.merchant.multiMid.email),
    merchantAccess: [
      {
        merchantGuid: '84d2dfa3-f22b-43fc-b631-024bbd3c0e53',
        dbaName: 'HOBhusRpqwoE',
        merchantPortalAccess: creatMockMerchantPortalAccess()
      },
      {
        merchantGuid: '6fd90b65-5453-4a8f-869a-24e0d0b35042',
        dbaName: 'VcITPEFSTltF',
        merchantPortalAccess: creatMockMerchantPortalAccess()
      },
      {
        merchantGuid: '7b154a14-2f97-4657-987e-ed5c5ae9b833',
        dbaName: 'iBYUppkANdge',
        merchantPortalAccess: creatMockMerchantPortalAccess()
      },
      {
        merchantGuid: 'bcafe682-210c-4377-91c7-ef60193e8597',
        dbaName: 'TYpwylgHvSNR',
        merchantPortalAccess: creatMockMerchantPortalAccess()
      },
      {
        merchantGuid: '932be7e3-ae6a-441a-9022-d0427bfa0bf1',
        dbaName: 'KrUPeqonBLkX',
        merchantPortalAccess: creatMockMerchantPortalAccess()
      },
      {
        merchantGuid: '2a389c0e-1ae0-4b21-915b-f021bc7cbb15',
        dbaName: 'XKdFTBAuHvmF',
        merchantPortalAccess: creatMockMerchantPortalAccess()
      }
    ],
    merchantDataAndAccess: {
      '84d2dfa3-f22b-43fc-b631-024bbd3c0e53': { dbaName: 'HOBhusRpqwoE', merchantPortalAccess: {} },
      '6fd90b65-5453-4a8f-869a-24e0d0b35042': { dbaName: 'VcITPEFSTltF', merchantPortalAccess: {} },
      '7b154a14-2f97-4657-987e-ed5c5ae9b833': { dbaName: 'iBYUppkANdge', merchantPortalAccess: {} },
      'bcafe682-210c-4377-91c7-ef60193e8597': { dbaName: 'TYpwylgHvSNR', merchantPortalAccess: {} },
      '932be7e3-ae6a-441a-9022-d0427bfa0bf1': { dbaName: 'KrUPeqonBLkX', merchantPortalAccess: {} },
      '2a389c0e-1ae0-4b21-915b-f021bc7cbb15': { dbaName: 'XKdFTBAuHvmF', merchantPortalAccess: {} }
    },
    relationshipDetails: [],
    partnerPortalAccessList: [],
    identityToken: {
      f1Email: { email: users.merchant.multiMid.email },
      email: users.merchant.multiMid.email,
      emailVerified: true,
      phoneNumber: '+15125536624',
      firstName: null,
      lastName: null
    },
    csrfToken: mockFrontendCsrfToken
  },
  [users.merchant.withDelegateAccess.email]: { // merchant with delegate access
    merchantGuidToDba: userToMerchantGuidMap[users.merchant.withDelegateAccess.email],
    merchantDetails: createMockMerchantDetails(users.merchant.withDelegateAccess.email),
    merchantAccess: [{
      merchantGuid: '83c30b5a-e330-40c8-9189-4c920aee71b1',
      dbaName: 'WKkpmQGoUbAF',
      merchantPortalAccess: creatMockMerchantPortalAccess()
    }],
    merchantDataAndAccess: {
      '83c30b5a-e330-40c8-9189-4c920aee71b1': {
        dbaName: 'WKkpmQGoUbAF',
        merchantPortalAccess: creatMockMerchantPortalAccess()
      }
    },
    relationshipDetails: [],
    partnerPortalAccessList: [],
    identityToken: {
      f1Email: { email: users.merchant.withDelegateAccess.email },
      email: users.merchant.withDelegateAccess.email,
      emailVerified: true,
      phoneNumber: '+15125536624',
      firstName: null,
      lastName: null
    },
    csrfToken: mockFrontendCsrfToken
  },
  [users.merchant.noDelegateAccess.email]: { // merchant with NO delegate access
    merchantGuidToDba: userToMerchantGuidMap[users.merchant.noDelegateAccess.email],
    merchantDetails: createMockMerchantDetails(users.merchant.noDelegateAccess.email),
    merchantAccess: [{
      merchantGuid: 'a52dddba-ac2d-48cd-9ff5-93a879dab6f8',
      dbaName: 'WKkpmQGoUbAF',
      merchantPortalAccess: creatMockMerchantPortalAccess()
    }],
    merchantDataAndAccess: {
      'a52dddba-ac2d-48cd-9ff5-93a879dab6f8': { dbaName: 'WKkpmQGoUbAF', merchantPortalAccess: {} }
    },
    relationshipDetails: [],
    partnerPortalAccessList: [],
    identityToken: {
      f1Email: { email: users.merchant.noDelegateAccess.email },
      email: users.merchant.noDelegateAccess.email,
      emailVerified: true,
      phoneNumber: '+15125536624',
      firstName: null,
      lastName: null
    },
    csrfToken: mockFrontendCsrfToken
  }
};
// -- END Merchant Portal --

// -- START Partner Portal --
const relationshipId = {
  partnerWithNoDelegates: '14c8b2f1-a180-471e-8d4f-67be5915489b',
  partnerWithDelegates: 'e74a38bf-e56b-4559-8877-898dffd15320',
  partnerNoDelegateAccess: '5d57738f-0e65-4e4f-aa4d-a0a98a614754'
};

const relationshipDetailsMerchantGuidToDba = { // `relationshipDetails` -> `merchantGuidToDba`
  [mockRelationships.relationship02.relationshipId]: {
    '66c9c005-e67e-4b66-8c74-33470250ab1b': 'CTUH_ADD_2_opened-6',
    '16360978-715f-4e85-bd35-22c3539600b2': 'CTUH_ADD_2_closed-1',
    '51f94b3e-ddf1-46bc-a576-2c4dc736a2cb': 'CTUH_ADD_2_closed-3',
    'f0c4a921-5dd3-4b95-adca-92f7852db0df': 'CTUH_ADD_2_closed-4',
    '18a2a63d-1742-4799-8ef6-4bba1f65d6c1': 'CTUH_ADD_2_TPPS_txnBarGraph',
    '10a8005a-dcb6-474f-99f0-2dce6f69d6f0': 'CTUH_ADD_2_opened-2',
    'a46f0195-3be1-4445-953f-1c6b78d120d8': 'CTUH_ADD_2_closed-6',
    'f680866d-8393-471c-ae7f-98284b60ab66': 'CTUH_ADD_2_closed-2',
    'e18291cf-759e-4e42-8a65-6c3945683320': 'CTUH_ADD_2_closed-4',
    '3b103038-7a07-498a-9a7f-9c528ffc6797': 'CTUH_ADD_2_TPPS_txnBarGraph',
    '4fc8f6da-06e9-47d7-b7e5-63cf754f209a': 'CTUH_ADD_2_opened-4',
    '2bfc6521-3f0b-4e46-ab1f-bd193005bd54': 'CTUH_ADD_2_shutdown',
    '98c1b001-598c-4364-a9e7-8e09f6513b22': 'CTUH_ADD_2_opened-4',
    'c3edde32-3696-4894-814b-43f7baadf40e': 'CTUH_ADD_2_closed-1',
    'f6d29a7b-0618-486b-97d4-1a7a3eb8a4bb': 'CTUH_ADD_2_opened-3',
    'ffe6add5-aa9d-4257-8513-aeb45173ef8d': 'CTUH_ADD_2_opened-6',
    '1f0b2083-8c66-4574-bdb0-b25a19712fdc': 'CTUH_ADD_2_TPPS_income',
    'e04b4105-eedf-4a0f-8e01-bca76a40fa8c': 'CTUH_ADD_2_opened-0',
    'd229718d-9960-4e53-b0dd-3e4cf4c1c0df': 'CTUH_ADD_2_opened-1',
    'b2164c74-662f-4e92-8a69-a7046e04bb1f': 'CTUH_ADD_2_shutdown',
    'cef60ea9-d046-4336-a21f-54e4e4a47721': 'CTUH_ADD_2_testTxn',
    '13370db1-fee4-4b68-95a2-ff8de8f17d7b': 'CTUH_ADD_2_stopped',
    '6b2ca5c6-e76d-4eb5-82b2-ff664ee0e209': 'CTUH_ADD_2_closed-5',
    '26d44ab3-f393-4ef9-a9ed-d7927a5a177f': 'CTUH_ADD_2_closed-5',
    '105c2c01-e537-4608-872f-37666fc4f42a': 'CTUH_ADD_2_closed-3',
    'b4b4a37f-5eb6-44ee-b73f-5b519ecc40c3': 'CTUH_ADD_2_opened-3',
    '492ca204-76c2-424b-9f51-5c491f0c38a6': 'CTUH_ADD_2_opened-5',
    '2f158d23-0730-4b13-bfed-ec4737ba8170': 'CTUH_ADD_2_closed-2',
    'c7e33651-4c24-4e4e-bc8b-a4c1fa977da1': 'CTUH_ADD_2_testTxn',
    '1eaf6d1d-7661-4c1b-94cf-65da03010589': 'CTUH_ADD_2_stopped',
    '822bbb27-9671-4b6a-8040-5b9788075249': 'CTUH_ADD_2_closed-0',
    '726fad40-e3e4-43cf-b16d-f6012620e23a': 'CTUH_ADD_2_TPPS_income',
    'ab6bc83b-f107-4eda-a686-9efccbd6f780': 'fB31ksdPB3CEkBe',
    '05c30d6b-1eaa-4dea-8d28-73ac6cbcf285': 'CTUH_ADD_2_opened-5',
    '041417d0-1b83-4a02-823b-f786944a5fae': 'CTUH_ADD_2_noStart',
    'b0d42870-41d7-476a-9ee5-b631c3e55f2f': 'CTUH_ADD_2_closed-0',
    '566dc791-c69d-451e-bd4b-e661d5dde29d': 'CTUH_ADD_2_opened-0',
    '3c1c45c3-992d-46cb-96b9-aa64106fb3ca': 'CTUH_ADD_2_opened-2',
    'd70bf380-3a4a-4622-9670-5f3a6cf81c49': 'CTUH_ADD_2_opened-1',
    '47f2780f-a4aa-4e11-9d32-2224ad5abe30': 'CTUH_ADD_2_noStart',
    'b1d09bfb-06cb-46e3-9937-11fe14f0e7d6': 'CTUH_ADD_2_closed-6'
  },
  [relationshipId.partnerWithNoDelegates]: {},
  [relationshipId.partnerWithDelegates]: {},
  [relationshipId.partnerNoDelegateAccess]: {}
};

const getMockCrabConfigurationOptions = overrides => ({
  ddaForChargebacksOnly: true,
  suppressStatementsDefault: true,
  merchantChargebackNotificationEmail: 'mockSigninCbEmail@test.com',
  ...overrides
});

const partnerUsersResponse = {
  [users.partner.hasSubpartnersWithMerchants.email]: {
    merchantGuidToDba: userToMerchantGuidMap[users.partner.hasSubpartnersWithMerchants.email],
    merchantDetails: createMockMerchantDetails(users.partner.hasSubpartnersWithMerchants.email),
    merchantAccess: [],
    merchantDataAndAccess: {},
    relationshipDetails: [{
      relationshipId: mockRelationships.relationship16.relationshipId,
      relationshipName: mockRelationships.relationship16.relationshipName,
      relationshipCode: mockRelationships.relationship16.relationshipCode,
      riskProfile: mockRelationships.relationship16.riskProfile,
      parentRelation: mockRelationships.relationship16.parentRelation,
      crabConfigurationOptions: getMockCrabConfigurationOptions({
        ddaForChargebacksOnly: false,
        suppressStatementsDefault: false,
        merchantChargebackNotificationEmail: null
      }),
      merchantGuidToDba: {},
      childPartner: '9f56b5f2-8e00-46d1-90b1-d7d8e131cc74',
      downlines: [],
      bankName: 'MVB',
      processorName: 'REPAY',
      inactive: false
    }],
    partnerPortalAccessList: [{
      portalUserId: 'da71f000-94b4-411d-b40b-6be09e436d5f',
      relationshipId: mockRelationships.relationship16.relationshipId,
      ...createMockPartnerPortalAccessList({ partnerDelegate: false })
    }],
    identityToken: {
      f1Email: { email: users.partner.hasSubpartnersWithMerchants.email },
      email: users.partner.hasSubpartnersWithMerchants.email,
      emailVerified: true,
      phoneNumber: '+15555555555',
      firstName: null,
      lastName: null
    },
    csrfToken: mockFrontendCsrfToken
  },
  [users.partner.hasAllDashboardData.email]: {
    merchantGuidToDba: userToMerchantGuidMap[users.partner.hasAllDashboardData.email],
    merchantDetails: createMockMerchantDetails(users.partner.hasAllDashboardData.email),
    merchantAccess: [],
    merchantDataAndAccess: {},
    relationshipDetails: [{
      relationshipId: mockRelationships.relationship02.relationshipId,
      relationshipName: mockRelationships.relationship02.relationshipName, // 'CTUH_ADD_2'
      relationshipCode: mockRelationships.relationship02.relationshipCode,
      riskProfile: mockRelationships.relationship02.riskProfile,
      parentRelation: mockRelationships.relationship02.parentRelation,
      crabConfigurationOptions: getMockCrabConfigurationOptions(),
      merchantGuidToDba:
        relationshipDetailsMerchantGuidToDba[mockRelationships.relationship02.relationshipId],
      childPartner: 'c0b2d126-9d53-4553-96ae-6339cf13eba4',
      payingChild: false,
      showToChild: false,
      downlines: [{
        relationshipId: mockRelationships.relationship04.relationshipId,
        relationshipName: mockRelationships.relationship04.relationshipName, // 'TPPS_leaderboard 2'
        relationshipCode: mockRelationships.relationship04.relationshipCode,
        riskProfile: mockRelationships.relationship04.riskProfile,
        parentRelation: mockRelationships.relationship04.parentRelation,
        crabConfigurationOptions: getMockCrabConfigurationOptions(),
        merchantGuidToDba: {
          '526b2463-7458-4d5c-9a56-c97375d48d9c': 'TPPS_leaderboard_mid'
        },
        childPartner: '827126b7-07cd-498e-97f7-5bd4c253523b',
        payingChild: false,
        showToChild: false,
        downlines: [],
        bankName: 'CYNERGY',
        processorName: 'FIRST_DATA',
        inactive: false
      }, {
        relationshipId: mockRelationships.relationship03.relationshipId,
        relationshipName: mockRelationships.relationship03.relationshipName, // 'TPPS_leaderboard 3'
        relationshipCode: mockRelationships.relationship03.relationshipCode,
        riskProfile: mockRelationships.relationship03.riskProfile,
        parentRelation: mockRelationships.relationship03.parentRelation,
        crabConfigurationOptions: getMockCrabConfigurationOptions(),
        merchantGuidToDba: {
          'e83814eb-9c0c-43f3-95ea-60461732ff28': 'TPPS_leaderboard_mid'
        },
        childPartner: '8d400d25-6fee-402a-8d55-f4dc5eb0b3a1',
        payingChild: false,
        showToChild: false,
        downlines: [],
        bankName: 'CYNERGY',
        processorName: 'FIRST_DATA',
        inactive: false
      }],
      bankName: 'CYNERGY',
      processorName: 'FIRST_DATA',
      inactive: false
    },
    {
      relationshipId: mockRelationships.relationship01.relationshipId,
      relationshipName: mockRelationships.relationship01.relationshipName, // 'CTUH_ADD_1'
      relationshipCode: mockRelationships.relationship01.relationshipCode,
      riskProfile: mockRelationships.relationship01.riskProfile,
      parentRelation: mockRelationships.relationship01.parentRelation,
      crabConfigurationOptions: getMockCrabConfigurationOptions(),
      merchantGuidToDba: {
        '83d67c25-f49e-4a06-8bea-fd4022ebad40': 'CTUH_ADD_1_opened-3',
        '6758be57-3521-4a7c-acf6-6119abcd30ed': 'CTUH_ADD_1_closed-4',
        '1c66c251-bd77-45ad-b55f-d43f8d9c3e35': 'CTUH_ADD_1_noStart',
        '62ad63f7-9d2d-49c4-8ce6-18ecbc932beb': 'CTUH_ADD_1_closed-2',
        [partnerPortalGuids.merchantGuids.default]: 'CTUH_ADD_1_opened-0',
        'f893e585-a2b7-47cd-8524-37491bdfd15f': 'GSzZjzd1J6VOiWO',
        'b7bf597e-f612-4462-b063-21bd706051d5': 'CTUH_ADD_1_opened-4',
        '77f302f2-5d4f-4742-8b63-7307429a12ba': 'CTUH_ADD_1_TPPS_txnBarGraph',
        [partnerPortalGuids.merchantGuids.secondWithData]: 'CTUH_ADD_1_opened-1',
        'bc4ea2b7-5232-4137-aeeb-0659f34a1152': 'CTUH_ADD_1_shutdown',
        '5a21bc56-160f-4d94-b5df-96d96aa1bae1': 'CTUH_ADD_1_stopped',
        '8ab408e5-b318-4589-bba7-37c3750d2f20': 'CTUH_ADD_1_opened-2',
        'e5496a79-4069-44bb-bc86-bfb6ea84e650': 'CTUH_ADD_1_closed-5',
        '938e4a42-c848-41c4-a2eb-747b31a8dde0': 'CTUH_ADD_1_testTxn',
        '0721550f-ea2c-4af1-8f16-f14ae4b69428': 'CTUH_ADD_1_opened-5',
        '071d33c0-5801-4b05-b699-af3863b9d853': 'CTUH_ADD_1_closed-1',
        '53ace820-0bed-4aaa-8eb0-d7060f68042e': 'CTUH_ADD_1_closed-3',
        '82530e9a-aa76-42dd-8570-c93207b340f5': 'CTUH_ADD_1_closed-6',
        '2879b631-f000-4702-b7b6-e3eb6046a706': 'CTUH_ADD_1_TPPS_income',
        'fc10b25f-bbcd-441c-b670-07e28a63d9e7': 'CTUH_ADD_1_closed-0',
        '453e2eea-8826-4eba-940b-8175fadb9528': 'CTUH_ADD_1_opened-6'
      },
      childPartner: 'c0b2d126-9d53-4553-96ae-6339cf13eba4',
      payingChild: false,
      showToChild: false,
      downlines: [{
        relationshipId: mockRelationships.relationship05.relationshipId,
        relationshipName: mockRelationships.relationship05.relationshipName, // 'TPPS_leaderboard 1'
        relationshipCode: mockRelationships.relationship05.relationshipCode,
        parentRelation: mockRelationships.relationship05.parentRelation,
        crabConfigurationOptions: getMockCrabConfigurationOptions(),
        riskProfile: mockRelationships.relationship05.riskProfile,
        merchantGuidToDba: {
          'dce037db-b77e-462f-84f7-f165c71558e8': 'TPPS_leaderboard_mid'
        },
        childPartner: '70e369a4-3d07-434e-b806-987db21b83da',
        payingChild: false,
        showToChild: false,
        downlines: [],
        bankName: 'CYNERGY',
        processorName: 'FIRST_DATA',
        inactive: false
      }],
      bankName: 'WELLS_FARGO',
      processorName: 'FIRST_DATA',
      inactive: false
    },
    {
      relationshipId: mockRelationships.relationship11.relationshipId,
      relationshipName: mockRelationships.relationship11.relationshipName, // FE - Netevia/Elevated
      relationshipCode: mockRelationships.relationship11.relationshipCode,
      riskProfile: mockRelationships.relationship11.riskProfile,
      parentRelation: mockRelationships.relationship11.parentRelation,
      crabConfigurationOptions: getMockCrabConfigurationOptions(),
      merchantGuidToDba: {
        [partnerPortalGuids.merchantGuids.merchant04]: 'FE mock merchant - Netevia/Preferred'
      },
      childPartner: 'c0b2d126-9d53-4553-96ae-6339cf13eba4',
      payingChild: false,
      showToChild: false,
      downlines: [],
      bankName: 'ESQUIRE',
      processorName: 'NETEVIA',
      inactive: false
    },
    {
      relationshipId: mockRelationships.relationship09.relationshipId,
      relationshipName: mockRelationships.relationship09.relationshipName, // FE - Netevia/Elevated
      relationshipCode: mockRelationships.relationship09.relationshipCode,
      riskProfile: mockRelationships.relationship09.riskProfile,
      parentRelation: mockRelationships.relationship09.parentRelation,
      crabConfigurationOptions: getMockCrabConfigurationOptions(),
      merchantGuidToDba: {
        [partnerPortalGuids.merchantGuids.merchant03]: 'FE mock merchant - Netevia/Elevated'
      },
      childPartner: 'c0b2d126-9d53-4553-96ae-6339cf13eba4',
      payingChild: false,
      showToChild: false,
      downlines: [],
      bankName: 'MVB',
      processorName: 'NETEVIA',
      inactive: false
    },
    {
      relationshipId: mockRelationships.relationship07.relationshipId,
      relationshipName: mockRelationships.relationship07.relationshipName, // Repay-Preferred
      relationshipCode: mockRelationships.relationship07.relationshipCode,
      riskProfile: mockRelationships.relationship07.riskProfile,
      parentRelation: mockRelationships.relationship07.parentRelation,
      crabConfigurationOptions: getMockCrabConfigurationOptions(),
      merchantGuidToDba: {
        'mock-repay-merchant-guid-1': 'FE mock merchant - Repay/Preferred'
      },
      childPartner: 'c0b2d126-9d53-4553-96ae-6339cf13eba4',
      payingChild: false,
      showToChild: false,
      downlines: [],
      bankName: 'MVB',
      processorName: 'REPAY',
      inactive: false
    },
    { // Priority/Axiom
      relationshipId: mockRelationships.relationship12.relationshipId,
      relationshipName: mockRelationships.relationship12.relationshipName,
      relationshipCode: mockRelationships.relationship12.relationshipCode,
      riskProfile: mockRelationships.relationship12.riskProfile,
      parentRelation: mockRelationships.relationship12.parentRelation,
      crabConfigurationOptions: getMockCrabConfigurationOptions(),
      merchantGuidToDba: {
        '43334724-d030-40f2-bd63-ed83b53d0e54': 'FE - Priority/Axiom/Elevated'
      },
      childPartner: 'c0b2d126-9d53-4553-96ae-6339cf13eba4',
      payingChild: false,
      showToChild: false,
      downlines: [],
      bankName: 'AXIOM',
      processorName: 'PRIORITY',
      inactive: false
    },
    { // Priority/Synovus
      relationshipId: mockRelationships.relationship13.relationshipId,
      relationshipName: mockRelationships.relationship13.relationshipName,
      relationshipCode: mockRelationships.relationship13.relationshipCode,
      riskProfile: mockRelationships.relationship13.riskProfile,
      parentRelation: mockRelationships.relationship13.parentRelation,
      crabConfigurationOptions: getMockCrabConfigurationOptions(),
      merchantGuidToDba: {
        '22c288f3-0a99-4a97-8755-795811923915': 'FE - Priority/Synovus/Elevated'
      },
      childPartner: 'c0b2d126-9d53-4553-96ae-6339cf13eba4',
      payingChild: false,
      showToChild: false,
      downlines: [],
      bankName: 'SYNOVUS',
      processorName: 'PRIORITY',
      inactive: false
    },
    { // Priority/Wells Fargo
      relationshipId: mockRelationships.relationship14.relationshipId,
      relationshipName: mockRelationships.relationship14.relationshipName,
      relationshipCode: mockRelationships.relationship14.relationshipCode,
      riskProfile: mockRelationships.relationship14.riskProfile,
      parentRelation: mockRelationships.relationship14.parentRelation,
      crabConfigurationOptions: getMockCrabConfigurationOptions(),
      merchantGuidToDba: {
        'cc7d8717-aa0d-4757-8fd0-1fb4a140c9d1': 'FE - Priority/Wells Fargo/Elevated'
      },
      childPartner: 'c0b2d126-9d53-4553-96ae-6339cf13eba4',
      payingChild: false,
      showToChild: false,
      downlines: [],
      bankName: 'WELLS_FARGO',
      processorName: 'PRIORITY',
      inactive: false
    },
    {
      relationshipId: mockRelationships.relationship08.relationshipId,
      relationshipName: mockRelationships.relationship08.relationshipName, // Repay-Elevated
      relationshipCode: mockRelationships.relationship08.relationshipCode,
      riskProfile: mockRelationships.relationship08.riskProfile,
      parentRelation: mockRelationships.relationship08.parentRelation,
      crabConfigurationOptions: getMockCrabConfigurationOptions(),
      merchantGuidToDba: {
        'mock-repay-merchant-guid-2': 'FE mock merchant - Repay/Elevated'
      },
      childPartner: 'c0b2d126-9d53-4553-96ae-6339cf13eba4',
      payingChild: false,
      showToChild: false,
      downlines: [],
      bankName: 'MVB',
      processorName: 'REPAY',
      inactive: false
    },
    {
      relationshipId: mockRelationships.relationship15.relationshipId,
      // Repay-Preferred-Inactive
      relationshipName: mockRelationships.relationship15.relationshipName,
      relationshipCode: mockRelationships.relationship15.relationshipCode,
      riskProfile: mockRelationships.relationship15.riskProfile,
      parentRelation: mockRelationships.relationship15.parentRelation,
      crabConfigurationOptions: getMockCrabConfigurationOptions(),
      merchantGuidToDba: {
        'mock-repay-merchant-guid-inactive': 'FE mock merchant - Repay/Preferred - Inactive'
      },
      childPartner: 'c0b2d126-9d53-4553-96ae-6339cf13eba4',
      payingChild: false,
      showToChild: false,
      downlines: [],
      bankName: 'MVB',
      processorName: 'REPAY',
      inactive: true
    }],
    partnerPortalAccessList: [{
      partnerPortalAccessId: '445b8c0a-cdea-48c3-b154-854408ab036a',
      portalUserId: '40204fe5-4c6f-41dd-8fa7-df85272e73c2',
      ...createMockPartnerPortalAccessList({ partnerDelegate: false }),
      relationshipId: 'c7f99ba1-ab36-4d0a-a638-ea80e0564d84'
    }, {
      partnerPortalAccessId: '8cd8936a-0701-4251-a9a4-af2ffcddcbb6',
      portalUserId: '40204fe5-4c6f-41dd-8fa7-df85272e73c2',
      ...createMockPartnerPortalAccessList({ partnerDelegate: false }),
      relationshipId: 'd289c5d3-7195-49a3-9fa6-1a8ef04056e6'
    }, {
      partnerPortalAccessId: '9dp75346-9663-4661-k6g3-az1fdsdcbb7',
      portalUserId: '40204fe5-4c6f-41dd-8fa7-df85272e73c2',
      ...createMockPartnerPortalAccessList({ partnerDelegate: false }),
      relationshipId: mockRelationships.relationship09.relationshipId
    }, {
      partnerPortalAccessId: '9dp75346-9663-4661-k6g3-az1fdsdcbb7',
      portalUserId: '40204fe5-4c6f-41dd-8fa7-df85272e73c2',
      ...createMockPartnerPortalAccessList({ partnerDelegate: false }),
      relationshipId: mockRelationships.relationship07.relationshipId
    },
    {
      partnerPortalAccessId: '9dp75346-9663-4661-k6g3-az1fdsdcbb7',
      portalUserId: '40204fe5-4c6f-41dd-8fa7-df85272e73c2',
      ...createMockPartnerPortalAccessList({ partnerDelegate: false }),
      relationshipId: mockRelationships.relationship15.relationshipId
    },
    {
      partnerPortalAccessId: '9dp75346-9663-4661-k6g3-az1fdsdcbb7',
      portalUserId: '40204fe5-4c6f-41dd-8fa7-df85272e73c2',
      ...createMockPartnerPortalAccessList({ partnerDelegate: false }),
      relationshipId: mockRelationships.relationship08.relationshipId
    },
    {
      partnerPortalAccessId: '9dp75346-9663-4661-k6g3-az1fdsdcbb7',
      portalUserId: '40204fe5-4c6f-41dd-8fa7-df85272e73c2',
      ...createMockPartnerPortalAccessList({ partnerDelegate: false }),
      relationshipId: mockRelationships.relationship11.relationshipId
    },
    {
      partnerPortalAccessId: '9dp75346-9663-4661-k6g3-az1fdsdcbb7',
      portalUserId: '40204fe5-4c6f-41dd-8fa7-df85272e73c2',
      ...createMockPartnerPortalAccessList({ partnerDelegate: false }),
      relationshipId: mockRelationships.relationship12.relationshipId
    },
    {
      partnerPortalAccessId: '9dp75346-9663-4661-k6g3-az1fdsdcbb7',
      portalUserId: '40204fe5-4c6f-41dd-8fa7-df85272e73c2',
      ...createMockPartnerPortalAccessList({ partnerDelegate: false }),
      relationshipId: mockRelationships.relationship13.relationshipId
    },
    {
      partnerPortalAccessId: '9dp75346-9663-4661-k6g3-az1fdsdcbb7',
      portalUserId: '40204fe5-4c6f-41dd-8fa7-df85272e73c2',
      ...createMockPartnerPortalAccessList({ partnerDelegate: false }),
      relationshipId: mockRelationships.relationship14.relationshipId
    }
    ],
    identityToken: {
      f1Email: {
        email: users.partner.hasAllDashboardData.email
      },
      email: users.partner.hasAllDashboardData.email,
      emailVerified: true,
      phoneNumber: '+15125536624',
      firstName: null,
      lastName: null
    },
    csrfToken: mockFrontendCsrfToken
  },
  [users.partner.noMerchantNoRelatonships.email]: {
    merchantGuidToDba: userToMerchantGuidMap[users.partner.noMerchantNoRelatonships.email],
    merchantDetails: createMockMerchantDetails(users.partner.noMerchantNoRelatonships.email),
    merchantAccess: [],
    merchantDataAndAccess: {},
    relationshipDetails: [],
    partnerPortalAccessList: [],
    identityToken: {
      f1Email: { email: users.partner.noMerchantNoRelatonships.email }, email: users.partner.noMerchantNoRelatonships.email, emailVerified: true, phoneNumber: '+15125536624', firstName: null, lastName: null
    },
    csrfToken: mockFrontendCsrfToken
  },
  [users.partner.delegateWithPermissionToCreateDelegates.email]: {
    // partner - with delegate access and has NO existing delegates
    merchantGuidToDba:
      userToMerchantGuidMap[users.partner.delegateWithPermissionToCreateDelegates.email],
    merchantDetails:
      createMockMerchantDetails(users.partner.delegateWithPermissionToCreateDelegates.email),
    merchantAccess: [],
    merchantDataAndAccess: {},
    relationshipDetails: [{
      relationshipId: relationshipId.partnerWithNoDelegates,
      relationshipName: 'rEclq3S1ShksTXq',
      relationshipCode: null,
      parentRelation: null,
      crabConfigurationOptions: getMockCrabConfigurationOptions({
        ddaForChargebacksOnly: false,
        suppressStatementsDefault: false
      }),
      merchantGuidToDba:
        relationshipDetailsMerchantGuidToDba[relationshipId.partnerWithNoDelegates],
      childPartner: '743a3324-d8fc-4e4c-b2ed-c918d7481bc4',
      payingChild: false,
      showToChild: false,
      riskProfile: 'preferred',
      downlines: [],
      bankName: 'CYNERGY',
      processorName: 'FIRST_DATA',
      inactive: false
    }],
    partnerPortalAccessList: [{
      partnerPortalAccessId: 'c0cce9fb-4042-40ad-b071-423b8c4ef344',
      portalUserId: '3e607f2c-f758-4c90-9f4f-753fd763e9d4',
      ...createMockPartnerPortalAccessList({ partnerDelegate: false }),
      relationshipId: relationshipId.partnerWithNoDelegates
    }],
    identityToken: {
      f1Email: { email: users.partner.delegateWithPermissionToCreateDelegates.email }, email: users.partner.delegateWithPermissionToCreateDelegates.email, emailVerified: true, phoneNumber: '+15125536624', firstName: null, lastName: null
    },
    csrfToken: mockFrontendCsrfToken
  },
  [users.partner.delegateWithDelegates.email]: {
    // partner - with delegate access and HAS existing delegates
    merchantGuidToDba: userToMerchantGuidMap[users.partner.delegateWithDelegates.email],
    merchantDetails: createMockMerchantDetails(users.partner.delegateWithDelegates.email),
    merchantAccess: [],
    merchantDataAndAccess: {},
    relationshipDetails: [{
      relationshipId: relationshipId.partnerWithDelegates,
      relationshipName: 'rEclq3S1ShksTXq',
      relationshipCode: null,
      parentRelation: null,
      crabConfigurationOptions: getMockCrabConfigurationOptions(),
      merchantGuidToDba:
        relationshipDetailsMerchantGuidToDba[relationshipId.partnerWithDelegates],
      childPartner: '743a3324-d8fc-4e4c-b2ed-c918d7481bc4',
      payingChild: false,
      showToChild: false,
      riskProfile: 'preferred',
      downlines: [],
      bankName: 'CYNERGY',
      processorName: 'FIRST_DATA',
      inactive: false
    }],
    partnerPortalAccessList: [{
      partnerPortalAccessId: 'c0cce9fb-4042-40ad-b071-423b8c4ef344',
      portalUserId: '3e607f2c-f758-4c90-9f4f-753fd763e9d4',
      ...createMockPartnerPortalAccessList({ partnerDelegate: false }),
      relationshipId: relationshipId.partnerWithDelegates
    }],
    identityToken: {
      f1Email: { email: users.partner.delegateWithDelegates.email }, email: users.partner.delegateWithDelegates.email, emailVerified: true, phoneNumber: '+15125536624', firstName: null, lastName: null
    },
    csrfToken: mockFrontendCsrfToken
  },
  [users.partner.noDelegateAccess.email]: {
    // partner - with NO delegate access
    merchantGuidToDba: userToMerchantGuidMap[users.partner.noDelegateAccess.email],
    merchantDetails: createMockMerchantDetails(users.partner.noDelegateAccess.email),
    merchantAccess: [],
    merchantDataAndAccess: {},
    relationshipDetails: [{
      relationshipId: relationshipId.partnerNoDelegateAccess,
      relationshipName: 'rEclq3S1ShksTXq',
      relationshipCode: null,
      parentRelation: null,
      crabConfigurationOptions: getMockCrabConfigurationOptions({
        ddaForChargebacksOnly: false,
        suppressStatementsDefault: false
      }),
      merchantGuidToDba:
        relationshipDetailsMerchantGuidToDba[relationshipId.partnerNoDelegateAccess],
      childPartner: '743a3324-d8fc-4e4c-b2ed-c918d7481bc4',
      payingChild: false,
      showToChild: false,
      riskProfile: 'preferred',
      downlines: [],
      bankName: 'CYNERGY',
      processorName: 'FIRST_DATA',
      inactive: false
    }],
    partnerPortalAccessList: [{
      partnerPortalAccessId: 'c0cce9fb-4042-40ad-b071-423b8c4ef344',
      portalUserId: '3e607f2c-f758-4c90-9f4f-753fd763e9d4',
      ...createMockPartnerPortalAccessList({ delegateAccess: false, partnerDelegate: false }),
      relationshipId: relationshipId.partnerNoDelegateAccess
    }],
    identityToken: {
      f1Email: { email: users.partner.noDelegateAccess.email }, email: users.partner.noDelegateAccess.email, emailVerified: true, phoneNumber: '+15125536624', firstName: null, lastName: null
    },
    csrfToken: mockFrontendCsrfToken
  }
};
// -- END Partner Portal --

export const mockSignInRes = { ...merchantUsersResponse, ...partnerUsersResponse };

export default mockSignInRes;
