import { isEmpty, isBool } from './_helpers';
import { toFrontendValue } from './_templateHelpers';

export const buildFormComponents = (existingData = {}, formHelperFields, options) => {
  const {
    isControlledField = false, // if the field exists under `controls`
    setInitialValue = {
      // key/value pairs of [id]: <default_value> for fields that load a dynamic initial value
    },
    customProps = {
      // if a field has any custom props to override any defaults that get set,
      // add them here. should be in key/value pairs of id/object of custom props. example:
      // [id]: { // custom props here, in an object - example:
      // list: []
      // }
    },
    disableFormFields = false
  } = options || {};
  const formFields = !isEmpty(formHelperFields)
    ? formHelperFields
    : { missingFormHelperField: { id: 'missingFormHelperField' } };
  const components = Object.entries(formFields)
    .filter(([fieldId]) => fieldId !== 'id')
    .reduce((acc, [fieldId, fieldHelperObject]) => {
      // fieldHelperObject should not contain additional nested fieldHelperObjects
      const defaultValue = setInitialValue[fieldId] || fieldHelperObject?.initialValue;

      // When loading data into a form, if `valuesForBackend` exists, use that value
      let existingValue = existingData?.valuesForBackend?.[fieldId];
      // Else use the `existingData` value which should already be the BE value on load
      if (existingValue === undefined) { existingValue = existingData?.[fieldId]; }
      // else if it's REQUIRED and empty, set it to the default, if one exists.
      const isRequired = !Object.prototype.hasOwnProperty.call(formFields[fieldId], 'required') || formFields[fieldId]?.required;

      if (isRequired && isEmpty(existingValue) && !isEmpty(defaultValue)) {
        existingValue = defaultValue;
      }

      const convertValue = (v) => {
        /**
         * For checkboxList items ON LOAD ONLY, if the value is not an array,
         * but is an object - eg, { january: true, february: false }
         * it needs to be converted to one - eg [{ january: true }, { february: false }]
         * for the CheckboxList component to handle the data properly on LOAD
         */
        const isCheckboxList = fieldHelperObject?.fieldType === 'checkboxList' && !isEmpty(v) && !Array.isArray(v);
        if (isCheckboxList) {
          const converted = Object.entries(v).map(([key, value]) => ({ [key]: value }));
          return converted;
        }
        return v;
      };

      const valueToFormat = !isEmpty(existingData) &&
      existingData?.[fieldId] !== undefined // needed for defaultValues to work
        ? convertValue(existingValue)
        : defaultValue;
      const customFieldProps = {
        ...(!isEmpty(customProps[fieldId]) && { ...customProps[fieldId] })
      };
      const fieldIsRequired = isFormFieldRequired({
        ...fieldHelperObject,
        // by default, all fields are considered required unless passed in fieldHelperObject object
        ...(fieldHelperObject?.required === undefined && { required: true })
      },
      {
        overrideProps: customProps[fieldId] || {}
      });
      const initialValueField = {
        ...(valueToFormat !== undefined && {
          initialValue: toFrontendValue(valueToFormat, {
            ...fieldHelperObject,
            ...customFieldProps
          })
        })
      };
      const hasInitialValue = valueExists(initialValueField?.initialValue, {
        ...fieldHelperObject,
        required: fieldIsRequired
      });
      const controlledComponents = fieldHelperObject?.controls
        ? formatControlledFields(existingData, fieldHelperObject.controls, options)
        : {};
      const defaultValueOverrides = (fieldHelperObject?.valid === true &&
        !isEmpty(fieldHelperObject?.value) && // Field has a hardcoded default value
        initialValueField?.initialValue !== fieldHelperObject?.value) || false;
      const component = {
        componentType: fieldHelperObject?.fieldType,
        ...(fieldHelperObject?.children && { children: fieldHelperObject?.children }),
        ...(hasInitialValue && initialValueField),
        props: {
          required: fieldIsRequired,
          ...fieldHelperObject,
          ...(disableFormFields && { disabled: true }),
          ...customFieldProps,
          ...(!isEmpty(controlledComponents) && { controls: controlledComponents }),
          ...(isControlledField && initialValueField?.initialValue !== undefined &&
            fieldHelperObject?.fieldType === 'input' && {
            ...(initialValueField?.initialValue === defaultValue && {
              // For `controls` input fields that have a default value, the `value` prop
              // needs to be set here so the Input component can register the default value
              value: defaultValue
            })
          }),
          ...(defaultValueOverrides && {
            ...(valueExists(initialValueField?.initialValue, fieldHelperObject)
              ? { // If value is different than hardcoded (ie, user changed it), set the BE value
                // to be the new value
                value: initialValueField?.initialValue
              }
              : {
                // If field has a default value/valid set, but was cleared & field is now
                // empty, reset the value/valid property
                value: undefined,
                valid: undefined
              })
          })
        }
      };
      return acc.concat(component);
    }, []);
  return components;
};

export const isFormFieldRequired = (formHelper, options) => {
  const { overrideProps = {} } = options || {};
  return isBool(overrideProps?.required) ? overrideProps.required : formHelper?.required;
};

export const getFieldsWithOverrides = (options) => {
  // Handles fields that are required for new apps, but may not exist for legacy apps
  const {
    allowOverrides,
    data,
    formFields,
    keys
  } = options || {};
  const fieldOverrides = allowOverrides // requirement to allow override props
    ? ((keys || []).reduce((acc, key) => (
      // Check for the fields that are currently empty in existing data,
      // and update their `required` prop to be `false`
      !isEmpty(data) && isEmpty(data[key]) && !isBool(data[key])
        ? { ...acc, [key]: { ...formFields ? formFields[key] : {}, required: false } }
        : acc
    ), {}))
    : {};
  return { ...formFields, ...fieldOverrides };
};

export const valueExists = (value, formHelper) => {
  const { fieldType, isCheckboxList, required } = formHelper || {};
  if (isBool(value)) { return true; }
  let exists = !isEmpty(value);
  const isRequiredCheckboxList = fieldType === 'checkboxList' && isCheckboxList && required;
  if (exists && isRequiredCheckboxList) {
    // If checkboxList is required, at least one item must be checked
    // for the value to be considered valid
    const checkedValues = Array.isArray(value)
      // Eg. [{ visa: true }, { mastercard: true }]
      ? value.reduce((acc, item) => acc.concat(Object.values(item)), [])
      : Object.values(value); // Eg. { visa: true, mastercard: true }
    exists = !isEmpty(checkedValues) ? checkedValues.includes(true) : false;
  }
  return exists;
};

export const getCheckedItemKeys = (value) => {
  // For checkboxList value, this will return an array of keys of only items that ARE checked
  if (isEmpty(value)) { return []; }
  if (Array.isArray(value)) { // eg. [{ key1: true }, { key2: false }]
    return value.reduce((acc, item) => (!isEmpty(item) && Object.values(item).includes(true)
      ? acc.concat(...Object.keys(item))
      : acc), []);
  }
  // else is an object, eg. { key1: true, key2: false }
  return Object.entries(value).reduce((acc, [key, isChecked]) => (isChecked
    ? acc.concat(key)
    : acc), []);
};

const formatControlledFields = (backendData = {}, controls = {}, options = {}) => {
  const controlledFormComponents = Object.entries(controls)
    .reduce((acc, [controlKey, controlFields]) => ({
      ...acc,
      [controlKey]: buildFormComponents(backendData, controlFields, {
        ...options,
        isControlledField: true
      })
    }), {});
  return controlledFormComponents;
};

// Checks if the form `data` on load is valid for a custom list item
export const isCustomListValid = (dataArray = []) => {
  let dataValidOnLoad = false;
  if (!isEmpty(dataArray)) {
    dataValidOnLoad = dataArray.every(item => item[item.customKey] === true);
  }
  return dataValidOnLoad;
};

export const formatTabData = (fieldState, fieldsObject, KEY, options) => {
  const {
    customSectionError = '' // custom error message to display for a custom section's fields
  } = options || {};
  const fieldEntries = fieldState ? Object.entries(fieldState) : [];
  const nextFields = { ...fieldsObject } || {};
  const { formComponents } = nextFields || [];
  const hasCustomListData = !isEmpty(fieldsObject?.customListData);
  if ((!isEmpty(formComponents) || hasCustomListData) && !isEmpty(fieldEntries)) {
    fieldEntries
      .forEach(([key, value]) => {
        const isValid = /IsValid$/.test(key);
        if (key !== KEY && key !== 'formInProgress' && !isEmpty(key)) {
          const compIndex = !isEmpty(formComponents) ? formComponents
            .findIndex(comp => comp.props.id === (isValid ? key.replace('IsValid', '') : key)) : -1;
          if (compIndex !== -1) {
            const temp = {
              ...formComponents[compIndex],
              props: {
                ...formComponents[compIndex].props,
                ...(isValid ? { valid: value } : { value }),
                setError: {
                  ...(customSectionError && { message: customSectionError })
                }
              }
            };
            formComponents[compIndex] = temp;
          }
        }
      });
    return nextFields;
  }
  return {};
};

export const handleConditional = (
  // This currently is only handling when CONDITIONAL field
  // and TARGET field are in the same section...
  sectionData, // that sectionData that would be passed to a FormAssistant
  conditional, // { key: publicCompany, value: string }
  target // { key: stockSymbol, value: bool }
) => {
  if (isEmpty(sectionData) || isEmpty(conditional) || isEmpty(target)) return {};
  const targetIndex = [...sectionData.formComponents]
    .findIndex(comp => comp.props.id === target?.key);

  const conditionalIndex = [...sectionData.formComponents]
    .findIndex(comp => comp.props.id === conditional?.key);

  const newComps = [...sectionData.formComponents] || [];
  if (targetIndex !== -1 && conditionalIndex !== -1 && !isEmpty(newComps)) {
    const meetsReq = sectionData
      .formComponents[conditionalIndex].props?.value === conditional?.value;
    newComps[targetIndex] = {
      ...sectionData.formComponents[targetIndex],
      props: {
        ...sectionData.formComponents[targetIndex].props,
        required: meetsReq ? target.value : !target.value
      }
    };
  }
  return {
    ...sectionData,
    formComponents: newComps
  };
};

export const formatTabCallbackData = (tabKey, fullFormState, constructorRefs) => {
  if (!isEmpty(tabKey) && !isEmpty(fullFormState) && !isEmpty(constructorRefs)) {
    const isTabValid = Object.entries(fullFormState)
      .every(([sectionKey, sectionObject]) => !isEmpty(sectionObject) &&
      sectionObject[sectionKey]);
    const newTabData = Object.entries(fullFormState)
      .reduce((acc, [sectionKey, sectionObject]) => {
        const cRef = constructorRefs[sectionKey];
        return {
          ...acc,
          [sectionKey]: !isEmpty(sectionObject) && !isEmpty(cRef)
            ? formatTabData(sectionObject, cRef, cRef.id)
            : {}
        };
      }, {});
    const options = {
      tabKey,
      tabData: newTabData,
      tabValid: isTabValid
    };
    return options;
  }
  return {};
};

// creates options object for a field that controls the visibility of possible options
// Eg: Dropdown has different Input fields that appear based on the Dropdown selection
// this will create a map of all the possible conditional options
// Eg. Returns { option1Id: { id: 'option1IdOption' formComponents: [], ... } }
export const buildConditionalOptions = (
  backendData, // OPTIONAL, if there is any data to pre-populate with
  formHelperFields, // REQUIRED the options form helpers to populate
  defaultBuildOptions = {} // OPTIONAL, any custom disabling of fields
) => {
  if (!isEmpty(formHelperFields)) {
    const optionsArray = Object.entries(formHelperFields)
      .filter(([optionKey]) => optionKey !== 'id')
      .reduce((acc, [optionKey, optionObject]) => {
        const formComponents = buildFormComponents(
          !isEmpty(backendData) ? backendData : {},
          { [optionObject.id]: optionObject },
          defaultBuildOptions
        );
        return {
          ...acc,
          // 'Option' is appended to the id here so it doesn't conflict with the actual field id
          // when being used with FormAssistant
          [`${optionObject.id}Option`]: {
            ...defaultSectionOptions,
            id: `${optionObject.id}Option`,
            formComponents
          }
        };
      }, {});
    return optionsArray;
  }
  return {};
};

export const getRequiredWithEmptyValues = (options) => {
  // Returns form field title (Title Case key) and value (camelCase key)
  // for fields that are required and empty
  const { formFields, valuesForBackend } = options || {};
  const requiredAndEmptyFields = !isEmpty(valuesForBackend)
    ? Object.entries(valuesForBackend).reduce((acc, [key, value]) => {
      const formField = formFields[key];
      const { label, required } = formField || {};
      const hasValue = valueExists(value, formField);
      return required && !hasValue
        ? acc.concat({ title: label, value: key })
        : acc;
    }, [])
    : null;
  return requiredAndEmptyFields;
};

// Form Props

// for forms using FormAssistant where the form has labels inside the element boxes
export const defaultSectionOptions = { componentLabelInside: true };

const globalFormProps = {
  input: {
    wrapperStyle: {
      flex: '33%',
      minWidth: '150px',
      margin: 'unset',
      marginLeft: '-1px'
    },
    required: true,
    boxStyle: 'inside',
    clearAutofillOnMount: true
  },
  dropdown: {
    wrapperStyle: {
      flex: '33%',
      minWidth: '150px',
      margin: 'unset'
    },
    required: true,
    boxStyle: 'inside'
  },
  timestamp: { // for InputTimestamp fields
    isTimestamp: true,
    required: true,
    valid: false,
    value: ''
  },
  radio: {
    direction: 'horizontal',
    shape: 'square',
    size: 'sm',
    boxStyle: 'inside',
    wrapperStyle: {
      margin: 'unset',
      flex: '33%',
      minWidth: '150px'
    },
    required: true
  },
  checkbox: {
    type: 'mini',
    labelPos: 'left',
    boxStyle: 'inside',
    wrapperStyle: {
      flex: 'auto',
      maxWidth: 'fit-content',
      height: 'unset',
      marginBottom: 'unset',
      lineHeight: '1.5',
      padding: '0 5px'
    },
    required: true
  },
  checkboxList: {
    type: 'mini',
    labelPos: 'top',
    boxStyle: 'inside',
    containerStyle: {
      alignContent: 'baseline',
      flex: '33%',
      margin: '-1px'
    },
    wrapperStyle: {
      display: 'flex',
      flexWrap: 'wrap'
    },
    required: true
  }
};

export default globalFormProps;
