import styled from 'styled-components';

export const DataTableWrap = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    /* never allow table to be taller than the available height (minus header+padding) */
    max-height: calc(100vh - var(--height-header));
    position: relative;
    padding-bottom: 5px;
    border-bottom: 1px solid transparent;
    .tableFooter {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      align-items: center;
      margin-top: 5px;
    }
    #downloadButton {
      margin-top: 5px;
      margin-bottom: 5px;
      z-index: 2;
      padding-top: 0;
      padding-bottom: 0;
      background-size: 16%;
      height: 30px;
      background-size: 1.5rem;
    }
`;

export const StyledTable = styled.table`
  width: 100%;
  margin: 0 auto;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 1.3rem;
  thead {
    text-align: left;
    white-space: nowrap;
    z-index: 4;
    th {
      border: none;
      font-size: 1em;
      white-space: pre-line;
      font-size: 1rem;
      line-height: 1.5;
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 4;
      background-color: var(--color-bg);
      padding: 10px 20px 10px 10px;
      cursor: pointer;
      border-right: 1px solid var(--color-data-border);
      box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.1);
      &.hasSelect {
        padding-left: 30px;
        label {
          min-height: 15px !important;
          min-width: 15px !important;
          position: absolute !important;
          top: 50% !important;
          left: 10px !important;
          transform: translate(0, -50%) !important;
        }
      }
      &.topHeader {
        text-align: center;
        white-space: nowrap;
        z-index: 4;
        font-size: 1.2em;
        border: 1px solid var(--color-data-border);
        padding: 0.75em;
      }
      &.desc, &.asc {
        padding: 0 20px 0 10px;
        cursor: pointer;
        &::after {
          position: absolute;
          top: 50%;
          right: 5px;
          transform: translate(0, -50% );
          content: '';
          display: block;
          background-size: 10px;
          background-repeat: no-repeat;
          transition: clip-path 0.2s;
          width: 15px;
          height: 15px;
          background: var(--color-grey);
          transition: 0.2s;
      }
      &.desc:after {
        clip-path: polygon(14% 80%, 0% 66%, 50% 20%, 100% 66%, 86% 80%, 50% 46%);
      }
      &.asc:after {
        clip-path: polygon(0% 34%, 14% 20%, 50% 54%, 86% 20%, 100% 34%, 50% 80%);
      }
      &.actions {
        background-color: var(--color-bg);
        border-color: var(--color-whiteish);
        cursor: default;
        font-size: 1em;
        padding: 10px;
      }
    }
  }
`;

export const StyledTableRow = styled.tr`
  &.disabled {
    color: var(--color-light-label);
  }
  &.warning {
    background: var(--color-stoplight-yellow);
    td:nth-child(odd) .table-invert-color {
      background: var(--color-stoplight-yellow);
    }
  }
  &.overdue {
    color: white;
    background: var(--color-stoplight-red);
    td:nth-child(odd) .table-invert-color {
      background: var(--color-stoplight-red);
    }
  }
  &.active {
    background: var(--color-row-selected);
  }
  &.hasCallback {
    &:not(.disabled) {
      cursor: pointer;
      &:hover {
        background: var(--color-row-hover);
        &.warning {
          background: var(--color-stoplight-yellow-hover);
          td:nth-child(odd) .table-invert-color {
            background: var(--color-stoplight-yellow);
          }
        }
        &.overdue {
          background: var(--color-stoplight-red-hover);
          td:nth-child(odd) .table-invert-color {
            background: var(--color-stoplight-red);
          }
        }
        &.active {
          background: var(--color-row-selected-hover);
        }
      }
    }
  }
`;
