import { ticketRequestDropdownFields, ticketStatusList } from '@f1/shared/src/_formFields';
import {
  camelToTitle,
  formatDateForFEView,
  ignoreCase,
  isEmpty,
  isEqual,
  snakeToTitle
} from '@f1/shared/src/_helpers';
import validations from '@f1/shared/src/_validations';

const ticketsTemplate = {
  frontend: (schema, version) => {
    if (version === '1.0' && !isEmpty(schema)) {
      const ticketList = formatDataArray(schema.ticketList);
      return {
        ticketList,
        ticketListHeaders: {
          ...Object.keys(ticketList?.[0] || {})?.reduce((acc, aKey) => {
            acc[aKey] = camelToTitle(aKey);
            return acc;
          }, {}),
          // override anything in ticketList object here
          requiredCompletionTime: 'Expected SLA time'
        }
      };
    }
    return { ticketList: [], ticketListHeaders: {} };
  },
  attachToResource: (schema, version) => {
    if (version === '1.0' && !isEmpty(schema)) {
      const relativePath = '';
      return {
        files: formatFileArray(schema?.files, relativePath)
      };
    }
    return {};
  },
  backend: (schema, version) => {
    if (version === '1.0') {
      const {
        ticketRequestSubForm,
        descriptionObject,
        requestType,
        isPartner
      } = schema;
      const title = formatTitle(requestType, isPartner);
      const descriptionArray = Object.entries(descriptionObject).map(([key, value]) => ([camelToTitle(key), value])).join('\n');
      const [description, chargebackEmails] = descriptionArray.split('Chargeback Notification Email Addresses,');
      const chargebackEmailList = !isEmpty(chargebackEmails) ? `Chargeback Notification Email Addresses: ${chargebackEmails}` : '';
      const formattedDescription = `${description.split(',').join(': ')}${chargebackEmailList}`;
      const { externalWatchers } = ticketRequestSubForm?.valuesForBackend || {};
      return {
        description: formattedDescription,
        title,
        externalWatchers: !isEmpty(externalWatchers)
          ? externalWatchers.reduce((acc, item) => {
            const { value } = item || {};
            // don't allow portal users to add corvia emails to watchers
            const isCorviaEmail = validations.corviaEmail.test(value);
            return isCorviaEmail ? acc : acc.concat(value);
          }, [])
          : []
      };
    }
    return {};
  }
};

const formatDataArray = data => data?.map(item => ({
  ticketId: item?.ticketId,
  title: item?.title,
  description: formatDescriptionPortalView(item?.description),
  status: formatStatusFrontend(item?.status),
  createdTimestamp: formatDateForFEView(item?.createdTimestamp, { includeTime: true }),
  requiredCompletionTime: formatDateForFEView(
    item?.requiredCompletionTime,
    { includeTime: true }
  ),
  ticketBusinessCode: item?.ticketBusinessCode,
  portalUserId: item?.requestedByPortalUser?.portalUserId,
  portalUserEmail: item?.requestedByPortalUser?.email,
  watchers: !isEmpty(item?.externalWatchers)
    ? item.externalWatchers.reduce((acc, email) => {
      // don't show corvia emails to portal users
      const isCorviaEmail = validations.corviaEmail.test(email);
      return [
        ...acc,
        ...(!isCorviaEmail && !acc.includes(ignoreCase(email)) ? [email] : [])
      ].sort();
    }, []).join(', ')
    : '-',
  merchantGuid: item?.merchant?.merchantGuid,
  merchantDbaName: item?.merchant?.dbaName,
  merchantLegalName: item?.merchant?.legalName,
  relationshipId: item?.relationship?.relationshipId,
  relationshipName: item?.relationship?.name
}));

const formatStatusFrontend = (statusEnum) => {
  const formattedEnum = ignoreCase(statusEnum || '');
  const statusMatch = ticketStatusList.find(
    item => (formattedEnum && item.value === formattedEnum)
  ) || {};
  return statusMatch.title ?? snakeToTitle(formattedEnum || '');
};

const formatDescriptionPortalView = (text) => {
  // Filters out guid info for portal user description field
  const lines = !isEmpty(text) ? (text.trim()).split('\n') : [];
  return !isEmpty(lines)
    ? lines.filter((line) => {
      const isGuidText = ['relationship id', 'merchant id'].some(id => ignoreCase(line || '').includes(id));
      return !isGuidText;
    }).join('\n')
    : '';
};

const formatFileArray = (files, relativePath) => files?.map(file => ({
  fileName: file.name,
  tags: []
}));

export const formatTitle = (requestType, isPartner) => {
  const requestDropdownFields = ticketRequestDropdownFields({ userType: isPartner ? 'partner' : 'merchant' });
  const ticketReqMatch = requestDropdownFields.find(field => isEqual(field.value, requestType));
  const ticketTitle = (!isEmpty(ticketReqMatch) && ticketReqMatch.title) ||
    camelToTitle(requestType);
  return ticketTitle;
};

export default ticketsTemplate;
