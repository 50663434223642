import React from 'react';
import {
  getFormattedRelationship,
  dedupeList,
  isEmpty,
  getLocaleString,
  ignoreCase,
  snakeToTitle,
  formatDateForFEView
} from '../../../_helpers';
import { toBackendValue } from '../../../_templateHelpers';
import {
  crabAppStatusList,
  crabMasterTaskList
} from '../../../_crabFields';
import { Icon } from '../../../../css/_styledComponents';

const processorConfiguration = {
  // conform processor names to processorNameList enums
  repay: {
    preferred: {
      isDefaultMpa: false
    },
    standard: {
      isDefaultMpa: false
    },
    elevated: {
      isDefaultMpa: false
    }
  },
  micamp_processing_maverick: {
    // preferred risk not supported with this processor
    standard: {
      isDefaultMpa: true
    },
    elevated: {
      isDefaultMpa: true
    }
  },
  netevia: {
    preferred: {
      isDefaultMpa: true
    },
    standard: {
      isDefaultMpa: false
    },
    elevated: {
      isDefaultMpa: false
    }
  },
  priority: {
    preferred: {
      allowedBanks: ['axiom', 'pueblo_bank_and_trust', 'synovus', 'wells_fargo'],
      isDefaultMpa: false
    },
    standard: {
      allowedBanks: ['axiom', 'pueblo_bank_and_trust', 'synovus', 'wells_fargo'],
      isDefaultMpa: false
    },
    elevated: {
      allowedBanks: ['axiom', 'pueblo_bank_and_trust', 'synovus', 'wells_fargo'],
      isDefaultMpa: false
    }
  }
};

export const useDefaultProcessor = (relationship) => {
  const formattedRelationship = getFormattedRelationship(relationship || {});
  const { bankName, riskProfile, processName } = formattedRelationship || {};
  if (!isEmpty(processorConfiguration[processName]) &&
    !isEmpty(processorConfiguration[processName][riskProfile])) {
    const { allowedBanks, isDefaultMpa } = processorConfiguration[processName][riskProfile] || {};
    return isEmpty(allowedBanks)
      ? isDefaultMpa === true
      : !allowedBanks.includes(bankName) && isDefaultMpa === true;
  }
  return true;
};

const getAppReviewStatuses = options => crabAppStatusList.filter((item) => {
  const { includeLegacy } = options || {};
  return item.isAppReviewStatusFilter ||
  // Keep for viewing legacy apps
  (includeLegacy ? item.isOpsStatusFilter || item.isCreditStatusFilter : false);
}).map(item => item.value);
export const partnerStatuses = crabAppStatusList
  .filter(item => item.isPartnerStatus).map(item => item.value);
export const openStatuses = crabAppStatusList
  .filter(item => item.isCompleted !== true && item.isApprovedAndOpen !== true)
  .map(item => item.value);

export const getTaskItemMatch = (taskNameEnum) => {
  const taskNameMatch = !isEmpty(taskNameEnum) && crabMasterTaskList.find(
    item => item.value === taskNameEnum
  );
  return taskNameMatch || {};
};

export const crabV1ApplicationTemplate = {
  frontend: (schema, version) => { // GET /v1/application
    if (version === '1.0') {
      const {
        appIsClosed = false,
        applications, // BE api response
        userType, // one of: partner, employee
        allRelationships,
        allRelationshipsNoDownlines, // partner only: can edit apps for these relationships only
        assignedEmployeeList, // employee only: for Assigned To dropdown in meco
        employeeGroupList, // employee only: badge counts section
        isBankUser, // meco only
        bankNameFilter, // meco only: bank users - bank name to filter apps on
        userEmail, // employee only: badge counts section
        openApps, // open apps filter is on, so don't so complete column
        bankIcons = {},
        processorIcons = {}
      } = schema;
      const primaryKey = 'applicationId';
      const hiddenColumns = getAppListHiddenColumns({
        customHidden: [...defaultAppListHiddenColumns, 'Assigned To (Ops)', 'Assigned To (Credit)'],
        openApps,
        userType
      });
      const appStatusBarData = userType === 'partner'
        ? crabAppStatusList.reduce((acc, item) => ({ // partner only - app status bar
          ...acc,
          ...(!isEmpty(item.partnerAppStatusBarTitle) && { [item.partnerAppStatusBarTitle]: 0 })
        }), {})
        : {};
      const appStatusBarTitleMap = userType === 'partner'
        ? crabAppStatusList.reduce((acc, item) => ({ // partner only - app status bar
          ...acc,
          ...(!isEmpty(item.partnerAppStatusBarTitle) && {
            [item.value]: item.partnerAppStatusBarTitle
          })
        }), {})
        : {};
      const employeeBadgeCounts = { // employee only
        preferred: 0,
        standard: 0,
        elevated: 0
      };
      const isAppReview = isAppReviewEmployee(employeeGroupList);
      const employeeReviewsApp = userType === 'employee' && isAppReview;
      const completedAppStatuses = ['approved', 'withdrawn', 'declined'];
      const appsByStatus = applications.filter(app => openApps === 'all' || // don't filter if all is passed in
      (!appIsClosed && !completedAppStatuses
        .some(status => status === ignoreCase(app.applicationStatus))) ||
        (appIsClosed && completedAppStatuses
          .some(status => status === ignoreCase(app.applicationStatus))));
      const initialApps = isBankUser && !isEmpty(bankNameFilter)
        ? appsByStatus.filter(
          app => ignoreCase(app.relationship?.bankName) === ignoreCase(bankNameFilter)
        )
        : appsByStatus;
      const allTableData = initialApps.map((app) => {
        const appStatus = ignoreCase(app.applicationStatus);
        const titleMapMatch = appStatusBarTitleMap[appStatus];
        const appRiskProfile = ignoreCase(app.relationship?.riskProfile);
        const appProcessor = ignoreCase(app.relationship?.processName || app.relationship?.processorName || '');
        const { tableFields, otherFields } = crabV1ApplicationTemplate.frontendSelectedApp({
          app,
          options: {
            userType,
            allRelationships,
            allRelationshipsNoDownlines,
            appIsClosed,
            userEmail,
            employeeGroupList,
            bankIcons,
            processorIcons
          }
        }, version);
        if (userType === 'partner' && titleMapMatch) { appStatusBarData[titleMapMatch] += 1; }
        if (employeeReviewsApp && !isEmpty(appRiskProfile) && !isEmpty(appProcessor)) {
          const statusList = getAppReviewStatuses();
          const isAppReviewStatus = statusList.includes(appStatus);
          if (isAppReviewStatus) {
            const increaseCount = otherFields?.includeCountOnLoad;
            if (increaseCount) { employeeBadgeCounts[appRiskProfile] += 1; }
          }
        }
        return { ...tableFields, ...otherFields };
      });
      const allAppsData = {
        tableData: allTableData,
        filteredDataOnLoad: employeeReviewsApp
          ? allTableData.filter(item => item.displayTableRowOnLoad)
          : allTableData,
        hiddenColumns,
        tablePrimaryKey: primaryKey,
        badgeCounts: employeeBadgeCounts,
        dropLists: {
          ...(!isBankUser && userType === 'employee' && {
            assignedEmployeeList: dedupeList(assignedEmployeeList || []),
            relationshipList: allRelationships
          })
        }
      };
      return {
        allAppsData,
        ...(userType === 'partner' && { appStatusBarData })
      };
    }
    return schema;
  },
  frontendSelectedApp: (schema, version) => {
    if (version === '1.0') {
      const { app, options } = schema || {};
      const {
        userType,
        allRelationships,
        allRelationshipsNoDownlines, // partner only
        appIsClosed,
        userEmail,
        employeeGroupList, // employee only
        bankIcons = {},
        processorIcons = {}
      } = options || {};
      const primaryKey = 'applicationId';
      const appStatus = ignoreCase(app.applicationStatus);
      const appSubmittedToBank = app.submittedToBank || false;
      const appSignatureType = ignoreCase(app.signatureType || '');
      const appCompleted = appIsClosed || ['approved', 'withdrawn', 'declined'].includes(appStatus);
      const relationshipArray = allRelationshipsNoDownlines || allRelationships || [];
      const relationshipMatch = relationshipArray?.find(r => r.value ===
        app.relationship?.relationshipId) || app.relationship || {};
      const appRiskProfile = ignoreCase(relationshipMatch?.riskProfile || '');
      const formattedRelationship = getFormattedRelationship(relationshipMatch || {});
      const { employeeOnlyFields } = app || {};
      const {
        underwritingRiskLevel,
        assignedAppReviewEmployee,
        assignedCreditEmployee,
        assignedOperationsEmployee,
        gdsDecision
      } = employeeOnlyFields || {};
      const formattedAssignedAppReviewEmployee = !isEmpty(assignedAppReviewEmployee)
        ? formatAssignedEmployee(assignedAppReviewEmployee)
        : {};
      const formattedAssignedCreditEmployee = !isEmpty(assignedCreditEmployee)
        ? formatAssignedEmployee(assignedCreditEmployee) // Keep for viewing legacy apps
        : {};
      const formattedAssignedOpsEmployee = !isEmpty(assignedOperationsEmployee)
        ? formatAssignedEmployee(assignedOperationsEmployee) // Keep for viewing legacy apps
        : {};
      const employeeCanUpdate = userType === 'employee'
        ? canCurrentEmployeeUpdate({
          appIsClosed: appCompleted,
          employeeGroupList,
          userEmail,
          userType,
          assignedAppReviewEmployee: formattedAssignedAppReviewEmployee
        })
        : false;
      const filterAppProps = {
        relationship: relationshipMatch,
        appStatus,
        employeeGroupList,
        userEmail,
        assignedAppReviewEmployee: formattedAssignedAppReviewEmployee
      };
      const displayTableRowOnLoad = userType === 'employee'
        ? filterAppOnLoad({ ...filterAppProps })
        : true;
      const includeCountOnLoad = userType === 'employee'
        ? filterAppOnLoad({
          applyAssignedToFilters: true, // include in risk-profile red bubble count
          ...filterAppProps
        })
        : false;
      const isDefaultProcessor = useDefaultProcessor(formattedRelationship);
      const statusMatch = crabAppStatusList
        .find(item => item.value === appStatus) || {};
      const partnerHasDirectAccess = userType === 'partner'
        // partner must have direct access to the relationship to update
        ? !isEmpty(allRelationshipsNoDownlines?.find(r => (
          !isEmpty(formattedRelationship?.relationshipId) &&
          (r?.value === formattedRelationship?.relationshipId ||
            r?.value === formattedRelationship?.relationshipId))))
        : false;
      const canUpdateUnderwritingRiskLevel = userType === 'employee'
        ? canEmployeeUpdateRiskLevel(employeeGroupList)
        : false;
      const tableFields = { // fields visible in the table
        ...(userType === 'partner' && { deleteApp: ['draft', 'waiting_on_initial_signature'].includes(appStatus) && partnerHasDirectAccess ? app.applicationId : null }),
        applicationNumber: renderApplicationNumber(app, {
          bankIcons,
          processorIcons,
          userType,
          formattedRelationship
        }),
        applicationName: app.applicationName,
        ...(userType === 'employee' && {
          'Dba Name(s)': isEmpty(app.dbaNameList)
            ? '-'
            : (
              <div style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '0.4em',
                margin: '0.2em 0',
                whiteSpace: 'nowrap'
              }}
              >
                {app.dbaNameList.map((aDba, index) => (
                  <div
                    key={`${app.applicationBusinessNumber}_${aDba}_${index.toString()}`}
                    style={{
                      lineHeight: '100%',
                      padding: '0.3em',
                      borderRadius: 'var(--radius-small)',
                      width: 'fit-content'
                    }}
                    className={app.dbaNameList.length > 1 ? 'table-invert-color' : null}
                  >
                    {aDba}
                  </div>
                ))}
              </div>
            )
        }),
        legalName: app.legalName || '-',
        status: statusMatch.title || snakeToTitle(appStatus),
        ...(userType === 'employee' && {
          'Assigned To (App Review)': formattedAssignedAppReviewEmployee?.firstName || '-',
          ...(!isEmpty(formattedAssignedOpsEmployee?.firstName) && { 'Assigned To (Ops)': formattedAssignedOpsEmployee?.firstName }), // Keep for viewing legacy apps
          ...(!isEmpty(formattedAssignedCreditEmployee?.firstName) && { 'Assigned To (Credit)': formattedAssignedCreditEmployee?.firstName }) // Keep for viewing legacy apps
        }),
        created: getLocaleString(app.applicationCreatedTimestamp),
        submitted: getLocaleString(app.applicationSubmittedTimestamp) || '-',
        ...(appIsClosed && { completed: getLocaleString(app.applicationCompletedTimestamp) || '-' }),
        relationshipCode: formattedRelationship.relationshipCode || '-',
        ...(userType === 'employee' && { partnerName: app.partner?.partnerName || '-' }),
        signatureType: appSignatureType,
        ...(canUpdateUnderwritingRiskLevel && {
          underwritingRiskLevel
        }),
        ...(!isEmpty(app?.declinedReason) && { declinedReason: snakeToTitle(app?.declinedReason) }),
        ...(userType === 'employee' && !isEmpty(gdsDecision) && { gdsDecision: snakeToTitle(gdsDecision) }),
        ...(!isEmpty(app?.applicationApiBoardedToProcessorTimestamp) &&
          {
            boardedToProcessorTimestamp:
             formatDateForFEView(app?.applicationApiBoardedToProcessorTimestamp,
               { includeTime: true })
          })
      };
      const otherFields = {
        // remaining fields NOT visible in table but may be used in other components
        applicationId: app[primaryKey],
        title: formattedRelationship?.relationshipName || '-', // for Relationship multiselect search
        canEdit: userType === 'partner'
          // partner must have direct access to the relationship to edit/add
          ? partnerHasDirectAccess &&
          // and app must be in an allowed edit status state
          ['draft', 'waiting_on_partner'].includes(appStatus)
          : false,
        ...(userType === 'employee' && { // for ApplicationList filter bar
          currentEmployeeCanUpdate: employeeCanUpdate,
          displayTableRowOnLoad,
          includeCountOnLoad,
          relationship: `${formattedRelationship.relationshipCode} - ${formattedRelationship?.relationshipName}`,
          assignedAppReviewEmployeeId: formattedAssignedAppReviewEmployee.employeeId,
          assignedCreditEmployeeId: // Keep for viewing legacy apps
            formattedAssignedCreditEmployee.employeeId,
          assignedOperationsEmployeeId: // Keep for viewing legacy apps
            formattedAssignedOpsEmployee.employeeId
        }),
        relationshipId: formattedRelationship?.relationshipId,
        relationshipName: formattedRelationship?.relationshipName,
        isDefaultProcessor,
        riskProfile: appRiskProfile,
        submittedToBank: appSubmittedToBank,
        appPackage: { // all data for the current application package
          applicationId: app.applicationId,
          applicationBusinessNumber: app.applicationBusinessNumber,
          applicationName: app.applicationName,
          dbaNameList: app.dbaNameList,
          legalName: app.legalName,
          relationship: formattedRelationship,
          partner: {
            partnerId: app.partner?.partnerId,
            partnerBusinessCode: app.partner?.partnerBusinessCode,
            partnerName: app.partner?.partnerName
          },
          applicationCreatedTimestamp: app.applicationCreatedTimestamp,
          applicationSubmittedTimestamp: app.applicationSubmittedTimestamp,
          applicationCompletedTimestamp: app.applicationCompletedTimestamp,
          applicationApiBoardedToProcessorTimestamp: app?.applicationApiBoardedToProcessorTimestamp,
          applicationStatus: appStatus,
          assignedAppReviewEmployee: formattedAssignedAppReviewEmployee,
          assignedOperationsEmployee: formattedAssignedOpsEmployee,
          assignedCreditEmployee: formattedAssignedCreditEmployee,
          submittedToBank: appSubmittedToBank,
          signatureType: appSignatureType,
          requiresNewSignature: app.requiresNewSignature || false,
          ...(userType === 'employee' && {
            employeeOnlyFields: {
              underwritingRiskLevel,
              assignedAppReviewEmployee: formattedAssignedAppReviewEmployee,
              assignedOperationsEmployee: // Keep for viewing legacy apps
                formattedAssignedOpsEmployee,
              assignedCreditEmployee: // Keep for viewing legacy apps
                formattedAssignedCreditEmployee,
              gdsDecision
            }
          })
        }
      };
      return { tableFields, otherFields };
    }
    return schema;
  },
  backendSetStatus: (schema, version) => { // POST /v1/application (EMPLOYEE ONLY)
    if (version === '1.0') { // not shown, only handled in transform
      const { statusType } = schema || {};
      if (statusType === 'compilePends') {
        return { applicationStatus: toBackendValue('waiting_on_partner', {}) };
      }
      if (statusType === 'newSignature') {
        return { applicationStatus: toBackendValue('waiting_on_signature_post_pends', {}) };
      }
      if (statusType === 'waitingOnBank') {
        return { applicationStatus: toBackendValue('waiting_on_bank', {}) };
      }
      if (statusType === 'waitingOnAllotment') {
        return { applicationStatus: toBackendValue('waiting_on_bank_allotment', {}) };
      }
      if (statusType === 'pendedByBank') {
        return { applicationStatus: toBackendValue('pended_by_bank', {}) };
      }
      if (statusType === 'returnAppToEmployee') {
        return { applicationStatus: toBackendValue('waiting_on_app_review', {}) };
      }
      if (statusType === 'abandoned') {
        return { applicationStatus: toBackendValue('abandoned', {}) };
      }
    }
    return schema;
  },
  backendReopenApp: (schema, version) => { // POST /v1/application (EMPLOYEE ONLY)
    if (version === '1.0') {
      const { backendValues } = schema;
      const body = {
        description: backendValues?.description, // already in BE format
        applicationStatus: 'waiting_on_app_review' // not shown on form, only handled in transform
      };
      return body;
    }
    return schema;
  },
  backendCloseApp: (schema, version) => { // POST /v1/application (EMPLOYEE ONLY)
    const { backendValues, requestType } = schema || {};
    if (version === '1.0') {
      const { declineReason = '' } = backendValues || {};
      const { mpaFields } = schema || {};
      const body = {
        description: backendValues?.description, // already in BE format
        // not shown, only handled in transform
        applicationStatus: toBackendValue(requestType === 'withdraw' ? 'withdrawn' : 'declined', {}),
        ...(requestType === 'decline' && {
          declined: {
            declineReason,
            ...(declineReason === 'derogatory_credit' && {
              derogatoryCreditFields: {
                applicantName: `${mpaFields?.owners?.controllingOwner?.firstName} ${mpaFields?.owners?.controllingOwner?.lastName}`,
                // already in BE format
                applicantAddress: `${mpaFields?.owners?.controllingOwner?.streetAddress}, ${mpaFields?.owners?.controllingOwner?.city}, ${mpaFields?.owners?.controllingOwner?.state} ${mpaFields?.owners?.controllingOwner?.zip}`,
                // already in BE format
                creditScore: backendValues?.creditScore,
                reasons: (backendValues?.reasons || []).map((aReason, index) => {
                  if (aReason === 'otherSpecify') {
                    return `(other)${backendValues?.otherReason}`;
                  }
                  return aReason;
                })
              }
            })
          }
        })
      };
      return body;
    }
    return schema;
  }
};

const renderApplicationNumber = (data, options) => {
  const {
    bankIcons = {},
    processorIcons = {},
    userType,
    formattedRelationship
  } = options || {};
  const { bankName, processName } = formattedRelationship || {};
  const bankIcon = userType === 'employee' ? bankIcons[bankName] || null : null;
  const processorIcon = userType === 'employee' ? processorIcons[processName] || null : null;
  const iconStyles = {
    backgroundColor: 'transparent',
    backgroundSize: 'contain',
    borderRadius: 'var(--radius-small)',
    border: '1px solid var(--color-data-border)',
    height: '25px',
    width: '25px'
  };
  return !isEmpty(processorIcon) || !isEmpty(bankIcon)
    ? {
      title: data.applicationBusinessNumber,
      originalData: data.applicationBusinessNumber,
      renderComponent: () => (
        <div
          style={{
            display: 'flex',
            gap: '0.2em',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          {data.applicationBusinessNumber}
          <div style={{ display: 'flex', gap: '0.2em' }}>
            {!isEmpty(processorIcon) ? <Icon title={snakeToTitle(processName)} icon={processorIcon} style={iconStyles} /> : <div style={{ height: '25px', width: '25px' }} /> }
            {!isEmpty(bankIcon) ? <Icon title={snakeToTitle(bankName)} icon={bankIcon} style={iconStyles} /> : <div style={{ height: '25px', width: '25px' }} /> }
          </div>
        </div>
      )
    }
    : data.applicationBusinessNumber;
};

export const filterAppOnLoad = (options) => {
  // Employees who review see a filtered list of apps waiting on them on initial load
  const {
    // appStatus, // required for `isAppWithCurrentEmployeeGroup`
    applyAssignedToFilters = false,
    employeeGroupList,
    userEmail,
    assignedAppReviewEmployee
  } = options || {};
  const { email: assignedAppReviewUserEmail } = assignedAppReviewEmployee || {};
  const isAppReview = isAppReviewEmployee(employeeGroupList);
  if (isAppReview) {
    const appIsWithCurrentEmployeeGroup = isAppWithCurrentEmployeeGroup(options);
    const assignedToAppReviewUser = !isEmpty(userEmail) &&
      userEmail === assignedAppReviewUserEmail;
    // app is assigned to the current user
    // OR if app is unassigned, but is with the user's current employee group
    const includeAppReview = applyAssignedToFilters
      ? (assignedToAppReviewUser || isEmpty(assignedAppReviewUserEmail)) &&
        appIsWithCurrentEmployeeGroup
      : appIsWithCurrentEmployeeGroup;
    return includeAppReview;
  }
  return true;
};

export const isAppWithCurrentEmployeeGroup = (options) => {
  const {
    appStatus,
    employeeGroupList
  } = options || {};
  const isAppReview = isAppReviewEmployee(employeeGroupList);
  if (isAppReview) {
    const ignoreCaseAppStatus = ignoreCase(appStatus || '');
    const appWithPartner = partnerStatuses.includes(ignoreCaseAppStatus);
    if (appWithPartner) {
      return true;
    }
    // Keep for viewing legacy apps - if, for some reason there was an app
    // in waiting_on_credit or waiting_on_ops, this allows app_review access
    // to the app.
    const statusList = getAppReviewStatuses({ includeLegacy: true });
    const isAppReviewStatus = statusList.includes(ignoreCaseAppStatus);
    const groupAppIsWith = isAppReviewStatus ? 'app_review' : '';
    return ['app_review', 'app review'].includes(groupAppIsWith);
  }
  return false;
};

const canCurrentEmployeeUpdate = (options) => { // employee only
  const {
    appIsClosed,
    employeeGroupList,
    userEmail,
    userType,
    assignedAppReviewEmployee
  } = options || {};
  const isAppReview = isAppReviewEmployee(employeeGroupList);
  const assignedAppReviewUserEmail = userType === 'employee' ? assignedAppReviewEmployee?.email : '';
  const isAssignedToCurrentUser = userType === 'employee' && (!isEmpty(userEmail) && (
    isAppReview && !isEmpty(assignedAppReviewUserEmail) && userEmail === assignedAppReviewUserEmail
  ));
  return !appIsClosed && isAssignedToCurrentUser;
};

export const isAppReviewEmployee = (employeeGroupList = []) => {
  const isAppReview = !isEmpty(employeeGroupList) && employeeGroupList.some(g => ['app_review', 'app review'].includes(ignoreCase(g)));
  return isAppReview;
};

export const isAppReviewManagerEmployee = employeeGroupList => (!isEmpty(employeeGroupList) &&
  employeeGroupList.some(g => ['app_review_manager', 'app review manager'].includes(ignoreCase(g))));

// Columns that should ALWAYS be hidden in the ApplicationList table
export const defaultAppListHiddenColumns = ['created', 'signatureType', 'underwritingRiskLevel', 'isDefaultProcessor', 'declinedReason', 'gdsDecision'];

export const getAppListHiddenColumns = (options) => {
  const {
    openApps,
    userType,
    customHidden = []
  } = options || {};
  const primaryKey = 'applicationId';
  // optionally removed columns depending on status or filter
  const removeColumns = [
    ...(openApps === 'uncompleted_only' ? ['completed'] : []),
    ...(userType === 'employee' ? ['applicationName'] : [])
  ];
  // Hidden columns must be specified - for DataTable AND ApplicationDetails (non-DataTable)
  const hiddenColumns = [primaryKey, 'appPackage', 'submittedToBank', 'currentEmployeeCanUpdate', 'canEdit', 'title', 'assignedCreditEmployeeId', 'assignedAppReviewEmployeeId', 'assignedOperationsEmployeeId', 'relationship', 'relationshipId', 'relationshipName', 'riskProfile', 'badgeCounts', 'includeCountOnLoad', 'displayTableRowOnLoad', ...removeColumns, ...(!isEmpty(customHidden) ? customHidden : [])];
  return hiddenColumns;
};

export const formatAssignedEmployee = (employee) => { // Formats BE employee object to FE
  const formattedEmployee = {
    employeeId: employee?.employeeId || '',
    department: ignoreCase(employee?.department || ''),
    firstName: employee?.firstName || '',
    email: employee?.email || ''
  };
  return formattedEmployee;
};

const canEmployeeUpdateRiskLevel = (employeeGroupList) => {
  const appReviewCanUpdate = isAppReviewEmployee(employeeGroupList) ||
    isAppReviewManagerEmployee(employeeGroupList);
  return appReviewCanUpdate || false;
};

export const enableAddMpaButton = (options) => { // Enable/disable add/clone mpa button
  const {
    appRelationship,
    appStatus,
    currentMpaList,
    relationshipList,
    userType
  } = options || {};
  const { riskProfile, relationshipId } = appRelationship || {};
  if (userType === 'partner') {
    const appStatusValid = ignoreCase(appStatus) === 'draft'; // can be snake_case or Title Case
    const hasDirectRelationshipAccess = (!isEmpty(relationshipList) && relationshipList
      .some(r => r.value === relationshipId)) || false;
    const riskProfileValid = ['standard', 'elevated'].includes(ignoreCase(riskProfile));
    const mpaCountValid = !isEmpty(currentMpaList) && currentMpaList.length < 2;
    return appStatusValid && hasDirectRelationshipAccess && riskProfileValid && mpaCountValid;
  }
  return false;
};

export default crabV1ApplicationTemplate;
