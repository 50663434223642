import React from 'react';
import PropTypes from 'prop-types';
import { NoData } from '../index';
import { formatNumber, isEmpty } from './_helpers';
import { StyledMiniBar } from '../css/_styledComponents';

export const MiniBar = ({
  callback,
  className,
  customNoDataMessage,
  data,
  fill,
  id,
  style
}) => {
  const classNames = `mini-report-bar ${!isEmpty(className) ? ' '.concat(className) : ''}`;
  return (
    <StyledMiniBar
      id={id}
      fill={fill}
      className={classNames}
      style={style}
      hasCallback={!isEmpty(callback)}
    >
      {!isEmpty(data)
        ? (
          <>
            {data.map(item => (
              <div key={item.id} id={item.id} className="mini-report">
                <div className="title">{item.title}</div>
                <div className="value">
                  {isEmpty(item.type)
                    ? item.value
                    : <>{formatNumber(item.value, { ...(item.type === 'price') && { currency: true } })}</>}
                </div>
              </div>
            ))}
          </>
        )
        : <NoData customMessage={customNoDataMessage} />}
    </StyledMiniBar>
  );
};

MiniBar.propTypes = {
  callback: PropTypes.func,
  className: PropTypes.string,
  customNoDataMessage: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.array]),
  fill: PropTypes.string,
  id: PropTypes.string,
  style: PropTypes.oneOfType([PropTypes.object])
};

MiniBar.defaultProps = {
  callback: null,
  className: null,
  customNoDataMessage: null,
  data: null,
  fill: null,
  id: null,
  style: {}
};

export default MiniBar;
