import React from 'react';
import PropTypes from 'prop-types';
import { addToLocalDB, getFromLocalDB } from './_dataCompression';

import {
  isEmpty,
  logError,
  logAsWarn,
  setClickTrail
} from './_helpers';

export class ErrorBoundary extends React.Component {
  componentDidMount () {
    const { customOptions } = this.props;
    window.addEventListener('click', e => setClickTrail(e, {
      ...customOptions,
      getFromLocalDB,
      addToLocalDB
    }));
  }

  componentWillUnmount () {
    const { customOptions } = this.props;
    window.removeEventListener('click', e => setClickTrail(e, {
      ...customOptions,
      getFromLocalDB,
      addToLocalDB
    }));
  }

  handleError = async (error, errorInfo) => {
    const { customOptions = {}, store } = this.props;
    const clickTrail = await getFromLocalDB('clickTrail', customOptions);
    const { userEmail } = customOptions || {};
    const reduxStore = !isEmpty(store) ? store.getState() : {};
    const { authenticate } = reduxStore || {};
    const { isAuthenticated } = authenticate || {};
    const errorToLog = error.message || error;
    const page = (typeof window !== 'undefined') ? window.location.href : 'Unknown page';
    const customLogMessage = error.message
      ? `ON PAGE: ${page} USER: ${userEmail}${!isEmpty(clickTrail) ? `CLICKS: ${JSON.stringify(clickTrail)}` : ''} JAVASCRIPT ERROR NAME: ${error.name}: ${error.message} JAVASCRIPT ERROR TRACE: ${error.stack.toString()} JAVASCRIPT ERROR COMPONENT STACK: ${errorInfo?.componentStack}`
      : null;
    const options = {
      ...customOptions,
      ...(error.message && { // some random JS error.
        logLevel: logAsWarn(errorToLog, { customLogMessage }) || (!isEmpty(customLogMessage) && !isAuthenticated) ? 'WARN' : 'ERROR',
        customLogMessage
      })
    };
    logError(errorToLog, options);
  }

  componentDidCatch (error, errorInfo) {
    this.handleError(error, errorInfo);
  }

  render () {
    const { children } = this.props;
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  customOptions: PropTypes.oneOfType([PropTypes.object]),
  store: PropTypes.oneOfType([PropTypes.object])
};
ErrorBoundary.defaultProps = {
  children: null,
  customOptions: {},
  store: {}
};

export default ErrorBoundary;
