import React from 'react';
import PropTypes from 'prop-types';
import { MinMaxButton, DataBoxWrap } from '../css/_styledComponents';
import {
  Button
} from '../index';

export class DataBox extends React.PureComponent {
  constructor (props) {
    super(props);
    this.mounted = false;
    const { minimized } = this.props;
    this.state = {
      isMinimized: minimized
    };
  }

  componentDidMount () {
    this.mounted = true;
  }

  componentDidUpdate (prevProps) {
    const { minimized } = this.props;
    minimized !== prevProps.minimized && this.updateState({ isMinimized: minimized });
  }

  componentWillUnmount () {
    this.mounted = false;
  }

  updateState = (state, callback = null) => {
    this.mounted && this.setState(state, callback);
  }

  toggleMinimized = () => {
    const { isMinimized } = this.state;
    this.updateState({ isMinimized: !isMinimized });
  }

  render () {
    const { isMinimized } = this.state;
    const {
      className,
      children,
      title,
      subtitle,
      collapsible,
      buttonAlign,
      buttonCallback,
      buttonDisabled,
      buttonType,
      buttonText,
      buttonIcon,
      buttonSize,
      customHeaderItemStyle,
      customHeaderItem
    } = this.props;
    return (
      <DataBoxWrap
        {...this.props}
        title={null} // prevent `title` from appearing when hovering anywhere in databox
        className={[
          'dataBox',
          ...(className ? [className] : []),
          ...(isMinimized ? ['collapsed'] : [])
        ].join(' ')}
      >
        {(title || subtitle || collapsible || buttonCallback) && (
          <div className="titleWrapper">
            <div className="title">
              {collapsible && (
                <MinMaxButton className="collapseToggle" bgColor="var(--color-bg)" minimized={isMinimized} onClick={this.toggleMinimized} />
              )}
              {title}
              {subtitle && (
                <span className="subtitle">
                  {subtitle}
                </span>
              )}
            </div>
            {buttonCallback && (
              <Button
                className="addButton"
                aria-label={buttonIcon ? 'dataBoxAction' : buttonText || 'Add +'}
                type={buttonType}
                onClick={buttonCallback}
                size={buttonSize}
                align={buttonAlign}
                disabled={buttonDisabled}
                {...buttonIcon && {
                  icon: buttonIcon,
                  size: buttonSize || 'sm'
                }}
              >
                {buttonIcon ? '' : buttonText || 'Add +'}
              </Button>
            )}
            {customHeaderItem && (
              <div style={{ marginLeft: 'auto', ...customHeaderItemStyle }}>
                { customHeaderItem }
              </div>
            )}
          </div>
        )}
        <div className="dataBoxBody">
          { children }
        </div>
      </DataBoxWrap>
    );
  }
}

DataBox.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  collapsible: PropTypes.bool,
  buttonAlign: PropTypes.string,
  buttonCallback: PropTypes.func,
  buttonDisabled: PropTypes.bool,
  buttonType: PropTypes.string,
  buttonText: PropTypes.string,
  buttonSize: PropTypes.string,
  buttonIcon: PropTypes.oneOfType([PropTypes.object]),
  customHeaderItem: PropTypes.oneOfType([PropTypes.object]),
  customHeaderItemStyle: PropTypes.oneOfType([PropTypes.object]),
  minimized: PropTypes.bool
};

DataBox.defaultProps = {
  className: null,
  children: null,
  title: null,
  subtitle: null,
  collapsible: false,
  buttonAlign: 'right',
  buttonCallback: null,
  buttonDisabled: false,
  buttonType: 'text',
  buttonText: null,
  buttonSize: null,
  buttonIcon: null,
  customHeaderItem: null,
  customHeaderItemStyle: {},
  minimized: false
};

export default DataBox;
