import { isEmpty } from '../../_helpers';

export const staticMpas = {
  2: {
    mpa: {
      businessInformation: {
        dbaName: 'DBA Name 3',
        legalBusinessName: 'Invalid Fields *&^*&^',
        preferredMccCode: '5968',
        contactFullName: ' '
      },
      ratesAndFees: {
        statementFee: 50,
        terminalFee: 'cheese'
      }
    },
    fileList: []
  }
};

export const staticAddendums = {
  0: {
    usingNobAddendum: true,
    usingCorviaReserveForm: false,
    usingCorviaProductCountryCompliance: true
  }
};

export const mockMpaMetadataList = (options) => {
  const { length = 2, staticMpasFromApp = {} } = options || {};
  const mpaMetadataList = Array.from({
    length: !isEmpty(staticMpasFromApp)
      ? Object.values(staticMpasFromApp).length
      : length
  })
    .map((item, index) => mockMpaMetadata(index, options));
  return mpaMetadataList;
};

const mockMpaMetadata = (mpaIndex, options) => {
  const { staticMpasFromApp, relationship } = options || {};
  const { riskProfile } = relationship || {};
  const mpaMetadata = {
    applicationMpaId: `${mpaIndex}`,
    dbaName: `DBA Name ${mpaIndex + 1} (${riskProfile} risk)`,
    ...(staticMpas[mpaIndex]), // Default static MPAs for ALL apps
    ...(staticMpasFromApp && staticMpasFromApp[mpaIndex]) // Static MPAs for specific apps
  };
  return mpaMetadata;
};
