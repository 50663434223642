import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { isEmpty } from './_helpers';
import { radioCSS, input } from './_styles';
import '../css/animations.css';
import { ToolTip } from './ToolTip';
import {
  ErrorBox
} from '../css/_styledFormComponents';

export class Radio extends React.Component {
  constructor (props) {
    super(props);
    this.mounted = false;
    const { selected } = this.props;
    this.radioUniqueId = uuidv4(); // used for internal radio state only
    this.state = {
      hovered: null,
      checked: this.setSelected(selected),
      errorText: '',
      isValid: null
    };
  }

  componentDidMount () {
    const {
      fields,
      required,
      validationActivated,
      selected
    } = this.props;
    this.mounted = true;
    let optionalFields;
    if (!required && fields.findIndex(element => element.value === 'none') === -1) {
      optionalFields = fields.push({
        label: 'None',
        value: 'none'
      });
      this.mapRadioFields(optionalFields);
    } else {
      this.mapRadioFields(fields);
    }
    if (validationActivated) {
      this.setSelected(selected);
    }
  }

  componentDidUpdate (prevProps) {
    const { selected } = this.props;
    if (prevProps.selected !== selected) {
      this.updateState({
        checked: this.setSelected(selected)
      });
    }
  }

  componentWillUnmount () {
    this.mounted = false;
  }

  updateState = (state) => {
    this.mounted && this.setState(state);
  }

  setSelected = (selected) => {
    const { required, fields, name } = this.props;
    const index = fields.findIndex(field => field.value === selected);
    // TODO: ENG-2431, we no longer need to pass in the messy version of selected
    // update all calls to the Radio component to pass in a normal selected value
    const isValid = required ? selected !== null && selected !== 'none' : index > -1 || null;
    const errorText = (required && !isValid) ? 'Must select an option' : '';
    this.updateState({
      isValid,
      errorText
    });
    if (index !== -1) {
      if (selected === 'none') {
        return null;
      }
      // If we passed the raw value, return the correct selected id
      return `${name}${selected}${index}_${this.radioUniqueId}`;
    }
    return selected;
  }

  setId = (radio, index) => {
    const {
      name
    } = this.props;
    return `${name}${radio.value}${index}_${this.radioUniqueId}`;
  }

  mapRadioFields (fields) {
    if (fields.length) {
      fields.forEach((field, index) => {
        field.checked && this.updateState({ checked: field.value === 'none' ? null : `${field.value}${index}` });
        field.hovered && this.updateState({ hovered: `${field.value}${index}` });
      });
    }
  }

  handleCheck (event) {
    const { ...eventCopy } = event;
    const { required, callback, id } = this.props;
    const currentId = event.currentTarget ? event.currentTarget.id : null;
    const currentChecked = event.currentTarget ? event.currentTarget.checked : event;
    const isValid = required ? (event.currentTarget.checked !== null && event.currentTarget.checked !== 'none') : event.currentTarget.checked !== null || null;
    const errorText = (required && !isValid) ? 'Must select an option' : '';
    this.updateState({
      checked: event.currentTarget.value === 'none' ? null : currentId,
      isValid,
      errorText
    });
    if (callback) {
      callback(id, eventCopy.currentTarget.value === 'none' ? null : eventCopy.currentTarget.value, currentChecked);
    }
  }

  handleMouseIn (event) {
    const currentId = event.currentTarget ? event.currentTarget.htmlFor : null;
    this.updateState({ hovered: currentId });
  }

  handleMouseOut (e) {
    this.updateState({ hovered: null });
  }

  render () {
    const {
      wrapperStyle,
      size,
      shape,
      direction,
      boxStyle,
      name,
      fields,
      label,
      required,
      id,
      className,
      tooltip,
      infoTipDisplay,
      disabled,
      innerWrapperStyle
    } = this.props;
    const {
      checked,
      hovered,
      errorText,
      isValid
    } = this.state;
    const defaultClassName = required ? 'radioHeader required' : 'radioHeader';
    const customClassName = !isEmpty(className) ? ` ${className}` : '';
    return (
      <fieldset
        id={id}
        aria-label={!isEmpty(label) ? label : id}
        className={`${defaultClassName}${customClassName}`}
        {...(required && { required: true })}
        style={{
          border: '0', // override default `fieldset` style
          padding: '0', // override default `fieldset` style
          ...(boxStyle === 'inside' && radioCSS.innerWrap),
          ...((boxStyle === 'inside' && isValid) && { backgroundColor: 'var(--color-healthy-bg)' }),
          ...((boxStyle === 'inside' && !isEmpty(errorText)) && { backgroundColor: 'var(--color-warning-bg)' }),
          ...((disabled && boxStyle === 'inside') && input.innerWrapDisabled),
          ...wrapperStyle
        }}
      >
        { label && (
          <div
            style={{
              ...(boxStyle === 'inside' ? input.labelInside : input.label)
            }}
          >
            { required && (
              <span style={input.label_required}>* </span>
            )}
            {label}
            {tooltip && (
              <ToolTip infoTip {...infoTipDisplay && { infoTipDisplay }}>{tooltip}</ToolTip>
            )}
          </div>
        )}
        <div
          style={{
            ...(boxStyle === 'inside' && {
              padding: '10px',
              overflow: 'hidden'
            }),
            ...innerWrapperStyle
          }}
        >
          { fields.map((radio, index) => (
            <label
              key={radio.label}
              onFocus={this.handleMouseIn.bind(this)}
              onBlur={this.handleMouseOut.bind(this)}
              onMouseEnter={this.handleMouseIn.bind(this)}
              onMouseLeave={this.handleMouseOut.bind(this)}
              htmlFor={this.setId(radio, index)}
              style={{
                ...(size === 'lg' && {
                  ...radioCSS.label
                }),
                ...(size === 'sm' && {
                  ...radioCSS.label,
                  ...radioCSS.labelSmall
                }),
                ...(hovered === this.setId(radio, index) && radioCSS.labelHover),
                ...(checked === this.setId(radio, index) && radioCSS.labelChecked),
                ...((radio.disabled || disabled) && (boxStyle === 'inside' ? radioCSS.labelDisabledInside : radioCSS.labelDisabled)),
                ...(direction === 'horizontal' && { float: 'left', marginRight: '10px' }),
                ...(radio.tooltip && { display: 'flex', ...(direction === 'horizontal' && { marginRight: '34px' }) })
              }}
            >
              <input
                style={radioCSS.input}
                type="radio"
                checked={checked === this.setId(radio, index)}
                data-checked={checked === this.setId(radio, index)}
                name={`${name}${radio.value}${index}_${this.radioUniqueId}`}
                id={`${name}${radio.value}${index}_${this.radioUniqueId}`}
                value={radio.value}
                disabled={(radio.disabled || disabled)}
                onChange={event => this.handleCheck(event)}
              />
              {radio.label}
              {radio.tooltip && (
                <ToolTip
                  infoTip
                  infoTipDisplay={{ right: 'unset', top: 'unset', marginLeft: '3px' }}
                  {...radio.tooltipProps}
                >
                  {radio.tooltip}
                </ToolTip>
              )}
              <span style={{
                ...radioCSS.checkmark,
                ...(size === 'sm' && radioCSS.checkmarkSmall),
                ...((radio.disabled || disabled) && (boxStyle === 'inside' ? radioCSS.disabledInside : radioCSS.disabled)),
                ...(
                  (!(radio.disabled || disabled) && hovered === this.setId(radio, index)) &&
                  radioCSS.checkmarkHover
                ),
                ...(checked === this.setId(radio, index) && radioCSS.checkmarkChecked),
                ...(shape === 'circle' && radioCSS.circle)
              }}
              >
                <span style={{
                  ...radioCSS.check,
                  ...(size === 'sm' && radioCSS.checSmall),
                  ...(
                    (!(radio.disabled || disabled) && hovered === this.setId(radio, index)) && {
                      ...radioCSS.checkHover,
                      ...(size === 'sm' && radioCSS.checkHoverSmall)
                    }
                  ),
                  ...(checked === this.setId(radio, index) && {
                    ...radioCSS.checkChecked,
                    ...(size === 'sm' && radioCSS.checkCheckedSmall)
                  }),
                  ...(shape === 'circle' && radioCSS.circle)
                }}
                />
              </span>
            </label>
          ))}
        </div>
        <ErrorBox
          className="errors"
          id={`${id}-error`}
          error={!isEmpty(errorText)}
          boxStyle={boxStyle}
        >
          {errorText}
        </ErrorBox>
      </fieldset>
    );
  }
}

Radio.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
  direction: PropTypes.string,
  shape: PropTypes.string,
  boxStyle: PropTypes.string,
  fields: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  wrapperStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  callback: PropTypes.func,
  selected: PropTypes.string,
  required: PropTypes.bool,
  id: PropTypes.string,
  className: PropTypes.string,
  tooltip: PropTypes.string,
  infoTipDisplay: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  disabled: PropTypes.bool,
  validationActivated: PropTypes.bool,
  innerWrapperStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

Radio.defaultProps = {
  name: 'radios',
  label: null,
  size: 'lg',
  direction: 'vertical',
  shape: 'circle',
  boxStyle: null,
  fields: [],
  wrapperStyle: {},
  callback: () => {},
  selected: null,
  required: false,
  id: null,
  className: null,
  tooltip: null,
  infoTipDisplay: {},
  disabled: false,
  validationActivated: false,
  innerWrapperStyle: {}
};

export default Radio;
