import React from 'react';
import PropTypes from 'prop-types';
import FilesArea from './FilesArea';
import {
  isBool,
  isEmpty,
  camelToTitle
} from './_helpers';
import { legendCSS, flexboxCSS } from './_globals';
import { inlineFilesFormSectionCSS, input } from './_styles';
import { Icon } from '../css/_styledComponents';
import { icons } from '../images/_icons';
import { hasTagMatch } from './data/sharedBoarding/templates/filesWithTagsTemplate';

class InlineFilesFormSection extends React.Component {
  constructor (props) {
    super(props);
    this.mounted = false;
    this.state = {
      existingFiles: [], // existing files to show on load
      filesRequiredList: [] // required files criteria displayed in form section
    };
  }

  componentDidMount () {
    this.mounted = true;
    this.initRequiredFiles();
  }

  componentDidUpdate (prevProps) {
    const {
      existingFiles,
      requiredFiles,
      requiredFilesChangeTimestamp,
      timestamp
    } = this.props;
    if (existingFiles.length !== prevProps.existingFiles.length ||
      requiredFiles.length !== prevProps.requiredFiles.length ||
      requiredFilesChangeTimestamp > prevProps.requiredFilesChangeTimestamp ||
      timestamp > prevProps.timestamp) {
      this.updateState({ existingFiles: this.cleanExistingFiles() }, this.initRequiredFiles);
    }
  }

  componentWillUnmount () {
    this.mounted = false;
  }

  updateState = (state, callback = null) => {
    this.mounted && this.setState(state, callback);
  }

  cleanExistingFiles = () => {
    /**
     * Handles removing duplicate files (ie, file name) sent in the `existingFiles` prop,
     * so the same behavior can be seen on local/FT testing and real api testing
     */
    const { existingFiles } = this.props;
    const formatted = existingFiles.reduce((acc, eFile) => {
      const accFileMatch = acc?.find(accFile => accFile?.name === eFile?.name);
      /**
       * If the file is already included in `acc`, don't add it again.
       * This is so that in local/FT testing, the file names can still be seen when added,
       * while also for real api testing, dupe file names don't appear
      */
      return !isEmpty(accFileMatch) ? acc : acc.concat(eFile);
    }, []);
    return formatted;
  }

  initRequiredFiles = () => {
    const { useTags, requiredFiles } = this.props;
    const hasRequiredFiles = !isEmpty(requiredFiles) && !isEmpty(requiredFiles[0]?.backendKey);
    const cleanExistingFiles = this.cleanExistingFiles();
    const formattedRequiredFiles = hasRequiredFiles ? requiredFiles.map((file) => {
      // check for requiredFiles with useFileNameInput
      const hasFileName = isBool(file.useFileNameInput) ? file.useFileNameInput : false;
      const regToUse = file?.name || file?.backendKey || '';

      // check for existingFiles that match requiredFiles for Attached Files section
      let existingFile = !isEmpty(cleanExistingFiles)
        ? cleanExistingFiles.find(
          eFile => ((!isEmpty(regToUse) && !isEmpty(eFile.name) && regToUse === eFile.name) ||
          RegExp(`^${regToUse}\\.\\S+$`).test((eFile.name || '')))
        ) || {}
        : {};

      // If one required file option maps to multiple file types
      const allowedBackendKeys = !isEmpty(file.allowedOptions)
        ? file.allowedOptions.map(o => o.value)
        : [];
      const hasAllowedKeyMatch = f => allowedBackendKeys.some(allowedKey => (f.tagList || []).some(tagItem => (tagItem?.value || '').includes(allowedKey)));
      const newFileMatch = !isEmpty(allowedBackendKeys) && !isEmpty(cleanExistingFiles)
        ? cleanExistingFiles.find(eFile => hasAllowedKeyMatch(eFile))
        : {};
      if (!isEmpty(allowedBackendKeys) && !isEmpty(newFileMatch)) {
        existingFile = newFileMatch;
      }
      const overrideDisplayText = this.shouldReplaceFileName(file);
      const fileTypeMatches = overrideDisplayText
        ? cleanExistingFiles.filter((eFile) => {
          const tagMatchExists = hasTagMatch(eFile || {}, file || {});
          return tagMatchExists || eFile.name.startsWith(file.backendKey) ||
          hasAllowedKeyMatch(eFile);
        })
        : cleanExistingFiles.filter((eFile) => {
          const tagMatchExists = hasTagMatch(eFile || {}, file || {});
          return tagMatchExists || hasAllowedKeyMatch(eFile) ||
          (!isEmpty(eFile.backendKey) && !isEmpty(file.backendKey) &&
          eFile.backendKey === file.backendKey);
        });
      /**
       * For multiple of the same file type names (eg, driversLicense_1, driversLicense_2),
       * update the text displayed to only show a few
      */
      const customDisplayText = this.getCustomDisplayText({ fileTypeMatches, requiredFile: file });
      const fileRowValid = this.isFileRowValid({
        customDisplayText,
        fileTypeMatches,
        existingFile,
        requiredFile: file
      });

      return {
        // required:
        backendKey: file.backendKey,

        // optional - if not passed, will used default values:
        fileRequired: isBool(file.required) ? file.required : true,
        fileTypeDisplayText: file?.displayText || camelToTitle(file.backendKey),
        // to override the user's uploaded file name that gets sent in the api request
        replaceFileName: isBool(file.replaceFileName) ? file.replaceFileName : false,
        useFileNameInput: hasFileName,
        existingFileName: customDisplayText || existingFile?.name || '', // has `existingFiles` & file already uploaded
        tagList: useTags ? file?.tagList || [] : [],
        ...(file.allowedOptions && { allowedOptions: file.allowedOptions }),
        ...(file.requiredFileSubTypes && { requiredFileSubTypes: file.requiredFileSubTypes }),

        // default starting entries for each new row:
        name: '', // DO NOT CHANGE KEY NAME. actual name of the file added by the user
        fileNameDisplayText: '', // text to display in the required files list
        fileValid: fileRowValid,
        loadFile: {}, // on successful file upload, this is the successful loaded file response
        fileData: {}, // file data (encoded, etc)
        fileChanged: false // if user uploaded file already but then replaces it
      };
    }) : [];
    const formattedDropList = formattedRequiredFiles.map(row => ({
      useFileNameInput: row.useFileNameInput,
      title: row.fileTypeDisplayText,
      value: row.backendKey
    }));
    this.updateState({
      filesRequiredList: formattedRequiredFiles,
      selectFileTypeList: formattedDropList,
      ...(!isEmpty(cleanExistingFiles) && { existingFiles: cleanExistingFiles }),
      ...(!hasRequiredFiles && { enableFileType: true })
    });
  }

  isFileRowValid = (options) => {
    const {
      customDisplayText,
      fileTypeMatches,
      existingFile,
      requiredFile
    } = options || {};
    let rowIsValid = !isEmpty(customDisplayText)
      ? !isEmpty(fileTypeMatches) // at least one valid file of this type is already uploaded
      : !isEmpty(existingFile);
    if (
      !isEmpty(requiredFile.requiredFileSubTypes) &&
      requiredFile.requiredFileSubTypes.length > 1
    ) {
      rowIsValid = fileTypeMatches.length >= requiredFile.requiredFileSubTypes.length;
    }
    return rowIsValid;
  }

  getCustomDisplayText = (options) => {
    const { fileTypeMatches, requiredFile } = options || {};
    const { disableFormFields } = this.props;
    let customDisplayText = '';
    const allMatchNames = !isEmpty(fileTypeMatches)
      ? fileTypeMatches.map(fileMatch => fileMatch.name)
      : [];
    if (!isEmpty(requiredFile.requiredFileSubTypes) &&
      requiredFile.requiredFileSubTypes.length > 1) {
      if (!isEmpty(fileTypeMatches)) {
        const remainingRequired = requiredFile.requiredFileSubTypes.length -
          fileTypeMatches.length;
        customDisplayText = (
          <>
            {allMatchNames.join(', ')}
            {!disableFormFields && fileTypeMatches.length < requiredFile.requiredFileSubTypes.length
              ? (
                <div style={{ lineHeight: '1.5' }}>
                  <em>{`Please upload ${remainingRequired} additional file${remainingRequired > 1 ? 's' : ''}`}</em>
                </div>
              )
              : null}
          </>
        );
      }
    } else if (!isEmpty(fileTypeMatches)) {
      customDisplayText = allMatchNames.length > 3
        ? allMatchNames.slice(0, 3).join(', ').concat(' ...')
        : allMatchNames.join(', ');
    }
    return customDisplayText;
  }

  handleCallback = (options) => {
    const {
      cacheAndS3ErrorFiles,
      allFilesLoaded,
      filesLoaded,
      formattedUploadFiles,
      preloadRes,
      filesValid,
      filesError
    } = options || {};
    const { useCacheUpload, callback } = this.props;
    const cbOptions = {
      valid: filesValid,
      value: filesError ? [] : filesLoaded,
      allFilesLoaded,
      ...(useCacheUpload && { cacheAndS3ErrorFiles }),
      formattedUploadFiles,
      filesLoaded,
      preloadRes
    };
    callback && callback(cbOptions);
  }

  shouldReplaceFileName = (file) => {
    // replace the user's dropped file name with the `backendKey` file name
    const replace = file.replaceFileName === true && file.useFileNameInput !== true &&
      !isEmpty(file.backendKey);
    return replace;
  }

  render () {
    const {
      existingFiles,
      filesRequiredList
    } = this.state;
    const {
      dropzoneSize,
      userType,
      title,
      fileRequirements,
      disableFormFields,
      isFormDirty,
      axiosRequest,
      preloadEndpoint,
      useCacheUpload,
      cacheUploadEndpoint,
      requestGuid,
      useNewFormat,
      useParentCacheErrorSwal,
      requiredFiles,
      id,
      displayedFields,
      validateFilesCallback,
      contentWrapperStyle,
      wrapperStyle,
      validateFields,
      allowEditFileName,
      showBorders,
      isPublicRequest,
      customFileTypeList
    } = this.props;
    return (
      <div id={id} className="formSection inlineFilesSection" style={{ ...flexboxCSS.wrap, ...wrapperStyle }}>
        <div
          className="sectionHeader"
          style={
            showBorders
              ? { ...legendCSS.wrap, flex: '100%', margin: '-1px' }
              : { flex: '100%' }
          }
        >
          <div style={legendCSS.text}>
            {title || 'Files'}
          </div>
        </div>
        <div
          className="contentWrapper"
          style={{
            ...(showBorders
              ? { ...inlineFilesFormSectionCSS.contentWrapper }
              : { flex: '1 1 100%', position: 'relative' }),
            ...contentWrapperStyle
          }}
        >
          <div className="requiredSection" style={inlineFilesFormSectionCSS.requiredSection} role="list" aria-label="Required files list">
            {filesRequiredList.map((row, i) => {
              const useErrorStyles = validateFields && row.fileRequired &&
                ((isEmpty(row.fileNameDisplayText) && isEmpty(row.existingFileName)) ||
                !row.fileValid);
              return (
                <div
                  className="requiredRow"
                  role="listitem"
                  aria-label={`${row.fileTypeDisplayText} required file row`}
                  key={`${i.toString()}`}
                  style={{
                    ...inlineFilesFormSectionCSS.requiredRow,
                    ...(i % 2 !== 0 && { ...inlineFilesFormSectionCSS.requiredRowEven }),
                    ...(useErrorStyles && { backgroundColor: 'var(--color-warning-bg)', margin: '2px 0' }),
                    ...(!disableFormFields && row.fileValid && { backgroundColor: 'var(--color-healthy-bg)', color: 'var(--color-healthy)', margin: '2px 0' })
                  }}
                >
                  <div className="fileTypeHeading">
                    {row.fileRequired && (
                      <span style={input.label_required}>* </span>
                    )}
                    {useErrorStyles
                      ? (
                        <span style={input.label_required}>
                          {`${row.fileTypeDisplayText} - Missing required file${row.requiredFileSubTypes?.length > 1 ? '(s)' : ''}`}
                        </span>
                      )
                      : `${row.fileTypeDisplayText}${!isEmpty(row.requiredFileSubTypes) && row.requiredFileSubTypes?.length > 1 ? (' (').concat(row.requiredFileSubTypes.length).concat(' files required)') : ''}`}
                    {!isEmpty(row.requiredFileSubTypes)
                      ? (
                        <div style={{ margin: '0 0 0.2em 1.5em', lineHeight: '2', color: 'var(--color-light-label)' }}>
                          {row.requiredFileSubTypes.map(subTypeItem => (
                            <div key={subTypeItem.value}>{subTypeItem.title}</div>
                          ))}
                        </div>
                      )
                      : null}
                  </div>
                  <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    alignSelf: 'baseline',
                    gap: '0.5em'
                  }}
                  >
                    <div
                      className="fileNameText"
                      style={{
                        textAlign: 'right',
                        ...((isEmpty(row.fileNameDisplayText) && isEmpty(row.existingFileName)) && {
                          fontStyle: 'italic'
                        })
                      }}
                    >
                      {row.fileNameDisplayText || row.existingFileName || (!isEmpty(row.requiredFileSubTypes) && row.requiredFileSubTypes?.length > 1 ? `Please upload ${row.requiredFileSubTypes.length} files` : ((disableFormFields && '-') || (!disableFormFields && `Please upload a file`)))}
                    </div>
                    {row.fileValid && (
                      <Icon color="var(--color-healthy)" icon={icons.check.src_color} useMask style={{ marginRight: '0' }} />
                    )}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="fileRequirements" style={inlineFilesFormSectionCSS.fileReqWrapper}>
            <strong>File Requirements</strong>
            <ul style={inlineFilesFormSectionCSS.fileReqUl}>
              <li style={inlineFilesFormSectionCSS.fileReqLi}>
                Files must be less than 4.9 GB in size
              </li>
              <li style={inlineFilesFormSectionCSS.fileReqLi}>
                File names must be unique
              </li>
              {!isEmpty(fileRequirements) && fileRequirements.map((fileReq, fileReqIndex) => (
                <li
                  key={`${fileReqIndex.toString()}`}
                  style={inlineFilesFormSectionCSS.fileReqLi}
                >
                  {fileReq}
                </li>
              ))}
            </ul>
          </div>
          <FilesArea
            useParentCacheErrorSwal={useParentCacheErrorSwal}
            dropzoneSize={dropzoneSize}
            requiredFiles={requiredFiles}
            customFileTypeList={customFileTypeList}
            userType={userType}
            displayedFields={displayedFields}
            isFormDirty={isFormDirty}
            validateFilesCallback={validateFilesCallback}
            enableDropzone={!disableFormFields}
            existingFiles={existingFiles}
            useNewFormat={useNewFormat}
            preloadEndpoint={preloadEndpoint}
            requestGuid={requestGuid}
            callback={this.handleCallback}
            axiosRequest={axiosRequest}
            disableFormFields={disableFormFields}
            useCacheUpload={useCacheUpload}
            cacheUploadEndpoint={cacheUploadEndpoint}
            allowEditFileName={allowEditFileName}
            isPublicRequest={isPublicRequest}
          />
        </div>
      </div>
    );
  }
}

InlineFilesFormSection.propTypes = {
  id: PropTypes.string,
  userType: PropTypes.string,
  title: PropTypes.string,
  dropzoneSize: PropTypes.string,
  callback: PropTypes.func,
  useNewFormat: PropTypes.bool,
  preloadEndpoint: PropTypes.string,
  useTags: PropTypes.bool,
  requestGuid: PropTypes.oneOfType([PropTypes.object]),
  axiosRequest: PropTypes.func,
  existingFiles: PropTypes.oneOfType([PropTypes.array]),
  disableFormFields: PropTypes.bool,
  timestamp: PropTypes.number,
  fileRequirements: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string)]),
  requiredFiles: PropTypes.oneOfType([PropTypes.array]),
  requiredFilesChangeTimestamp: PropTypes.number,
  isFormDirty: PropTypes.func,
  validateFilesCallback: PropTypes.func,
  displayedFields: PropTypes.oneOfType([PropTypes.array]),
  contentWrapperStyle: PropTypes.oneOfType([PropTypes.object]),
  wrapperStyle: PropTypes.oneOfType([PropTypes.object]),
  validateFields: PropTypes.bool,
  useCacheUpload: PropTypes.bool,
  cacheUploadEndpoint: PropTypes.string,
  allowEditFileName: PropTypes.bool,
  showBorders: PropTypes.bool,
  useParentCacheErrorSwal: PropTypes.bool,
  isPublicRequest: PropTypes.bool,
  customFileTypeList: PropTypes.oneOfType([PropTypes.array])
};

InlineFilesFormSection.defaultProps = {
  id: '',
  userType: '',
  title: '',
  dropzoneSize: 'small',
  callback: null,
  existingFiles: [], // optional, show existing files in dropzone file list
  disableFormFields: false, // disables dropzone
  timestamp: 0,
  useNewFormat: false, // true = use fileAttachToResourceTemplate; false = use filePreloadTemplate
  preloadEndpoint: '',
  useTags: false, // if the `preloadEndpoint` supports tags
  requestGuid: null,
  axiosRequest: () => {},
  fileRequirements: [], // optional, list of human-readable file requirements
  requiredFiles: [
    /** array of:
     * {
     *   // Required - must be unique & camelCase - each specified file to show in filesRequiredList
     *   backendKey: '',
     *
     *   fileRequired: true, // Optional - if not passed, default is true
     *   fileTypeDisplayText: '', // Optional - if file type heading !== backendKey
     *
     *   // Optional, to override the user's uploaded file name that gets sent in the api request
     *   replaceFileName: false,
     *
     *   // Optional, used with site modal for user input of fileName for required file,
     *   // and if `true`, then `replaceFileName` must also be `true`
     *   useFileNameInput: false
     * }
    */
  ],
  requiredFilesChangeTimestamp: 0,
  isFormDirty: () => {},
  validateFilesCallback: null, // if parent needs to know if the dropped files are valid
  displayedFields: ['selectFileType'],
  contentWrapperStyle: {},
  wrapperStyle: {},
  validateFields: false, // To mark missing required files with validation boxes
  useCacheUpload: false, // To generate s3 upload link to cache bucket (does NOT create/upload file)
  cacheUploadEndpoint: '', // Required if `useCacheUpload` is true
  allowEditFileName: false,
  showBorders: true,
  useParentCacheErrorSwal: false, // Parent comp will handle upload errors
  isPublicRequest: false, // if token is NOT required to make api calls
  customFileTypeList: []
};

export default InlineFilesFormSection;
