import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import * as XLSX from 'xlsx';
import swal from 'sweetalert';
import { Button } from './Button';
import { Spinner } from './Spinner';
import { download } from '../images/_icons';
import { Checkbox } from './Checkbox';
import {
  camelToTitle,
  cellActions,
  isEmpty,
  sortData,
  isEqual,
  columnHideList,
  getDownloadValue
} from './_helpers';
import Pagination from './Pagination';
import DataTableRow from './DataTableRow';
import { DataTableWrap, StyledTable } from '../css/_styledTable';

const NoData = lazy(() => import('./NoData'));
export class DataTable extends React.PureComponent {
  constructor (props) {
    super(props);
    this.mounted = false;
    const {
      perPage,
      pageNum,
      direction,
      initialHighlight
    } = this.props;
    this.state = {
      spinnerLoading: false,
      allItems: [],
      currentItems: [],
      sort: {
        column: null,
        direction: direction || 'asc'
      },
      columns: [],
      hasActions: false,
      hasSelect: false,
      key: '',
      perPage,
      pageNum,
      currentHighlight: initialHighlight || '',
      sortedData: [],
      allSelected: false,
      checkboxState: {},
      highlightColumn: null,
      filterBarHeight: 0
    };
  }

  componentDidMount () {
    this.mounted = true;
    const { passedData } = this.props;
    if (!isEmpty(passedData)) {
      this.setAllItems();
    }
    window.addEventListener('resize', this.getFilterBarHeight);
    this.setColumns();
  }

  componentDidUpdate = async (prevProps, prevState) => {
    const {
      dataIsIncomplete,
      dataGroup,
      filtered,
      hiddenColumns,
      passedData,
      activeHighlight
    } = this.props;
    const dataGroupChange = prevProps.dataGroup !== dataGroup;
    const isInitDataLoad = isEmpty(prevProps.passedData) && !isEmpty(passedData) &&
      !isEqual(passedData, prevProps.passedData);
    if (dataGroupChange || isInitDataLoad) {
      this.setAllItems(dataGroupChange);
    } else {
      if (prevProps.dataIsIncomplete !== dataIsIncomplete) {
        this.updateState({
          allPagedDataLoaded: !dataIsIncomplete
        }, () => this.setAllItems(!dataIsIncomplete));
      }
      const dataChanged = this.dataChanged(prevProps, prevState);
      if (dataChanged) {
        !isEqual(filtered, prevProps.filtered) && this.setAllItems(dataChanged);
        activeHighlight && this.updateState(prev => ({
          allSelected: true,
          // If using `dataIsIncomplete` to load paged data, don't clear `currentHighlight`
          // if user clicked on a row on the first page before the rest of the data loaded
          currentHighlight: !isEmpty(prev.currentHighlight) && prev.allPagedDataLoaded
            ? prev.currentHighlight
            : ''
        }), this.selectAll);
        this.setColumns();
      }
    }
    if (hiddenColumns.length !== (prevProps.hiddenColumns || []).length) {
      this.setColumns();
    }
  }

  componentWillUnmount () {
    this.mounted = false;
    window.removeEventListener('resize', this.getFilterBarHeight);
  }

  dataChanged = (prevProps, prevState) => {
    const {
      dataGroup,
      passedData,
      filtered
    } = this.props;
    if (!isEqual(prevProps.passedData, passedData)) {
      return true;
    }
    const prevData = (prevProps.filtered || []);
    const currentData = (filtered || []);
    return (
      dataGroup !== prevProps.dataGroup ||
      (currentData !== '[]' && !isEqual(prevData, currentData))
    );
  }

  setAllItems = (reset = false) => {
    const { allItems } = this.state;
    const { passedData } = this.props;
    if ((isEmpty(allItems) && passedData?.length > 0) || reset) {
      this.updateState({ allItems: passedData, sortedData: [...passedData] }, this.setColumns);
    } else {
      this.setColumns();
    }
  }

  setColumns = () => {
    const {
      visible,
      hiddenColumns,
      headers,
      dataGroup
    } = this.props;
    if (!this.mounted) { return; }
    const columnOrder = !isEmpty(headers?.columnOrder) ? headers.columnOrder : null;
    if (!isEmpty(visible)) {
      const visibleColumns = !isEmpty(dataGroup) && dataGroup !== 'delegates'
        ? visible.map(column => (column.key !== undefined ? column.key : column))
        : visible;
      this.updateState({
        columns: this.sortColumns(
          columnOrder,
          visibleColumns.filter(key => !columnHideList.includes(key))
        )
      }, this.setActions);
    } else {
      const currentData = this.getCurrentData().length && this.getCurrentData()[0];
      const availableColumns = !isEmpty(hiddenColumns)
        ? Object.keys(currentData).filter(key => !hiddenColumns.includes(key))
        : Object.keys(currentData);
      const resolvedColumns = this.sortColumns(
        columnOrder,
        availableColumns.filter(key => !columnHideList.includes(key))
      );
      this.updateState({
        columns: resolvedColumns
      }, this.setActions);
    }
  }

  sortColumns = (columnOrder, columns) => {
    if (isEmpty(columnOrder) || isEmpty(columns)) {
      return columns;
    }
    return columns.sort((a, b) => columnOrder.indexOf(a) - columnOrder.indexOf(b));
  }

  setActions = () => {
    const {
      allSelected,
      columns,
      sort,
      checkboxState: currentCheckboxState
    } = this.state;
    const { passedData } = this.props;
    const hasActions = columns.some(r => cellActions.includes(r));
    const hasSelect = columns.some(r => r === 'select');
    const checkboxState = {};
    if (hasSelect) {
      passedData && passedData.forEach((data) => {
        checkboxState[data.select] = currentCheckboxState?.[data?.select] || allSelected;
        // retains each checkbox's true state if it already has been selected
      });
    }
    if (!this.mounted) { return; }
    this.updateState(prevState => ({
      ...prevState,
      hasActions,
      hasSelect,
      checkboxState,
      sort: {
        column: columns[0],
        direction: sort.direction
      }
    }), this.setKey);
  }

  setKey = () => {
    const { primaryKey, defaultSortCol } = this.props;
    const { columns, sort } = this.state;
    const { column } = sort;
    if (!this.mounted) { return; }
    this.updateState({
      key: primaryKey !== null ? primaryKey : columns[0]
    }, () => { this.handleSort(defaultSortCol || column || columns[0]); });
  }

  setActiveHighlight = (data) => {
    const { primaryKey, rowCallback } = this.props;
    const { currentHighlight } = this.state;
    if (!isEmpty(data) && primaryKey &&
    currentHighlight !== data[primaryKey]) {
      this.updateState({ currentHighlight: data[primaryKey] });
    }
    rowCallback && rowCallback(data);
  }

  getCurrentData = () => {
    const { passedData, filtered } = this.props;
    return filtered || passedData || [];
  }

  handleSort = (column, toggle, e) => {
    const {
      dataIsIncomplete,
      sortByEmptyFirst: globalSortByFirst,
      sortColumnOverrides
    } = this.props;
    const { sort } = this.state;
    const elem = e ? e.target : null;
    this.updateState({ spinnerLoading: true });
    let sortDir = sort.direction;
    if (toggle) {
      sortDir = sort.direction === 'asc' ? 'desc' : 'asc';
    }
    const currentData = this.getCurrentData();
    // using CSS logic, more specific wins out over global
    const configuredSortByFirst = !isEmpty(sortColumnOverrides[column])
      ? sortColumnOverrides[column][sortDir]?.sortByEmptyFirst || false
      : globalSortByFirst;
    const configuredSortByLast = !isEmpty(sortColumnOverrides[column])
      ? sortColumnOverrides[column][sortDir]?.sortByEmptyLast || false
      : false; // no current global value, use false instead
    const sortedData =
      sortData([...currentData], column, {
        direction: sortDir,
        sortByEmptyFirst: configuredSortByFirst,
        sortByEmptyLast: configuredSortByLast
      });
    this.updateState({
      sort: {
        column: dataIsIncomplete ? null : column,
        direction: sortDir
      },
      sortedData
    }, this.handlePagination);
    this.updateState({ spinnerLoading: false });
    this.highlightColumn(column, elem);
  };

  highlightColumn = (highlightColumn, elem) => {
    this.updateState({ highlightColumn });
    setTimeout(() => {
      this.updateState({ highlightColumn: null });
      if (elem) {
        elem.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest'
        });
      }
    }, 200);
  }

  selectAll = () => {
    const { allSelected, sortedData } = this.state;
    const { actionCallback } = this.props;
    const checkboxState = {};
    sortedData.forEach((data) => { checkboxState[data.select] = !allSelected; });
    this.updateState({
      allSelected: !allSelected,
      checkboxState
    });
    actionCallback && actionCallback('select', !allSelected, !allSelected ? 'SELECT_ALL' : 'UNSELECT_ALL', checkboxState);
  };

  updateState = (state, callback) => {
    this.mounted && this.setState(state, callback);
  }

  handlePagination = () => {
    const {
      pageNum,
      perPage,
      sortedData
    } = this.state;
    if (isEmpty(sortedData)) { return; }
    const indexOfLast = pageNum * perPage;
    const indexOfFirst = indexOfLast - perPage;
    const currentData = sortedData;
    const pageData = currentData && currentData.slice(indexOfFirst, indexOfLast);
    this.updateState(prevState => ({
      isFiltered: (currentData?.length || 0) !== prevState.allItems.length,
      currentItems: pageData
    }), this.getFilterBarHeight);
  }

  getFilterBarHeight = () => {
    const fixedFilterBar = document.querySelector('#filterBar .fixed');
    if (fixedFilterBar) {
      const filterBarHeight = fixedFilterBar.offsetHeight;
      this.updateState({ filterBarHeight });
    }
  }

  handleActionCallback = (type, value, data) => {
    const { checkboxState } = this.state;
    const { actionCallback } = this.props;
    const newCheckboxState = { ...checkboxState, [data]: value };
    if (type === 'select') {
      this.updateState({
        checkboxState: newCheckboxState,
        allSelected: false
      });
    }
    actionCallback && actionCallback(type, value, data, newCheckboxState);
  }

  handleDownload = () => {
    const { allItems, isFiltered, sortedData } = this.state;
    if (isFiltered) {
      swal({
        title: `Download Data`,
        text: 'Please select the data you would like to download.',
        buttons: {
          all: {
            text: 'Download All',
            value: 'all',
            className: 'siteModalSwalClose'
          },
          filtered: {
            text: 'Download Filtered',
            value: 'filtered',
            className: 'siteModalSwalClose'
          }
        },
        className: 'swal-datatable-download-data-modal',
        closeOnClickOutside: true,
        closeOnEsc: true
      }).then((result) => {
        if (result === 'filtered') {
          this.downloadFile(sortedData);
        } else if (result === 'all') {
          this.downloadFile(allItems);
        }
      });
    } else {
      this.downloadFile(sortedData);
    }
  }

  downloadFile = (data) => {
    const {
      alertBar,
      downloadFile,
      modal
    } = this.props;
    const { fileName, sheetName } = downloadFile || {};
    const tempFileName = !isEmpty(fileName) ? fileName : 'Download';
    const newFileName = tempFileName.length > 31 // file name cannot be > 31 characters
      ? fileName.slice(0, 30)
      : tempFileName;
    const tempSheetName = !isEmpty(sheetName) ? sheetName : 'Sheet1';
    const newSheetName = tempSheetName.length > 31 ? tempSheetName.slice(0, 30) : tempSheetName;
    const fileData = this.formatDownloadData(data);
    const worksheet = XLSX.utils.json_to_sheet(fileData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, newSheetName);
    XLSX.writeFile(workbook, `${newFileName}.xlsx`);
    const successMessage = `Success! File has been downloaded.`;
    if (modal) {
      swal({
        text: successMessage,
        icon: 'success',
        className: 'swalSiteModalDownload',
        button: {
          text: 'OK',
          value: true,
          visible: true,
          className: 'siteModalSwalClose',
          closeModal: true
        },
        closeOnClickOutside: true,
        closeOnEsc: true
      });
    } else {
      alertBar('success', successMessage);
    }
  }

  getDownloadColumnTitle = (column) => {
    const { headers, dataGroup, visible } = this.props;
    const { headerKeyMap } = headers || {};
    if (!isEmpty(visible) && !isEmpty(dataGroup)) {
      const headerMatch = visible.find(({ key }) => key === column);
      return !isEmpty(headerMatch) ? headerMatch.englishName : camelToTitle(column);
    }
    if (!isEmpty(headerKeyMap) && column in headerKeyMap) {
      return headerKeyMap[column];
    }
    return camelToTitle(column);
  }

  formatDownloadData = (data) => {
    const { columns } = this.state;
    const visibleColumns = columns.filter(c => !cellActions.includes(c));
    return data.reduce((newDataArray, item) => {
      const newItem = visibleColumns.reduce((dataFormatted, column) => {
        const title = this.getDownloadColumnTitle(column);
        const tableValue = getDownloadValue(item[column]);
        return { ...dataFormatted, [title]: tableValue };
      }, {});
      return newDataArray.concat(newItem);
    }, []);
  }

  paginationResponse = async (response) => {
    const {
      pageNum = null,
      perPage = null
    } = response;
    pageNum && await this.updateState({ pageNum });
    perPage && await this.updateState({ perPage });
    this.handlePagination();
  }

  isHighlight = (dataTableRowElement) => {
    const { primaryKey, activeHighlight } = this.props;
    const { currentHighlight } = this.state;
    return (activeHighlight && primaryKey &&
      currentHighlight) === dataTableRowElement[primaryKey];
  }

  renderTopLevelHeaders = topLevelHeaders => topLevelHeaders.map(topHeader => (
    <th
      className="topHeader"
      key={topHeader.key}
      colSpan={topHeader.colSpan}
    >
      {topHeader.key}
    </th>
  ));

  render () {
    const {
      id,
      className,
      canDownload,
      visible,
      dataGroup,
      headers,
      downloadText,
      dataIsIncomplete,
      rowCallback,
      noPagination,
      topLevelHeaders,
      headerStyle
    } = this.props;
    const {
      perPage,
      pageNum,
      allSelected,
      sort,
      checkboxState,
      currentItems,
      columns,
      key,
      hasActions,
      hasSelect,
      sortedData,
      spinnerLoading,
      highlightColumn,
      filterBarHeight
    } = this.state;
    const visibleColumns = columns.filter(c => !cellActions.includes(c));
    if (sortedData.length) {
      return (
        <DataTableWrap
          id={id}
          className={'dataTable'.concat(className ? ` ${className}` : '')}
          {...filterBarHeight > 0 && {
            style: {
              maxHeight: `calc(100vh - 55px - var(--padding-header) - var(--padding-header) - ${filterBarHeight}px)`
            }
          }}
        >
          <Spinner loading={spinnerLoading} />
          <div
            id="tableWrapper"
            className="table-scroll"
          >
            <StyledTable
              className="dataTable"
            >
              <thead>
                {!isEmpty(topLevelHeaders) && (
                  <tr>
                    {this.renderTopLevelHeaders(topLevelHeaders)}
                  </tr>
                )}
                <tr>
                  { hasActions && (
                    <th
                      className={[
                        'actions',
                        sort.column === 'actions' ? sort.direction : '',
                        hasSelect ? 'hasSelect' : ''
                      ].join(' ')}
                    >
                      {hasSelect && (
                        <Checkbox
                          id="checkbox-select-all"
                          wrapperStyle={{
                            position: 'absolute',
                            left: '0',
                            top: '0',
                            width: '100%',
                            height: '100%'
                          }}
                          height="100%"
                          width="100%"
                          type="mini"
                          callback={this.selectAll}
                          checked={allSelected}
                          disabled={dataIsIncomplete}
                        />
                      )}
                      Actions
                    </th>
                  )}
                  { !isEmpty(visibleColumns) && visibleColumns.map((column, index) => (
                    <th
                      className={[
                        highlightColumn === column ? `${column} highlight` : column,
                        sort.column === column ? sort.direction : ''
                      ].join(' ')}
                      key={`${column}_${index.toString()}`}
                      style={{
                        ...(column.length > 35 && { minWidth: '15em' }),
                        ...headerStyle
                      }}
                      onClick={/* istanbul ignore next */e => this.handleSort(column, true, e)}
                    >
                      {
                        !isEmpty(headers?.headerKeyMap) ? headers.headerKeyMap[column]
                          : camelToTitle(column, { fields: visible, dataGroup })
                      }
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                { !isEmpty(currentItems) && currentItems.map((rowData, i) => (
                  (!isEmpty(rowData[key])) && (
                    <DataTableRow
                      key={`${rowData[key]}_${i.toString()}`}
                      primaryKey={key}
                      rowIndex={`${i.toString()}`}
                      visible={visibleColumns}
                      data={rowData}
                      actionCallback={this.handleActionCallback}
                      {...rowCallback && { rowCallback: this.setActiveHighlight }}
                      highlightActive={this.isHighlight(rowData)}
                      disabled={rowData.disabled}
                      checkboxState={checkboxState}
                      highlightColumn={highlightColumn}
                    />
                  )))}
              </tbody>
            </StyledTable>
          </div>
          <div className="tableFooter">
            { (!noPagination || (noPagination && perPage < sortedData.length)) && (
              <Pagination
                data={sortedData}
                perPage={perPage}
                pageNum={pageNum}
                callback={this.paginationResponse}
              />
            )}
            <span style={{ ...(dataIsIncomplete && { color: 'var(--color-warning)' }), fontSize: '1.2rem' }}>
              {dataIsIncomplete
                ? 'First page complete, data loading. Some table functions may not work as intended.'
                : 'All table data loaded'
              }
            </span>
            { canDownload && (
              <div className="downloadWrapper" style={{ marginLeft: 'auto' }}>
                <Button
                  id="downloadButton"
                  onClick={this.handleDownload}
                  icon={download()}
                  size="sm"
                >
                  {downloadText || 'Download'}
                </Button>
              </div>
            )}
          </div>
        </DataTableWrap>
      );
    }
    return <NoData wrapperStyles={{ padding: '20px' }} detailsText={dataIsIncomplete ? 'Data still loading' : ''} />;
  }
}

DataTable.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  visible: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.object]),
  primaryKey: PropTypes.string,

  // filtered - use if table displays a filtered data set on load
  // then `passedData` is also required & should be ALL the table data
  filtered: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.object]),

  // passedData is ALL the data that gets displayed in the table on mount
  // on change, passedData is any filtered data
  passedData: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.object]),

  // dataGroup is required if the table needs to refresh ALL data (such as on guid change),
  // which indicates that we need to reset allData that was initially set on mount
  dataGroup: PropTypes.string,

  activeHighlight: PropTypes.bool,
  // dataTable needs to be told if it should track with row has been clicked

  perPage: PropTypes.number,
  pageNum: PropTypes.number,
  alertBar: PropTypes.func,
  canDownload: PropTypes.bool,
  headers: PropTypes.oneOfType([PropTypes.object]),
  hiddenColumns: PropTypes.oneOfType([PropTypes.array]),
  downloadFile: PropTypes.oneOfType([PropTypes.object]),
  modal: PropTypes.bool,
  actionCallback: PropTypes.func,
  rowCallback: PropTypes.func,
  downloadText: PropTypes.string,
  defaultSortCol: PropTypes.string,
  direction: PropTypes.string,
  sortByEmptyFirst: PropTypes.bool, // should only be `true` when `direction` prop is desc
  dataIsIncomplete: PropTypes.bool,
  initialHighlight: PropTypes.string, // value of the primary key to highlight
  noPagination: PropTypes.bool,
  topLevelHeaders: PropTypes.oneOfType([PropTypes.array]),
  headerStyle: PropTypes.oneOfType([PropTypes.object]),
  sortColumnOverrides: PropTypes.oneOfType([PropTypes.object])
};

DataTable.defaultProps = {
  id: null,
  className: null,
  visible: null,
  primaryKey: null,
  filtered: null,
  passedData: null,
  dataGroup: 'authorization',
  perPage: 25,
  pageNum: 1,
  alertBar: () => {},
  canDownload: true,
  headers: null,
  hiddenColumns: [],
  downloadFile: {},
  modal: false,
  actionCallback: null,
  rowCallback: null,
  activeHighlight: false,
  downloadText: '',
  defaultSortCol: '',
  direction: '',
  sortByEmptyFirst: false, // should only be `true` when `direction` prop is desc
  dataIsIncomplete: false,
  initialHighlight: '',
  noPagination: false,
  topLevelHeaders: [],
  headerStyle: {},
  // override Object should be formatted:
  // { 'nameOfColToApplySpecialSortRule' : { 'directionOfSortRule' : { 'sortRuleName: value }}}
  // example { date: { asc: { sortByEmptyFirst: true }, desc: { sortByEmptyFirst: { false }}}}
  sortColumnOverrides: {}
};

export default DataTable;
