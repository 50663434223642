import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { DataBox, DataList, NoData } from '../index';
import { getType, isEmpty } from './_helpers';
import { FlexBoxWrap } from '../css/_styledComponents';

export const DataBoxList = (props) => {
  const {
    children,
    className,
    collapsible,
    data,
    hideEmptyValues,
    id,
    inset,
    itemStyle,
    itemTitle,
    minimized,
    sort,
    style,
    title,
    type
  } = props;
  return (
    <DataBox
      role="article"
      aria-label={`${itemTitle || title} databox list`}
      id={id}
      className={className}
      type={type}
      title={title}
      inset={inset}
      collapsible={collapsible}
      minimized={collapsible ? minimized : false}
      style={{ height: 'fit-content', marginTop: '10px', ...style }}
    >
      {children}
      <FlexBoxWrap>
        {!isEmpty(data) ? data.map((item, index) => (
          <div key={uuidv4()} style={{ flex: '1', ...(item.dataListItemStyle || itemStyle) }}>
            <strong style={{ fontSize: '1.2rem' }}>{item.dataListItemTitle || `${itemTitle || ''} ${index + 1}`}</strong>
            <DataList
              data={getType(item) === 'array' || getType(item.data) === 'array'
                ? item
                : Object.entries(item.data || item)}
              hideEmptyValues={hideEmptyValues}
              sort={sort}
            />
          </div>
        )) : <NoData />}
      </FlexBoxWrap>
    </DataBox>
  );
};

DataBoxList.propTypes = {
  children: PropTypes.oneOfType([PropTypes.any]),
  className: PropTypes.string,
  collapsible: PropTypes.bool,
  data: PropTypes.oneOfType([PropTypes.array]),
  hideEmptyValues: PropTypes.bool,
  id: PropTypes.string,
  inset: PropTypes.bool,
  itemStyle: PropTypes.oneOfType([PropTypes.object]),
  itemTitle: PropTypes.string,
  minimized: PropTypes.bool,
  sort: PropTypes.bool,
  style: PropTypes.oneOfType([PropTypes.object]),
  title: PropTypes.string,
  type: PropTypes.string
};

DataBoxList.defaultProps = {
  children: null,
  className: null,
  collapsible: false,
  data: null,
  hideEmptyValues: false,
  id: null,
  inset: false,
  itemStyle: {},
  minimized: false,
  sort: false,
  style: {},
  title: null,
  itemTitle: null,
  type: null
};

export default DataBoxList;
