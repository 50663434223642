import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actionCreators from '../../crm/src/redux/actions/actionCreators';
import { loader } from './_styles';
import '../css/animations.css';

function mapStateToProps (state) {
  return {
    loading: state.toggleLoader.loading
  };
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

export class BoundLoader extends React.PureComponent {
  constructor (props) {
    super(props);
    this.mounted = false;
    this.state = {
    };
  }

  componentDidMount () {
    this.mounted = true;
  }

  componentWillUnmount () {
    this.mounted = false;
  }

  render () {
    const { loading } = this.props;
    return (
      <div id="loader" style={{ ...(!loading && { display: 'none' }), ...loader.wrap, ...(loading && loader.loading) }}>
        <div className="boxLoader" style={loader.loader} />
      </div>
    );
  }
}

BoundLoader.propTypes = {
  loading: PropTypes.bool
};
BoundLoader.defaultProps = {
  loading: false
};

const Loader = connect(mapStateToProps, mapDispatchToProps)(BoundLoader);

export default Loader;
