import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { isEmpty } from '@f1/shared/src/_helpers';
import { NoData, Spinner, ToolTip } from '@f1/shared';
import { close, refresh, settings } from '@f1/shared/images/_icons';
import { Icon, TicketBubble } from '@f1/shared/css/_styledComponents';
import { NotificationBellWrapper } from '../css/_styledComponents';

export class DataBoxNotificationBell extends React.PureComponent {
  constructor (props) {
    super(props);
    this.mounted = false;
  }

  componentDidMount () {
    this.mounted = true;
  }

  componentWillUnmount () {
    this.mounted = false;
  }

  renderNotificationRow = (item) => {
    const bubble = (
      <TicketBubble
        wrapperStyle={{ marginRight: '1em', marginBottom: '0px' }}
        style={{
          backgroundColor: 'var(--color-corvia-gold)',
          ...(item.count === 0 && { backgroundColor: 'var(--color-disabled)' })
        }}
      >
        {item.count}
      </TicketBubble>
    );
    return (
      <Link
        key={item.title}
        aria-label={item.title}
        className={`row ${item.value}Link`}
        to={item.href || '/'}
        style={{ display: 'flex' }}
        {...(!isEmpty(item.state) && { state: item.state })}
        onClick={this.handleCloseCallback}
      >
        <div className="count">{bubble}</div>
        {item.title}
      </Link>
    );
  }

  handleCloseCallback = () => {
    const { closeCallback } = this.props;
    closeCallback && closeCallback({ hideBellTimestamp: Date.now() });
  }

  render () {
    const {
      loading,
      mouseX,
      notificationData,
      refreshCallback
    } = this.props;
    const {
      hasApiError,
      hasActionRequiredSettings,
      hasCrabSettings,
      notifications
    } = notificationData || {};
    return (
      <NotificationBellWrapper id="notificationDetails" mouseX={mouseX}>
        <Spinner loading={loading} />
        <div className="header-wrapper">
          <div className="header">
            <div className="text">User Defined Notifications</div>
            <div className="actions">
              <ToolTip
                inline
                text={(
                  <Icon
                    icon={refresh.src_white}
                    useMask
                    id="refresh-button"
                    onClick={refreshCallback}
                    onKeyDown={refreshCallback}
                    tabIndex="0"
                    role="button"
                    aria-label="Refresh Notifications"
                    style={{ height: '16px', width: '16px', margin: '0' }}
                  />
                )}
              >
                Refresh
              </ToolTip>
              <ToolTip
                inline
                text={(
                  <Link
                    id="manage-button"
                    aria-label="Manage Notifications"
                    to="/account/notifications"
                    onClick={this.handleCloseCallback}
                  >
                    <Icon
                      useHoverStyle
                      icon={settings().src_white}
                      useMask
                      style={{ height: '16px', width: '16px', margin: '0' }}
                    />
                  </Link>
                )}
              >
                Manage
              </ToolTip>
            </div>
          </div>
          <ToolTip
            inline
            wrapperStyle={{
              position: 'absolute',
              top: '0',
              right: '0',
              left: 'unset',
              bottom: 'unset'
            }}
            text={(
              <Icon
                icon={close.src_white}
                useMask
                id="close-button"
                onClick={this.handleCloseCallback}
                onKeyDown={this.handleCloseCallback}
                tabIndex="0"
                role="button"
                aria-label="Close Notifications"
                style={{ height: '20px', width: '20px', margin: '0' }}
              />
            )}
          >
            Close
          </ToolTip>
        </div>
        {hasApiError || isEmpty(notifications)
          ? (
            <NoData
              err={hasApiError}
              customMessage={hasApiError ? 'There was a problem retrieving these notifications, please refresh the page or click the "Refresh" button above to try again.' : null}
              wrapperStyles={{ textAlign: 'left', padding: '0.5em', fontSize: '1.4rem' }}
            />
          )
          : (
            <div className="notifications">
              {hasActionRequiredSettings && (
                <>
                  <div className="header">Action Suggested</div>
                  {!isEmpty(notifications) && notifications
                    .filter(item => item.isActionRequired).map(this.renderNotificationRow)}
                </>
              )}
              {hasCrabSettings && (
                <>
                  <div className="header">Applications</div>
                  {!isEmpty(notifications) && notifications
                    .filter(item => item.isCrab).map(this.renderNotificationRow)}
                </>
              )}
            </div>
          )}
      </NotificationBellWrapper>
    );
  }
}

DataBoxNotificationBell.propTypes = {
  closeCallback: PropTypes.func,
  loading: PropTypes.bool,
  mouseX: PropTypes.number,
  notificationData: PropTypes.shape({
    hasApiError: PropTypes.bool,
    hasActionRequiredSettings: PropTypes.bool,
    hasCrabSettings: PropTypes.bool,
    notifications: PropTypes.oneOfType([PropTypes.array])
  }),
  refreshCallback: PropTypes.func
};

DataBoxNotificationBell.defaultProps = {
  closeCallback: () => {},
  loading: false,
  mouseX: null,
  notificationData: {
    hasApiError: false,
    hasActionRequiredSettings: false,
    hasCrabSettings: false,
    notifications: null
  },
  refreshCallback: () => {}
};

export default DataBoxNotificationBell;
