import * as React from 'react';
import PropTypes from 'prop-types';
import swal from 'sweetalert';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from '@f1/shared/src/routing/withRouter';
import { handleCloseSwal, isPublicUrl, isSidebarOpen } from '@f1/shared/src/_helpers';
import * as actionCreators from '../redux/actions/actionCreators';
import { handleClearLocalDB } from '../utils';

function mapStateToProps (state) {
  return {
    isAuthenticated: state.authenticate.isAuthenticated,
    user: state.authenticate.user
  };
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

export class Polling extends React.Component {
  componentDidMount () {
    const { isAuthenticated } = this.props;
    if (isAuthenticated) {
      this.checkToken();
    }
    !isAuthenticated && handleClearLocalDB();
  }

  componentDidUpdate (prevProps, prevState) {
    const { isAuthenticated, location } = this.props;
    if (prevProps.isAuthenticated !== isAuthenticated && isAuthenticated) {
      // user signs in
      this.checkToken();
    } else if (
      prevProps.location.state?.clearPolling !== location.state?.clearPolling &&
      location.state?.clearPolling
    ) {
      // user clicks sign out
      handleClearLocalDB();
      this.clearPollInterval();
      this.clearSessionTimeout();
    } else if (isAuthenticated) {
      // no cookie but still authenticated in store
      this.checkToken();
    } else if (prevProps.isAuthenticated !== isAuthenticated && !isAuthenticated) {
      // unauthorized user (401 error on handleApiError method)
      this.clearPollInterval();
      this.clearSessionTimeout();
      this.signOut({ clearStore: false }); // store has already been cleared
    }
  }

  componentWillUnmount () {
    this.clearPollInterval();
    this.clearSessionTimeout();
  }

  checkToken = () => {
    const { user } = this.props;
    const { exp = 0 } = user?.accessToken || {};
    const currentTime = Math.ceil(Date.now() / 1000);
    if (currentTime > exp) {
      this.signOut();
      this.clearPollInterval();
      this.clearSessionTimeout();
    } else {
      const fiveTil = exp - (5 * 60);
      if (currentTime < exp && currentTime > fiveTil) {
        this.warningAlert();
      } else if (!this.poll) {
        this.setPollInterval();
      }
    }
  }

  setPollInterval = () => {
    this.poll = setInterval(this.checkToken, (5 * 60 * 1000));
  }

  clearPollInterval = () => clearInterval(this.poll);

  setSessionTimeout = () => {
    const { user } = this.props;
    const { exp = 0 } = user?.accessToken || {};
    const currentTime = Math.ceil(Date.now() / 1000);
    const timeRemaining = (exp - currentTime) * 1000;
    this.sessionTimeout = setTimeout(this.signOut, timeRemaining);
  }

  clearSessionTimeout = () => clearTimeout(this.sessionTimeout);

  warningAlert = () => {
    const { alertBar } = this.props;
    if (!isPublicUrl) {
      isSidebarOpen() ? this.showSwal() : alertBar('notice', 'Your session will expire soon. Save any changes you are working on before you are logged out automatically.');
    }
    if (this.poll) {
      this.clearPollInterval();
    }
    if (this.sessionTimeout) {
      this.clearSessionTimeout();
    }
    this.setSessionTimeout();
  }

  showSwal = () => {
    swal({
      text: 'Your session will expire soon.',
      className: 'swal-corvia-default',
      icon: 'info',
      closeOnClickOutside: false,
      closeOnEsc: false
    });
  }

  signOut = (options) => {
    const { clearStore = true } = options || {};
    const { navigate, resetStore, alertBar } = this.props;
    localStorage.removeItem('applicationId');
    handleClearLocalDB();
    if (clearStore) {
      resetStore();
    }
    if (!isPublicUrl) {
      handleCloseSwal();
      alertBar('notice', 'Your session has expired. Please sign in again.');
      navigate('/signin');
    }
  }

  render () {
    return (
      <div {...this.props} />
    );
  }
}

Polling.propTypes = {
  isAuthenticated: PropTypes.bool,
  user: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  navigate: PropTypes.func,
  alertBar: PropTypes.func,
  resetStore: PropTypes.func,
  location: PropTypes.shape({
    state: PropTypes.shape({
      clearPolling: PropTypes.bool
    })
  })
};

Polling.defaultProps = {
  isAuthenticated: false,
  user: {},
  navigate: () => {},
  alertBar: () => {},
  resetStore: () => {},
  location: {
    state: {
      clearPolling: false
    }
  }
};

const withPolling = Component => withRouter(connect(mapStateToProps, mapDispatchToProps)(
  class extends Polling {
    render () {
      return <Component {...this.props} />;
    }
  }
));

export default withPolling;
