import React from 'react';
import bigDecimal from 'js-big-decimal';
import * as XLSX from 'xlsx';
import {
  isEmpty,
  getDownloadValue,
  getType,
  piiNameList,
  sortData,
  ignoreCase,
  isBool,
  dateStringToIso,
  getBooleanOrRadio,
  getFormattedRelationship,
  formatPhone
} from './_helpers';

export const getFilterBarList = (array, options) => {
  const {
    titleKey = '',
    valueKey = '',
    sort = false
  } = options || {};
  if (isEmpty(array)) return [];
  const newList = array.reduce((acc, arrItem) => {
    const exists = !isEmpty(acc
      .find(item => (item && item.value) === (arrItem && arrItem[valueKey])));
    return exists ? acc : acc.concat({ title: arrItem[titleKey], value: arrItem[valueKey] });
  }, []);
  const allOption = { title: 'All', value: 'all' };
  return [allOption].concat(sort ? sortData(newList, 'title') : newList);
};

// BE - convert FE form value into the expected format for the BE
export const toBackendValue = (frontendValue, formField, options) => {
  const { key = null } = options || {};
  // inputs: FE form value, formField from formHelpers
  // output: expected/formatted value for BE API request
  let newValue = frontendValue;
  if (!isEmpty(newValue) && getType(newValue) === 'string') {
    // always trim any string we send to the BE.
    newValue = newValue.trim();
  }
  if (!isEmpty(newValue) || isBool(newValue)) {
    if (formField.backendFormat) { // Custom method to format for BE value
      const customValue = formField.backendFormat(newValue, formField);
      return customValue;
    }
    if ((formField.componentType === 'dropdown' || formField.componentType === 'combobox') &&
      !formField.isMulti) {
      // If we're using a dropdown, we don't want to trim the value that already
      // exists. This is to cover a case where a combobox field was created
      // with a space at the end by accident but already exists in the DB.
      return newValue;
    }
    if (['text', 'onlyAlpha', 'textarea', 'stateCode', 'url', 'urlRequiredProtocol', 'mid'].includes(formField.type) &&
    (formField.componentType !== 'combobox' && !formField.isMulti)) {
      // if FE displays text field of values separated by commas but BE expects array of strings
      return formField.stringToArray
        ? newValue.split(',').map(val => val.trim())
        : newValue.toString().trim();
    }
    if (formField.type === 'date' && formField.dateOnly) {
      // if BE expects date only (not timestamp), type=date should already be in correct format
      return newValue;
    }
    if (// convert dates to ISO UNLESS approvedDate or closed
      formField.type === 'date' &&
      formField.id !== 'approvedDate' &&
      formField.id !== 'closedDate' &&
      formField.id !== 'dateOpened' &&
      formField.id !== 'guarantorSignedDate' &&
      formField.id !== 'dob'
    ) {
      return dateStringToIso(newValue);
    }
    if (formField.type === 'radio') { // convert radio to boolean
      return getBooleanOrRadio(newValue, { toBool: true });
    }
    if (formField.type === 'tel' || formField.type === 'faxNumber' ||
    (formField.type === 'tel' && formField.telPrefixRequired)) {
      return `+${formatPhone(newValue, { includeCountryCode: true })}`;
    }
    if (formField.type === 'ssn') {
      return newValue.replace(/[-]/g, '');
    }
    if (formField.fieldType === 'checkboxList') {
      if (key === 'employeeGroupId') {
        if (Array.isArray(newValue)) { // when groups are sent on addTicket, they are in []
          return newValue.map(group => ({ [key]: Object.keys(group)[0] }));
        }
        return Object.entries(newValue).map(group => ({ [key]: group[0] }));
        // when groups are sent from editTicket, they are in {}
      }
      if (key === 'employeeGroupIdApproval') {
        return newValue.map(group => ({ employeeGroupId: group.employeeGroupId }));
      }
      if (key) {
        return newValue.map(group => ({ [key]: group[key] }));
      }
      if (Array.isArray(newValue)) {
        return newValue.map(group => Object.keys(group)[0]);
      }
      return !isEmpty(newValue)
        ? Object.keys(newValue) // object of { [key]: true } pairs
        : [];
    }
    if (formField.type === 'price' && formField.priceToNumber) {
      // converts FE price field into a number type
      return parseFloat(parseFloat(newValue).toFixed(2));
    }
    if (formField.type === 'number') {
      return parseInt(newValue || 0, 10);
    }
    const isRatioType = ['ratioSevenPrecision', 'ratioTwoPrecision', 'dollarAndSevenPrecision'].includes(formField.type);
    if (formField.type === 'rationalNumber' || isRatioType) {
      return parseFloat(newValue || 0);
    }
    if ((formField.type || '').includes('percent') && formField.isRatio) {
      const isNumber = `${Number(newValue)}` !== 'NaN';
      const decimalNumber = isNumber ? parseFloat(bigDecimal.divide(`${newValue}`, '100')) : null;
      return decimalNumber;
    }
    // this default case means FE format already matches the format for BE
    return newValue;
  }
  return null;
};

export const isFieldValid = (field) => {
  if (isEmpty(field)) { return false; }
  // by default, all form fields are required, so if the form field passes a required property,
  // it should exist in the formField object as 'false'
  const isOptional = isBool(field.required) && !field.required;
  const isEmptyValue = field.value === undefined || (!isBool(field.value) && isEmpty(field.value));
  if (isOptional && isEmptyValue) {
    // for optional fields that are empty, returning valid as true. this will help with reducing
    // # of checks for whether value is empty, etc. templates should be handling empty values
    return true;
  }
  return isBool(field.valid) ? field.valid : false;
};

export const toFrontendValue = (backendValue, field) => {
  const formFieldProps = {
    ...field,
    ...(field?.componentType && { fieldType: field.componentType })
  };
  // format value if the fieldType is a radio button
  if (isBool(backendValue) && (formFieldProps.fieldType === 'radio')) {
    // convert bool backendValue to yes/no value
    return getBooleanOrRadio(backendValue, { toRadio: true });
  }
  // so that an empty string is set as the input rather than null
  if (isEmpty(backendValue) && formFieldProps.fieldType === 'input' && formFieldProps.type !== 'date') {
    return '';
  }
  if (formFieldProps.type === 'date') {
    const newValue = !isEmpty(backendValue) ? backendValue.substring(0, 10) : '';
    return formFieldProps.isTimestamp ? backendValue || '' : newValue;
  }
  const isNumber = typeof backendValue === 'number';
  if ((formFieldProps.type || '').includes('percent') && formFieldProps.fieldType === 'input') {
    const suffix = formFieldProps.showPercentSuffix ? '%' : '';
    if (formFieldProps.isRatio) {
      const percentString = ratioToPercent(backendValue, { hideSuffix: isEmpty(suffix) });
      return percentString;
    }
    const newValue = isNumber && backendValue <= 1
      ? backendValue * 100
      : backendValue;
    return `${roundPercent(newValue)}${suffix}`;
  }
  if (formFieldProps.fieldType === 'input' && Array.isArray(backendValue)) { // array of strings
    return formFieldProps.stringToArray
    // if BE returns array of strings but FE shows string separated by commas
      ? backendValue.join(', ')
      : backendValue.map(val => (val === null ? '' : val));
  }
  if (formFieldProps.fieldType === 'checkboxList') {
    if (formFieldProps.isCheckboxList) {
      return Array.isArray(backendValue) && !isEmpty(backendValue) && typeof backendValue[0] === 'object'
        ? backendValue
        : {};
    }
    const arrayToReduce = !isEmpty(backendValue) && Array.isArray(backendValue)
      ? backendValue
      : [];
    const newList = !isEmpty(arrayToReduce)
      ? arrayToReduce.reduce((acc, item) => ({ ...acc, [item]: true }), {})
      : [];
    return newList;
  }
  if (Array.isArray(backendValue)) {
    return backendValue.map(val => (val === null ? '' : val));
  }
  // other cases added here as needed
  // default is just the value
  if (!isEmpty(backendValue) && !isNumber && formFieldProps.fieldType === 'input' && formFieldProps.type !== 'textarea') {
    // eslint-disable-next-line no-control-regex
    return `${backendValue || ''}`.replace(/[\r\n\x0B\x0C\u0085\u2028\u2029]+/g, ' ');
  }
  return backendValue;
};

// rounds number to specified decimals, removes any extra ending 0s for integers
export const roundPercent = (v, options) => {
  const { decimalPlaces = 2 } = options || {};
  if (isEmpty(v)) { return ''; }
  const numberVal = typeof v === 'number' ? v : Number(v);
  const stringVal = numberVal.toFixed(decimalPlaces).replace(/[.,]00$/, '');
  return stringVal;
};

export const ratioToPercent = (ratioValue, options) => {
  const { hideSuffix } = options || {};
  if (!isEmpty(ratioValue)) {
    const percentString = bigDecimal.multiply(`${ratioValue}`, '100');
    return `${percentString}${hideSuffix ? '' : '%'}`;
  }
  return null;
};

export const getRelationshipsByRiskProfile = (list, riskProfile) => {
  const ignoreCaseRiskProfile = ignoreCase(riskProfile || '');
  if (ignoreCaseRiskProfile === 'preferred') { return getPreferredRelationships(list); }
  if (ignoreCaseRiskProfile === 'standard') { return getStandardRelationships(list); }
  if (ignoreCaseRiskProfile === 'elevated') { return getElevatedRelationships(list); }
  return list;
};

export const getStandardRelationships = (list) => {
  const standardRelationships = list.filter(r => r.riskProfile && ignoreCase(r.riskProfile) === 'standard');
  return standardRelationships;
};

export const getElevatedRelationships = (list) => {
  const elevatedRelationships = list.filter(r => r.riskProfile && ignoreCase(r.riskProfile) === 'elevated');
  return elevatedRelationships;
};

export const getPreferredRelationships = (list) => {
  const relationships = list.filter(r => r.riskProfile && ignoreCase(r.riskProfile) === 'preferred');
  return relationships;
};

// Adds line breaks to rendered description text
export const formatDescriptionText = (text, options) => {
  const { useSingleLineBreak } = options || {};
  if (!isEmpty(text) && text.includes(`\n`)) {
    const splitText = (text || '').split(`\n`) || [];
    const textOnlyLines = splitText.reduce((acc, line) => (
      (line || '').length > 0 ? acc.concat(line) : acc
    ), []);
    const lastItemIndex = textOnlyLines.length - 1;
    return (
      <>
        {textOnlyLines.map((line, lineIndex) => (
          <span style={{ lineHeight: '1.5' }} key={`${line}-${lineIndex.toString()}`}>
            {line}
            <br />
            {!useSingleLineBreak && lastItemIndex !== lineIndex ? <br /> : null}
          </span>
        ))}
      </>
    );
  }
  return text;
};

// For backend transforms, remove PII fields (eg, when cloning mpa/creating new template)
export const cleanFieldsForBackend = (backendData, options) => {
  const { clearPii = false } = options || {};
  if (isEmpty(backendData)) { return backendData; }
  if (clearPii) {
    const handleDataArray = array => (!isEmpty(array)
      ? array.map(dataItem => (
        cleanFieldsForBackend(dataItem, options)))
      : []);
    if (getType(backendData) === 'object') {
      const cleanData = Object.entries(backendData).reduce((acc, [key, value]) => {
        if (piiNameList.includes(key)) {
          if (getType(value) === 'object' && value !== null) {
            return { ...acc, [key]: {} };
          }
          if (getType(value) === 'array') {
            return { ...acc, [key]: [] };
          }
          return { ...acc, [key]: null };
        }
        if (getType(value) === 'object') { return { ...acc, [key]: cleanFieldsForBackend(value, options) }; }
        if (getType(value) === 'array') { return { ...acc, [key]: handleDataArray(value) }; }
        return { ...acc, [key]: value };
      }, {});
      return cleanData;
    }
    if (getType(backendData) === 'array') {
      const cleanData = handleDataArray(backendData);
      return cleanData;
    }
  }
  return backendData;
};

export const getParsedJson = (data) => {
  try {
    const parsed = JSON.parse(data);
    return parsed;
  } catch (e) {
    return '';
  }
};

export const isPriorityTsysBank = (relationship) => {
  const formattedRelationship = getFormattedRelationship(relationship || {});
  const { bankName, processName } = formattedRelationship || {};
  const isPriority = processName === 'priority';
  const isPriorityTsys = isPriority && ['axiom', 'synovus', 'pueblo_bank_and_trust'].includes(bankName);
  return isPriorityTsys ?? false;
};

export const isPriorityFirstDataBank = (relationship) => {
  const formattedRelationship = getFormattedRelationship(relationship || {});
  const { bankName, processName } = formattedRelationship || {};
  const isPriority = processName === 'priority';
  const isPriorityFirstData = isPriority && ['wells_fargo'].includes(bankName);
  return isPriorityFirstData ?? false;
};

export const formatMid = (mid, options) => {
  // For PORTAL users: Transforms the MID value into a link to the merchant details page
  const { location } = options || {};
  const { disableMidLink } = options || {};
  if (isEmpty(mid) || location !== 'portal' || disableMidLink) return mid;
  return (<a href={`/merchantDetails?mid=${mid}`}>{mid}</a>);
};

/**
 * @param {*} data - Standard table data array
 * @param {*} columns - Array of { title, value }
    title: pretty column name,
    value: data value to map to the column
 * @returns {
    downloaded: Boolean (true: succeeded, false: failed)
    successMessage: String (on success, returns default success message)
    errorDetails: Error (on error, returns the error object)
  }
 */
export const downloadTableData = (data, columns, options) => {
  const { fileName, sheetName } = options || {};
  try {
    const tempFileName = !isEmpty(fileName) ? fileName : `Download`;
    // file name cannot be > 26 characters (including ".xlsx")
    const newFileName = `${tempFileName.length > 26 ? tempFileName.slice(0, 25) : tempFileName}.xlsx`;
    const tempSheetName = !isEmpty(sheetName) ? sheetName : `Sheet1`;
    const newSheetName = tempSheetName.length > 31 ? tempSheetName.slice(0, 30) : tempSheetName;
    const formattedTableData = !isEmpty(data) && !isEmpty(columns)
      ? data.map(item => (
        columns.reduce(
          (colAcc, col) => ({ ...colAcc, [col.title]: getDownloadValue(item[col.value]) }), {}
        )))
      : [];
    const worksheet = XLSX.utils.json_to_sheet(formattedTableData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, newSheetName);
    XLSX.writeFile(workbook, newFileName);
    return { successMessage: `Success! File has been downloaded.`, downloaded: true };
  } catch (err) { return { errorDetails: err, downloaded: false }; }
};

export const formatDataListValue = value => (
  !isEmpty(value) || isBool(value) ? value : '-'
);
