import { formatDateForFEView, getLocaleString } from '../../../_helpers';

const verifiGETTemplate = {
  frontend: (schema, version) => {
    if (version === '1.0') {
      const alerts = schema?.alerts.map(alert => ({
        alertId: alert?.disputeAlertId,
        receivedTime: getLocaleString(alert?.f1ReceivedTimestamp, { includeTime: true }),
        dbaName: alert?.merchant?.dbaName,
        legalName: alert?.merchant?.legalName,
        caseNumber: alert?.case?.caseNumber,
        caseType: alert?.case?.type,
        caseStatus: alert?.case?.status,
        merchantGuid: alert?.merchant?.merchantGuid,
        mid: alert?.merchant?.mid,
        disputeDate: formatDateForFEView(alert?.dispute?.disputeDate),
        disputeAmount: alert?.dispute?.disputeAmount,
        verifiMerchantId: alert?.merchant?.verifiMerchantId
      }));
      const pages = {
        totalRecords: schema.pagingInfo?.totalNumberOfRecords,
        pageIndex: schema.pagingInfo?.pageIndex,
        pageSize: schema.pagingInfo?.pageSize
      };
      return { alerts, pages };
    }
    return schema;
  }
};

export default verifiGETTemplate;
