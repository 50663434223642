import { getMockValue } from '../_mockDataHelpers';

export const mockReportReserveDetailsGet = (body, params, options) => {
  const { guid = 'testGuid' } = options || {};
  const numberProps = { min: -800000, max: 800000 };
  return {
    [guid]: {
      chart: {
        header: {
          label: 'date',
          lines: [],
          biaxial: [],
          currency: true,
          biaxialCurrency: null
        },
        data: [
          { date: 'Feb', reservesAmount: getMockValue(numberProps) },
          { date: 'Mar', reservesAmount: getMockValue(numberProps) },
          { date: 'Apr', reservesAmount: getMockValue(numberProps) },
          { date: 'May', reservesAmount: getMockValue(numberProps) },
          { date: 'Jun', reservesAmount: getMockValue(numberProps) },
          { date: 'Jul', reservesAmount: getMockValue(numberProps) },
          { date: 'Aug', reservesAmount: getMockValue(numberProps) }
        ]
      },
      table: Array.from({ length: 10 }).map(row => (
        {
          date: getMockValue({ type: 'date' }),
          reserveBalance: getMockValue({ type: 'number', min: 10000, max: 9999999 }),
          heldBalance: getMockValue({ type: 'number', min: 10000, max: 9999999 }),
          totalMerchantPosition: getMockValue({ type: 'number', min: 10000, max: 9999999 })
        }
      ))
    }
  };
};

export const mockReportReserveCurrentGet = (body, params, options) => {
  const { guid = 'testGuid' } = options || {};
  return {
    [guid]: {
      reservesTabularData: Array.from({ length: 10 }).map(row => (
        {
          mid: getMockValue({ type: 'mid' }),
          relationshipName: getMockValue({ type: 'string' }),
          reserveBalance: getMockValue({ type: 'number', min: 10000, max: 9999999 }),
          heldBalance: getMockValue({ type: 'number', min: 10000, max: 9999999 }),
          totalMerchantPosition: getMockValue({ type: 'number', min: 10000, max: 9999999 })

        }
      ))
    }
  };
};

export default mockReportReserveDetailsGet;
