import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actionCreators from '../../crm/src/redux/actions/actionCreators';
import { modalCSS } from './_styles';
import { isEmpty } from './_helpers';
import { Icon } from '../css/_styledComponents';
import { icons } from '../images/_icons';

function mapStateToProps (state) {
  return {
    siteModal: state.siteModal,
    modalTitle: state.siteModal.modalTitle,
    showModal: state.siteModal.showModal,
    modalContent: state.siteModal.modalContent,
    options: state.siteModal.options
  };
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

export class BoundModal extends React.Component {
  componentDidMount () {
    window.addEventListener('keyup', event => this.handleKeyUp(event));
  }

  componentDidUpdate () {
    const { showModal } = this.props;
    document.querySelector('body').style.overflow = showModal ? 'hidden' : 'auto';
  }

  componentWillUnmount () {
    window.removeEventListener('keyup', event => this.handleKeyUp(event));
  }

  handleOutsideClick = (e) => {
    const {
      options: {
        closeOnOutsideClick = true
      }
    } = this.props;
    if (e.target.getAttribute('id') === 'siteModal' && closeOnOutsideClick) {
      this.handleClose(e);
    }
  }

  handleKeyUp = (event) => {
    if (event.keyCode === 27) {
      this.handleClose();
    }
  };

  handleClose = () => {
    const { siteModal, options } = this.props;
    document.querySelector('body').style.overflow = 'auto';
    siteModal(false);
    options?.closeModalCallback && options.closeModalCallback();
  }

  render () {
    const {
      modalContent,
      showModal,
      modalTitle,
      options
    } = this.props;
    const classNames = [
      ...(showModal ? ['active'] : []),
      ...(options.size ? [options.size] : [])
    ];
    return (
      <div
        id="siteModal"
        className={classNames.length ? classNames.join(' ') : ''}
        style={{
          ...modalCSS.outerWrap,
          ...(showModal && modalCSS.active)
        }}
        role="menuitem"
        tabIndex="0"
        onMouseDown={this.handleOutsideClick}
        onKeyDown={this.handleOutsideClick}
      >
        <div
          className="innerWrap"
          style={{
            ...modalCSS.innerWrap,
            ...(showModal && modalCSS.active)
          }}
        >
          <div className="modalHeader" style={modalCSS.header}>
            <Icon
              icon={icons.close.src_color}
              useMask
              role="menuitem"
              tabIndex="0"
              className="modalClose"
              aria-label="Close Modal"
              style={modalCSS.close}
              onMouseDown={this.handleClose}
              onKeyDown={this.handleClose}
            />
            <span role="heading" aria-level="2">{modalTitle}</span>
          </div>
          <div className="modalContent" style={modalCSS.content}>
            {!isEmpty(modalContent) && React.cloneElement(modalContent)}
          </div>
        </div>
      </div>
    );
  }
}

BoundModal.propTypes = {
  siteModal: PropTypes.func,
  showModal: PropTypes.bool,
  modalContent: PropTypes.element,
  modalTitle: PropTypes.string,
  options: PropTypes.oneOfType([PropTypes.object])
};

BoundModal.defaultProps = {
  siteModal: () => {},
  showModal: false,
  modalContent: React.createElement('div'),
  modalTitle: '',
  options: {
    size: '',
    closeModalCallback: null,
    closeOnOutsideClick: true
  }
};

const Modal = connect(mapStateToProps, mapDispatchToProps)(BoundModal);
export default Modal;
