import React from 'react';
import PropTypes from 'prop-types';
import swal from 'sweetalert';
import { filesActionBarCSS } from './_styles';
import { Icon } from '../css/_styledComponents';
import { icons } from '../images/_icons';
import { getLocaleString, isEmpty } from './_helpers';

import { EditFileForm } from './boarding/components/EditFileForm';
import { ToolTip } from './ToolTip';

export class FilesActionBar extends React.PureComponent {
  constructor (props) {
    super(props);
    this.mounted = false;
  }

  componentDidMount () {
    this.mounted = true;
  }

  componentWillUnmount () {
    this.mounted = false;
  }

  confirmDelete = (file) => {
    const { deleteFileCallback, noSwal } = this.props;
    const deleteOptions = { file };
    if (deleteFileCallback && noSwal) {
      deleteFileCallback(deleteOptions);
    } else {
      const preText = file?.name ? `You are about to delete this file:\n\n${file?.name}\n\n` : '';
      swal({
        title: `Delete this file?`,
        text: `${preText}Deleting a file is permanent. There is no undo.`,
        closeOnClickOutside: false,
        closeOnEsc: false,
        buttons: ['Go Back!', 'Delete File'],
        className: 'swal-corvia-warning',
        icon: 'warning',
        dangerMode: true
      }).then((result) => {
        if (result) { deleteFileCallback && deleteFileCallback(deleteOptions); }
      });
    }
  }

  handleEditFile = (file) => {
    const {
      userType,
      siteModal,
      hasEditAccess,
      editFileRequestGuid,
      editFileCallback,
      axiosRequest
    } = this.props;
    if (hasEditAccess) {
      const content = (
        <EditFileForm
          userType={userType}
          file={file}
          requestGuid={editFileRequestGuid}
          axiosRequest={axiosRequest}
          callback={editFileCallback}
        />
      );
      siteModal(true, 'Edit File', content);
    }
  }

  render () {
    const {
      id,
      galleryView,
      file,
      wrapperStyle,
      deleteFileCallback,
      hasEditAccess
    } = this.props;
    const canDelete = file.userCanDelete && !isEmpty(deleteFileCallback);
    const hasNoActions = !hasEditAccess && !canDelete && isEmpty(file.uploadTimestamp);
    const actionStyle = galleryView && file.isImage ? { padding: '1px' } : {};
    const { restrictedTag } = file;
    return (
      <div
        id={id}
        className="files-action-bar"
        style={{
          ...(galleryView && file.isImage
            ? { ...filesActionBarCSS.imageActionBar, ...(hasNoActions && { height: '0' }) }
            : { display: 'flex' }),
          alignItems: 'center',
          ...wrapperStyle
        }}
      >
        {hasEditAccess && (
          <Icon
            icon={icons.edit().src_white}
            useMask
            className="editFile"
            filename={file.name}
            onClick={() => this.handleEditFile(file)}
            onKeyDown={() => this.handleEditFile(file)}
            tabIndex="0"
            role="button"
            data-testid={`${file.name}-edit-file-button`}
            style={{ ...filesActionBarCSS.actionIcon, ...actionStyle }}
          />
        )}
        {restrictedTag && (
          <ToolTip
            inline
            text={(
              <Icon
                icon={icons.hazard.src_white}
                color="var(--color-warning)"
                useMask
                style={{
                  height: '16px', width: '16px', margin: '0 5px 0 0'
                }}
              />
            )}
          >
            This file cannot be deleted, it is a required file. Upload a new
            version of the file with the same tags to replace it
          </ToolTip>
        )}
        {canDelete && (
          <Icon
            icon={icons.deleteItem().src_white}
            useMask
            hoverColor="var(--color-warning)"
            className="deleteFile"
            filename={file.name}
            onClick={() => this.confirmDelete(file)}
            onKeyDown={() => this.confirmDelete(file)}
            tabIndex="0"
            role="button"
            data-testid={`${file.name}-delete-file-button`}
            style={{ ...filesActionBarCSS.actionIcon, ...actionStyle }}
          />
        )}
        {!isEmpty(file.uploadTimestamp) && (
          <ToolTip
            infoTip
            infoTipDisplay={{
              ...filesActionBarCSS.actionIcon,
              ...actionStyle,
              display: 'flex',
              position: 'inherit'
            }}
          >
            {`Uploaded on ${getLocaleString(new Date(file.uploadTimestamp))}`}
          </ToolTip>
        )}
      </div>
    );
  }
}

FilesActionBar.propTypes = {
  id: PropTypes.string,
  userType: PropTypes.string,
  galleryView: PropTypes.bool,
  wrapperStyle: PropTypes.oneOfType([PropTypes.object]),
  hasEditAccess: PropTypes.bool,
  file: PropTypes.shape({
    name: PropTypes.string,
    uploadTimestamp: PropTypes.string,
    isImage: PropTypes.bool,
    userCanDelete: PropTypes.bool,
    restrictedTag: PropTypes.bool
  }),
  editFileRequestGuid: PropTypes.oneOfType([PropTypes.object]),
  siteModal: PropTypes.func,
  axiosRequest: PropTypes.func,
  editFileCallback: PropTypes.func,
  deleteFileCallback: PropTypes.func,
  noSwal: PropTypes.bool
};

FilesActionBar.defaultProps = {
  id: '',
  userType: '',
  galleryView: false,
  wrapperStyle: {},
  hasEditAccess: false,
  file: {
    name: '',
    uploadTimestamp: null,
    isImage: false,
    userCanDelete: false,
    restrictedTag: false
  },
  editFileRequestGuid: {},
  siteModal: () => {},
  axiosRequest: () => {},
  editFileCallback: () => {},
  deleteFileCallback: null,
  noSwal: false
};

export default FilesActionBar;
