import { getMockValue, mockPagingInfo, getMockPageData } from '../_mockDataHelpers';
import { getRandomNumber, transformData } from '../../_helpers';
import verifiGETTemplate from './templates/verifiGETTemplate';

const getRandom = (type) => {
  const map = {
    type: ['dispute', 'cancel'],
    status: ['open', 'pending', 'processing', 'cancelled', 'revoked', 'resolved', 'declined', 'reviewing', 'exporting', 'closed'],
    dbaName: ['Merchant with Transaction Report Data', 'Test Enterprises', 'Landscaping Your Hobbit Hole', 'Theoden\'s Rein', 'Baggins Businesses', 'MoneySaversStore', 'Billy Bass Pro Shop', 'Shark Fin Networks', 'PumpKinz', 'Shrek Den Bar'],
    legalName: ['eTi4wSeiPBxLzXM inc.', 'testEntsLLC', 'Samwise Gardeners', 'Rohan Horses LLC', 'Frodo\'s Froyos', 'Money Savers', 'Singing Carp LLC', 'Sharkfinetworks', 'Pumpkin Enterprises', 'Shrek Den']
  };
  return getMockValue({ type: 'list', list: map[type] });
};

const generateAlarms = () => {
  const alarms = [];
  const numOfAlarms = getRandomNumber({ min: 1, max: 35 });
  for (let i = 0; i < numOfAlarms; i += 1) {
    alarms.push(
      {
        disputeAlertId: getMockValue({ type: 'guid' }),
        f1ReceivedTimestamp: getMockValue({ type: 'isoDate' }),
        merchant: {
          merchantGuid: getMockValue({ type: 'guid' }),
          mid: getMockValue({
            type: 'number', stringify: true, min: 111111111111, max: 999999999999
          }),
          dbaName: getRandom('dbaName'),
          legalName: getRandom('legalName'),
          verifiMerchantId: getMockValue({ type: 'guid' })
        },
        case: {
          caseNumber: getMockValue({ type: 'number', stringify: true, max: 10 }),
          type: getRandom('type'),
          status: getRandom('status')
        },
        dispute: {
          disputeDate: getMockValue({ type: 'date' }),
          disputeAmount: getMockValue({ type: 'number', max: 6 })
        }
      }
    );
  }

  return alarms;
};

export const mockVerifiGet = {
  alerts: generateAlarms()
};

export const mockVerifiGetRes = (body, params, options) => {
  const { guid = 'mockGuid' } = options || {};
  const {
    totalNumberOfRecords = mockPagingInfo.totalNumberOfRecords,
    pageIndex = mockPagingInfo.pageIndex,
    pageSize = mockPagingInfo.pageSize
  } = params || {};
  const pageData = getMockPageData({
    allData: mockVerifiGet.alerts,
    pageIndex,
    pageSize,
    optionsCopy: { ...params }
  });
  const mockData = {
    alerts: pageData,
    pagingInfo: {
      totalNumberOfRecords,
      pageIndex,
      pageSize
    }
  };
  return { [guid]: mockData };
};

export const mockVerifiGetFormatted = data => transformData({
  data: { ...data },
  toSchema: 'frontend',
  version: '1.0',
  template: verifiGETTemplate
});

export const mockVerifiGetFormattedUnitTestData = mockVerifiGetFormatted({
  alerts: [{
    disputeAlertId: getMockValue({ type: 'guid' }),
    f1ReceivedTimestamp: getMockValue({ type: 'isoDate' }),
    merchant: {
      merchantGuid: getMockValue({ type: 'guid' }),
      mid: getMockValue({
        type: 'number', stringify: true, min: 111111111111, max: 999999999999
      }),
      dbaName: getRandom('dbaName'),
      legalName: getRandom('legalName'),
      verifiMerchantId: getMockValue({ type: 'guid' })
    },
    case: {
      caseNumber: '10101010',
      type: 'cancel',
      status: 'open'
    },
    dispute: {
      disputeDate: getMockValue({ type: 'date' }),
      disputeAmount: getMockValue({ type: 'number', max: 6 })
    }
  },
  {
    disputeAlertId: getMockValue({ type: 'guid' }),
    f1ReceivedTimestamp: getMockValue({ type: 'isoDate' }),
    merchant: {
      merchantGuid: getMockValue({ type: 'guid' }),
      mid: getMockValue({
        type: 'number', stringify: true, min: 111111111111, max: 999999999999
      }),
      dbaName: getRandom('dbaName'),
      legalName: getRandom('legalName'),
      verifiMerchantId: getMockValue({ type: 'guid' })
    },
    case: {
      caseNumber: getMockValue({ type: 'number', stringify: true, max: 10 }),
      type: getRandom('type'),
      status: 'pending'
    },
    dispute: {
      disputeDate: getMockValue({ type: 'date' }),
      disputeAmount: getMockValue({ type: 'number', max: 6 })
    }
  },
  {
    disputeAlertId: getMockValue({ type: 'guid' }),
    f1ReceivedTimestamp: getMockValue({ type: 'isoDate' }),
    merchant: {
      merchantGuid: getMockValue({ type: 'guid' }),
      mid: getMockValue({
        type: 'number', stringify: true, min: 111111111111, max: 999999999999
      }),
      dbaName: getRandom('dbaName'),
      legalName: getRandom('legalName'),
      verifiMerchantId: getMockValue({ type: 'guid' })
    },
    case: {
      caseNumber: getMockValue({ type: 'number', stringify: true, max: 10 }),
      type: getRandom('type'),
      status: 'closed'
    },
    dispute: {
      disputeDate: getMockValue({ type: 'date' }),
      disputeAmount: getMockValue({ type: 'number', max: 6 })
    }
  }]
});

export const mockVerifiPostRes = (body, params, options) => {
  const { guid = 'mockDisputeAlertId' } = options || {};
  return { [guid]: body };
};

export default mockVerifiGetRes;
