import { v4 as uuidv4 } from 'uuid';
import {
  formatDateForFEView,
  fileTagValues,
  isEmpty,
  isEqual,
  isImage,
  ignoreCase,
  sortData
} from '../../../_helpers';

const filesWithTagsTemplate = {
  frontend: (schema, version) => { // Formats files from GET api call
    if (version === '1.0') {
      const {
        fileNameCustomValidation,
        noSort = false,
        files, // BE response key option
        taggedFilesObjectWithUrl, // BE response key option
        filesList, // BE response key option
        userType,
        disableEdit = false, // employee only
        disableDelete = false,
        // if you want to disable modifications/deleting on only certain tags
        // this feature is specifically related to crab. Warning text reflects that
        // if this feature is expanded beyond crab use, make sure to update
        // instances of restrictedTagList to incorporate the new data structures
        // and FilesActionBar.js where restrictedTag is in use
        restrictedTagList = []
      } = schema || {};
      const filesToMap = taggedFilesObjectWithUrl || filesList || files || [];
      const mappedFiles = filesToMap.map((file, index) => {
        const ignoreCaseTagList = !isEmpty(file?.tags) && Array.isArray(file?.tags)
          ? file.tags.map(fileTag => (fileTagValues
            .find(tagItem => tagItem?.value === ignoreCase(fileTag?.tagObject))
            ? ignoreCase(fileTag?.tagObject)
            : fileTag?.tagObject))
          : [];
        const unsortedTags = ignoreCaseTagList
          .map(tagValue => fileTagValues
            .find(listItem => listItem.value === tagValue) ||
            // If not in standard list, create new entry
            { title: tagValue, value: tagValue, __isNew__: true })
          .filter(tagItem => (!isEmpty(tagItem) && tagItem.visible !== false));
        const sortedTags = sortSelectedTags(unsortedTags, { userType });
        const isTagRestricted = isEmpty(restrictedTagList)
          ? false
          // employees CANNOT delete required files for crab apps that are partner-added
          // allow for employee to delete employee added files, though
          : ignoreCaseTagList.some(aFileTag => restrictedTagList.includes(aFileTag)) && ignoreCaseTagList.includes('uploaded_by_partner');
        const userCanDelete = userType === 'employee'
          ? (ignoreCaseTagList.includes('uploaded_by_partner') || ignoreCaseTagList.includes('uploaded_by_employee'))
          : (ignoreCaseTagList.includes('uploaded_by_partner'));
        const fileNameValid = !isEmpty(fileNameCustomValidation)
          ? fileNameCustomValidation(file?.fileName)
          : !isEmpty(file?.fileName);
        const formattedFile = {
          fileNameValid,
          name: file?.fileName || '-',
          url: file?.url || '',
          originalTags: ignoreCaseTagList,
          uploadTimestamp: file?.fileCreationTimestamp || null,
          uniqueFileId: uuidv4(), // for delete file, in case there are dupe file names
          tagList: sortedTags,
          guidType: file?.guidType,
          guidValue: file?.guidValue,
          displayName: file?.fileName || '-',
          key: index,
          isImage: isImage(file?.fileName || ''),
          uploadedByMe: userType === 'employee'
            // For employee view file list styles
            ? ignoreCaseTagList.includes('uploaded_by_employee')
            : false,
          userCanEdit: userType === 'employee'
            ? (disableEdit !== true && !ignoreCaseTagList.includes('uploaded_by_system')) || false
            : false,
          userCanDelete: ignoreCaseTagList.includes('uploaded_by_system') ? false : (disableDelete !== true && userCanDelete && !isTagRestricted) || false,
          ...(file?.fileOptions && { ...file.fileOptions }),
          restrictedTag: isTagRestricted
        };
        return formattedFile;
      });
      return noSort
        ? mappedFiles
        : sortData(mappedFiles, userType === 'employee' ? 'uploadTimestamp' : 'displayName', { ...(userType === 'employee' && { direction: 'desc' }) });
    }
    return schema || [];
  },
  frontendDownloadPackage: (schema, version) => {
    if (version === '1.0') {
      const { backendData } = schema || {};
      const { taggedFilesObjectWithUrl } = backendData || {};
      const filesToMap = !isEmpty(taggedFilesObjectWithUrl) ? taggedFilesObjectWithUrl : [];
      const formattedFilesMetadata = !isEmpty(filesToMap)
        ? filesToMap.map((backendFile) => {
          const formattedFile = {
            'Guid Type': backendFile?.guidType,
            'Guid Value': backendFile?.guidValue,
            'File Name': backendFile?.fileName,
            'Timestamp Uploaded': formatDateForFEView(backendFile?.fileCreationTimestamp, { includeTime: true }),
            Tags: !isEmpty(backendFile?.tags) ? backendFile?.tags.map(t => t.tagObject).join(', ') : ''
          };
          return formattedFile;
        })
        : [{ 'No application files found': '' }];
      const mappedFilesList = !isEmpty(filesToMap)
        ? filesToMap.map(backendFile => ({ fileName: backendFile?.fileName, url: backendFile.url }))
        : [];
      return {
        sheets: [{ title: 'Files Metadata', data: formattedFilesMetadata }],
        downloadFilesList: mappedFilesList
      };
    }
    return schema;
  },
  backendPost: (schema, version) => { // EDIT file name
    if (version === '1.0') {
      const { existingFileName, newFileName } = schema || {};
      return { existingFileName, newFileName };
    }
    return schema;
  },
  backendDelete: (schema, version) => { // DELETE file
    if (version === '1.0') {
      const { fileNameToDelete } = schema || {};
      return { fileName: fileNameToDelete };
    }
    return schema;
  },
  backendEditTagList: (schema, version) => { // EDIT file tags
    if (version === '1.0') {
      const { fileName, tagList, originalTags } = schema || {};
      const systemTags = !isEmpty(originalTags)
        ? originalTags.filter((t) => {
          const systemTag = fileTagValues
            .find(tagItem => tagItem.value === t &&
              (tagItem.isFixed || tagItem.visible === false)) || {};
          return !isEmpty(systemTag);
        })
        : [];
      const newTags = [
        ...(!isEmpty(systemTags) ? systemTags : []),
        ...(!isEmpty(tagList) ? tagList : [])
      ];
      const finalTags = getTagObjects({ tags: newTags });
      return {
        files: [{
          fileName,
          tags: finalTags
        }]
      };
    }
    return schema;
  },
  backendGetGuid: (schema, version) => { // EDIT file tags
    if (version === '1.0') {
      if (schema.guidType && schema.guidValue) {
        return { [schema.guidType]: schema.guidValue };
      }
    }
    return {};
  }
};

export const sortSelectedTags = (tagList, options) => {
  const { userType, showFixedFirst = false } = options || {};
  const groupedTags = !isEmpty(tagList)
    ? tagList.reduce((acc, tagItem) => {
      const {
        isFixed,
        isInternal,
        __isNew__: isNew,
        value
      } = tagItem || {};
      const formattedTag = { ...tagItem, useTagColors: userType === 'employee' };
      const systemTag = isFixed;
      if (systemTag) {
        // Show "uploaded-by" system tags last
        const systemKey = (`${value}`).startsWith('uploaded_by_') ? 'systemLast' : 'systemFirst';
        return { ...acc, [systemKey]: acc[systemKey].concat(formattedTag) };
      }
      const freeFormTag = !isFixed && !isInternal && isNew;
      if (freeFormTag) { return { ...acc, freeForm: acc.freeForm.concat(formattedTag) }; }
      const internalNonGroupTag = isInternal && !value.includes('employee_group');
      if (internalNonGroupTag) {
        return { ...acc, internalNonGroup: acc.internalNonGroup.concat(formattedTag) };
      }
      const internalGroupTag = isInternal && value.includes('employee_group');
      if (internalGroupTag) {
        return { ...acc, internalGroup: acc.internalGroup.concat(formattedTag) };
      }
      return { ...acc, standard: acc.standard.concat(formattedTag) };
    }, {
      systemFirst: [],
      systemLast: [],
      standard: [],
      freeForm: [],
      internalNonGroup: [],
      internalGroup: []
    })
    : {};
  const sortedTags = !isEmpty(groupedTags)
    ? Object.entries(groupedTags).reduce((acc, [key, value]) => ({ ...acc, [key]: sortData(value, 'title') }), {})
    : {};
  const {
    systemFirst = [],
    systemLast = [],
    standard = [],
    freeForm = [],
    internalNonGroup = [],
    internalGroup = []
  } = sortedTags || {};
  const allSystem = [...(systemFirst ?? []), ...(systemLast ?? [])];
  if (userType === 'partner') {
    return [...standard, ...freeForm, ...internalNonGroup];
  }
  if (showFixedFirst) {
    return [...allSystem, ...internalGroup, ...internalNonGroup, ...standard, ...freeForm];
  }
  return [...standard, ...freeForm, ...internalNonGroup, ...internalGroup, ...allSystem];
};

export const getTagObjects = (options) => {
  const {
    frontendView,
    userType,
    tags,
    addingNewFiles
  } = options || {};
  const allTags = [
    ...(frontendView
      ? [
        /**
         * When adding a new file and we want to instantly update the tags displayed to the
         * user, this will add the necessary tags - internally on FE only - so users can take
         * action on the file (eg, delete file, etc) without having to refresh the GET call.
         */
        ...(userType === 'employee' ? ['uploaded_by_employee'] : []),
        ...(userType === 'partner' ? ['uploaded_by_partner'] : [])
      ]
      : []
    ),
    ...(!isEmpty(tags) ? tags : [])
  ];
  const tagValuesOnly = !isEmpty(allTags)
    ? allTags.map(t => (!isEmpty(t.value) ? t.value : t))
    : [];
  const tagValues = addingNewFiles // If adding new files, NO system tags should be included
    ? tagValuesOnly.filter((tagValue) => {
      const systemTag = fileTagValues.filter(t => t.isFixed)
        .find(tagItem => tagItem.value === ignoreCase(tagValue)) || {};
      return isEmpty(systemTag);
    })
    : tagValuesOnly;
  const tagObjectArray = !isEmpty(tagValues)
    ? [...new Set([...tagValues])].map(t => ({ tagObject: t }))
    : [];
  return tagObjectArray;
};

const hasSomeTagMatches = (allReqFileTagValues, existingFileTagList) => (
  existingFileTagList.some(existingFileTagItem => (
    allReqFileTagValues.some(
      reqFileTag => isEqual(ignoreCase(existingFileTagItem.value), ignoreCase(reqFileTag))
    )
  ))
);

export const hasTagMatch = (existingFile, requiredFile) => {
  // Checks if `existingFile` tagList has tag(s) that match a `requiredFile` tagList
  const { tagList: existingFileTagList = [] } = existingFile || {};
  const { tagList: reqFileTagList = [], backendKey: reqFileBackendKey } = requiredFile || {};
  // merge any required files with multiple `allowedOptions` tags
  const allReqFileTagValues = !isEmpty(requiredFile?.allowedOptions)
    ? requiredFile.allowedOptions.reduce((acc, item) => ([
      ...new Set(acc.concat(item.tagList))
    ]), [])
    : reqFileTagList;
  if (!isEmpty(existingFileTagList) && !isEmpty(allReqFileTagValues)) {
  // If existing file & required file have associated tags, match against those first
    const hasMatchedTags = hasSomeTagMatches(allReqFileTagValues, existingFileTagList);
    return hasMatchedTags;
  }
  if (reqFileBackendKey === 'other') {
    // No tags added when partner adds file as "other"
    const hasNoTags = isEmpty(existingFileTagList); // Excludes "uploaded_by" tags
    const hasOtherTag = !hasNoTags ? existingFileTagList.some(existingFileTagItem => (existingFileTagItem.value || '') === 'other') : false;
    return hasNoTags || hasOtherTag;
  }
  return false;
};

export default filesWithTagsTemplate;
