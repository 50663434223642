import { pageSettingsKeys, globalSettingsKeys } from '@f1/shared/src/_customSettings';

// In portal, go to /designs/custom-settings to see expected JSON structure

// Key/value pairs - the value is the top-level data key for api call
// DO NOT EXPORT THIS. Use `portalPageKeys` instead to get a global mapping.
const pageKeys = { // Page-specific. Add any new page keys here
  dashboardPage: 'dashboardPage',
  actionSuggestedPage: 'actionSuggestedPage',
  merchantDetailsPage: 'merchantDetailsPage',
  merchantsOnHoldPage: 'merchantsOnHoldPage',
  monthlySummaryPage: 'monthlySummaryPage',
  customReportsPage: 'customReportsPage',
  achReportPage: 'achReportPage',
  batchSummaryPage: 'batchSummaryPage',
  applicationStatusPage: 'applicationStatusPage',
  noticesPage: 'noticesPage',
  accountPage: 'accountPage',
  crabApplicationPage: 'crabApplicationPage',
  crabPrevetPage: 'crabPrevetPage',
  faqPage: 'faqPage',
  documentsPage: 'documentsPage',
  ticketsPage: 'ticketsPage',
  trainingPage: 'trainingPage'
};

export const portalPageKeys = { ...globalSettingsKeys, ...pageKeys };

const pageKeyValues = Object.values(pageKeys).sort();

export const portalSettingsStructure = {
  [portalPageKeys.globalPreferences]: { // Set any defaults here
    theme: 'light' // One of ['light', 'dark']
  },
  ...pageKeyValues.reduce((pageKeysAcc, pageKey) => ({
    ...pageKeysAcc,
    [pageKey]: Object.values(pageSettingsKeys).reduce((settingsAcc, settingObj) => ({
      ...settingsAcc,
      [settingObj.name]: settingObj.dataTypeValue
    }), {})
  }), {})
};
