import styled, { css } from 'styled-components';

const sharedInputStyles = css`
  width: 100%;
  display: block;
  ${props => (props.height ? `min-height: ${props.height}px;` : ``)}
  outline: none;
  background-color: var(--color-input-background);
  ${props => (props.boxStyle === 'inside' ? `
    padding: 5px;
    border: 1px solid #000000;
    border-radius: 0;
    ${props.height ? `
      min-height: ${props.hasLabel ? props.height + 24 : props.height}px;
    ` : ``}
    padding-top: ${props.hasLabel ? '24' : '0'}px;
    padding-bottom: 0;
  ` : `
    padding-top: 0;
    padding-bottom: 0;
    border: 1px solid var(--color-hr);
    border-radius: var(--radius-main);
  `)}
  ${props => (props.type === 'price' ? `
    padding-left: 20px;
    padding-right: 10px;
  ` : `
    padding-left: 10px;
    padding-right: 10px;
  `)}
  ${props => (props.hasIcon ? `
    padding-right: 30px;
  ` : ``)}
  + .labelText {
    font-size: 1.2rem;
    white-space: nowrap;
    position: absolute;
    display: block;
    height: 24px;
    line-height: 24px;
    top: -24px;
    transition: all .2s ease-in-out;
    padding-right: 25px;
    ${props => (props.boxStyle === 'animated' ? `
      z-index: 1;
      top: 5px;
      max-width: 0;
      height:2px;
      left: 10px;
      line-height: 2.6em;
      color: var(--color-disabled);
      background-color: ${props.backgroundColor ? props.backgroundColor : 'var(--color-input-background)'};
    ` : ``)}
    ${props => (props.boxStyle === 'inside' ? `
      min-height: 14px;
      color: hsl(var(--color-text-hue), var(--color-text-saturation), calc(var(--color-text-lightness) + 25%));
      line-height: 1;
      font-size: 1.2rem;
      padding: 4px;
      margin-left: 0;
      margin-right: 0;
      margin-top: 0;
      margin-bottom: 0;
      top: 0;
      height: 20px;
      width: 100%;
    ` : ``)}
  }
  ${props => (props.boxStyle === 'animated' ? `
    &:focus + .labelText, &[aria-valid=true] + .labelText, &[aria-invalid=true] + .labelText, &[aria-empty=true] + .labelText {
      max-width: 1000px;
      padding-left: 5px;
      padding-right: 5px;
      line-height: 0em;
      left: 15px;
      top: 0px;
      letter-spacing: 0.3em;
      text-transform: uppercase;
    }
  ` : '')}
  &:focus {
    color: var(--color-status-in-progress);
    border-color: var(--color-status-in-progress);
    + .inputIcon::before {
      background-color: var(--color-status-in-progress);
    }
    + div {
      color: var(--color-status-in-progress);
      + .inputIcon::before {
        background-color: var(--color-status-in-progress);
      }
    }
    + .labelText {
      color: var(--color-status-in-progress);
      + .inputIcon::before {
        background-color: var(--color-status-in-progress);
      }
      + div {
        color: var(--color-status-in-progress);
        + .inputIcon::before {
          background-color: var(--color-status-in-progress);
        }
      }
    }
  }
  &[aria-valid=true] {
    color: hsl(var(--color-healthy-hue), var(--color-healthy-saturation), calc(var(--color-healthy-lightness) + 10%));
    border-color: hsl(var(--color-healthy-hue), var(--color-healthy-saturation), calc(var(--color-healthy-lightness) + 10%));
    background-color: var(--color-healthy-bg);
    + .inputIcon::before {
      background-color: hsl(var(--color-healthy-hue), var(--color-healthy-saturation), calc(var(--color-healthy-lightness) + 10%));
    }
    + div {
      color:hsl(var(--color-healthy-hue), var(--color-healthy-saturation), calc(var(--color-healthy-lightness) + 10%));
      + .inputIcon::before {
        background-color: hsl(var(--color-healthy-hue), var(--color-healthy-saturation), calc(var(--color-healthy-lightness) + 10%));
      }
    }
    + .labelText {
      color: hsl(var(--color-healthy-hue), var(--color-healthy-saturation), calc(var(--color-healthy-lightness) + 10%));
      + .inputIcon::before {
        background-color: hsl(var(--color-healthy-hue), var(--color-healthy-saturation), calc(var(--color-healthy-lightness) + 10%));
      }
      + div {
        color: hsl(var(--color-healthy-hue), var(--color-healthy-saturation), calc(var(--color-healthy-lightness) + 10%));
        + .inputIcon::before {
          background-color: hsl(var(--color-healthy-hue), var(--color-healthy-saturation), calc(var(--color-healthy-lightness) + 10%));
        }
      }
    }
  }
  &[aria-invalid=true] {
    color: var(--color-warning);
    border-color: var(--color-warning);
    background-color: var(--color-warning-bg);
    + .inputIcon::before {
      background-color: var(--color-warning);
    }
    + div {
      color: var(--color-warning);
      + .inputIcon::before {
        background-color: var(--color-warning);
      }
    }
    + .labelText {
      color: var(--color-warning);
      + .inputIcon::before {
        background-color: var(--color-warning);
      }
      + div {
        color: var(--color-warning);
        + .inputIcon::before {
          background-color: var(--color-warning);
        }
      }
    }
  }
  &:focus {
    border-color: var(--color-status-in-progress);
    box-shadow: inset 0px 0px 0px 1px var(--color-status-in-progress);
    z-index: 10;
    + .labelText {
      color: var(--color-status-in-progress);
    }
  }
  &:disabled {
    color: var(--color-light-label);
    border-color: var(--color-light-label);
    background-color: var(--color-disabled);
    + .inputIcon::before {
      background-color: var(--color-light-label);
    }
    + div {
      color: var(--color-light-label);
      + .inputIcon::before {
        background-color: var(--color-light-label);
      }
    }
    + .labelText {
      color: var(--color-light-label);
      + .inputIcon::before {
        background-color: var(--color-light-label);
      }
      + div {
        color: var(--color-light-label);
        + .inputIcon::before {
          background-color: var(--color-light-label);
        }
      }
    }
  }
`;

export const InputWrap = styled.label`
  position: relative;
  display: block;
  ${props => (props.hasLabel && props.boxStyle !== 'animated' ? `
    min-height: ${props.height + 24}px;
    padding-top: ${props.boxStyle === 'inside' ? '0' : '24'}px;
  ` : `
    min-height: ${props.height}px;
  `)};
  ${props => (props.boxStyle === 'inside' ? `
    margin-top: -1px;
    margin-right: -1px;
    margin-bottom: -1px;
    margin-left: -1px;
    position: relative;
    background-color: var(--color-bg);
  ` : ``)}
  + span {
    color: var(--color-status-in-progress);
  }
  input {
    ${sharedInputStyles}
  }
  &:focus-within {
    z-index: 10;
  }
`;

export const Inputprefix = styled.div`
  position: absolute;
  z-index: 0;
  ${props => (props.boxStyle !== 'inside' ? `
    top: 50%;
    transform: translate(0, -50%);
  ` : `
    top: 28px
  `)};
  left: 8px;
  color: #aaaaaa;
  ${props => (props.valid ? `
    color: var(--color-healthy);
  ` : ``)}
  ${props => (props.active ? `
    color: var(--color-status-in-progress);
  ` : ``)}
  ${props => (props.error ? `
    color: var(--color-warning);
  ` : ``)}
  pointer-events: none;
`;

export const InputSuffix = styled.div`
  position: absolute;
  z-index: 0;
  ${props => (props.boxStyle !== 'inside' ? `
    top: 50%;
    transform: translate(0, -50%);
  ` : `
    top: 28px
  `)};
  left: 18px;
  color: #aaaaaa;
  ${props => (props.valid ? `
    color: var(--color-healthy);
  ` : ``)}
  ${props => (props.active ? `
    color: var(--color-status-in-progress);
  ` : ``)}
  ${props => (props.error ? `
    color: var(--color-warning);
  ` : ``)}
  pointer-events: none;
`;

export const RequiredStar = styled.span`
  font-weight: bold;
  color: var(--color-warning);
  padding-right: 3px;
  &::before {
    content: "*";
  }
`;

export const Textarea = styled.textarea`
  ${sharedInputStyles}
  font-size: 1.6rem;
  padding: 10px;
  width: 100%;
  font-weight: 800;
  background-color: var(--color-bg);
  resize: vertical;
  overflow: auto;
  min-height: 64px;
  ${props => (props.boxStyle === 'inside' ? `
    padding-top: 22px;
    + .labelText {
      + .tooltip {
        .hasTip {
          top: 4px !important;
        }
      }
    }
    ${(props.height ? `height: ${props.height + 22}px` : 'height: 100%')};
    + .labelText {
      width: calc(100% - 4px);
      left: 2px;
      top: 2px;
    }
    &[aria-valid=true] + .labelText {
      color: var(--color-healthy);
      border-color: var(--color-healthy);
      background-color: var(--color-healthy-bg);  
    }
    &[aria-invalid=true] + .labelText {
      color: var(--color-warning);
      border-color: var(--color-warning);
      background-color: var(--color-warning-bg);
    }
    &:disabled + .labelText {
      color: var(--color-light-label);
      border-color: var(--color-light-label);
      background-color: var(--color-disabled);
    }
  ` : `
    height: 100%;
  `)}
  ${props => (props.height <= 31 ? `
    font-size: 1.2rem;
  ` : ``)}
  word-wrap: break-word;
`;

export const ErrorBox = styled.div`
  max-height: ${props => (props.error ? '500px' : '0px')};
  width: 100%;
  opacity: 1;
  overflow: hidden;
  font-size: 1.2rem;
  line-height: 1.2rem;
  transition: all 0.2s ease 0s;
  padding: ${props => (props.error ? '4px' : '0px')};
  ${props => (props.boxStyle === 'inside' ? `
    color: white;
    background-color: var(--color-warning);
    h1, h2, h3 {
      color: var(--color-white);
    }
  ` : ``)}
  ${props => (props.boxStyle !== 'inside' ? `
    color: var(--color-warning);
  ` : ``)}
`;

export const TextAreaShadow = styled.div`
  word-wrap: break-word;
  width: 100%;
  visibility: hidden;
  height: 0;
`;

export const AdvancedFilterBar = styled.div`
  background-color: var(--color-advanced-filter-bar);
  color: var(--color-text);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 0.9em;
  gap: 0.75em;
  margin-bottom: 0.5em;
  padding: 0 0.5em 0.5em 0.5em;
  width: 100%;
  border-radius: var(--radius-main);
  padding: 0.5em;
  > * {
    min-width: 150px;
    flex: 1;
  }
  .title {
    color: var(--color-text);
    font-weight: bold;
    font-size: 1.7rem;
    margin-bottom: 10px;
    font-size: 1.5rem;
    position: relative;
    width: fit-content;
    margin-bottom: 0px;
    flex: 100%;
    width: 100%;
  }
`;
