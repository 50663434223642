import { transformData } from '../../_helpers';
import { getMockValue } from '../_mockDataHelpers';
import verifiDetailsGETTemplate from './templates/verifiDetailsGetTemplate';

const getRandom = (type) => {
  const map = {
    type: ['dispute', 'cancel'],
    status: ['open', 'pending', 'processing', 'cancelled', 'revoked', 'resolved', 'declined', 'reviewing', 'exporting', 'closed'],
    dbaName: ['Merchant with Transaction Report Data', 'Test Enterprises', 'Landscaping Your Hobbit Hole', 'Theoden\'s Rein', 'Baggins Businesses', 'MoneySaversStore', 'Billy Bass Pro Shop', 'Shark Fin Networks', 'PumpKinz', 'Shrek Den Bar'],
    legalName: ['eTi4wSeiPBxLzXM inc.', 'testEntsLLC', 'Samwise Gardeners', 'Rohan Horses LLC', 'Frodo\'s Froyos', 'Money Savers', 'Singing Carp LLC', 'Sharkfinetworks', 'Pumpkin Enterprises', 'Shrek Den'],
    paymentDescriptor: ['Test description', 'Another test description']
  };
  return getMockValue({ type: 'list', list: map[type] });
};

export const mockVerifiDetails = {
  alert: {
    merchant: {
      merchantGuid: getMockValue({ type: 'guid' }),
      mid: getMockValue({
        type: 'number', stringify: true, min: 111111111111, max: 999999999999
      }),
      dbaName: getRandom('dbaName'),
      legalName: getRandom('legalName'),
      verifiMerchantId: getMockValue({ type: 'guid' }),
      paymentDescriptor: getRandom('paymentDescriptor'),
      paymentDescriptorContact: ''
    },
    case: {
      caseNumber: getMockValue({ type: 'number', stringify: true, max: 10 }),
      type: getRandom('type'),
      status: getRandom('status'),
      statusCode: '',
      networkTimeStamp: '',
      activity: {
        activityAmount: getMockValue({ type: 'number', max: 2 }),
        activityCurrency: '',
        activityDate: '',
        acquirerReferenceNumber: ''
      }
    },
    dispute: {
      disputeDate: '',
      disputeAmount: getMockValue({ type: 'number', max: 4 }),
      disputeCurrency: 'USD',
      reasonCode: ''
    },
    disputeAlertId: getMockValue({ type: 'guid' }),
    f1ReceivedTimestamp: getMockValue({ type: 'date' }),
    referenceTransaction: {
      referenceTransactionDate: '',
      referenceTransactionAmount: getMockValue({ type: 'number', max: 5 }),
      referenceTransactionCurrency: 'USD',
      authorizationCode: '',
      merchantOrderId: ''
    },
    card: {
      cardId: getMockValue({ type: 'description', length: '2' }),
      cardBrand: '',
      cardNumber: '',
      expirationDate: '',
      firstName: '',
      lastName: ''
    }
  }
};

export const mockVerifiDetailsGetRes = (body, params, options) => {
  const { guid = 'mockGuid' } = options || {};
  return { [guid]: mockVerifiDetails };
};

export const mockVerifiDetailsFormatted = data => transformData({
  data,
  toSchema: 'frontend',
  version: '1.0',
  template: verifiDetailsGETTemplate
});

export default mockVerifiDetails;
