import React from 'react';
import PropTypes from 'prop-types';
import { DataBoxList } from '../css/_styledComponents';
import {
  isEmpty,
  isEqual,
  isBool,
  columnHideList,
  dataTitles,
  getType
} from './_helpers';
import { ToolTip } from './ToolTip';

export class DataList extends React.PureComponent {
  constructor (props) {
    super(props);
    this.stack = React.createRef();
    this.state = {
      dataArray: null,
      rowLength: 0
    };
  }

  componentDidMount () {
    this.mounted = true;
    window.addEventListener('resize', this.rowLength);
    this.setData();
  }

  componentDidUpdate (prevProps) {
    const { data } = this.props;
    if (!isEqual(data, prevProps.data)) {
      this.setData();
    }
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.rowLength);
    this.mounted = false;
  }

  updateState = (state, callback = null) => {
    this.mounted && this.setState(state, callback);
  }

  getRowLength = (parent) => {
    // This returns the number of rows in a dynamic flexbox/grid container
    let leftTracker = 0;
    let length = 0;
    if (parent) {
      const children = parent.childNodes;
      for (let i = 0; i < children.length; i += 1) {
        const { left } = children[i].getBoundingClientRect();
        if (left > leftTracker) {
          leftTracker = left;
          length = i + 1;
        } else {
          break;
        }
      }
    }
    return length;
  };

  rowLength = () => {
    const parent = this.stack.current;
    if (parent) {
      const length = this.getRowLength(parent);
      this.updateState({ rowLength: length });
    }
  };

  setData = () => {
    const {
      data,
      sort,
      hideEmptyValues,
      hiddenColumns
    } = this.props;
    const dataType = getType(data);
    const dataArray = dataType === 'array'
      ? [...data]
      : (dataType === 'object' && Object.entries(data)) ?? [];
    const filteredArray = !isEmpty(dataArray)
      ? dataArray.filter(([key, value]) => {
        const notHiddenColumn = ![
          ...columnHideList,
          ...(!isEmpty(hiddenColumns) ? hiddenColumns : [])
        ].includes(key);
        const notEmpty = hideEmptyValues ? !isEmpty(value) || isBool(value) : true;
        return notHiddenColumn && notEmpty;
      })
      : [];
    const sortedArray = sort ? filteredArray.sort() : filteredArray;
    this.updateState({ dataArray: !isEmpty(sortedArray) ? sortedArray : null }, this.rowLength);
  }

  render () {
    const {
      data,
      sort,
      hideEmptyValues,
      ...rest
    } = this.props;
    const { dataArray, rowLength } = this.state;
    return (
      <DataBoxList {...rest} ref={this.stack} className={`x${rowLength}`}>
        { dataArray && dataArray.map(([key, value, meta], i) => (
          <dl key={`${key}_${i.toString()}`} {...!isEmpty(meta?.rowStyle) ? { style: meta.rowStyle } : ''}>
            <dt {...!isEmpty(meta?.keyStyle) ? { style: meta.keyStyle } : ''}>
              {dataTitles(key)}
              {meta?.tooltip ? <ToolTip infoTip inline>{meta?.tooltip}</ToolTip> : null}
            </dt>
            <dd {...!isEmpty(meta?.valueStyle) ? { style: meta.valueStyle } : ''}>
              {value}
            </dd>
          </dl>
        ))}
      </DataBoxList>
    );
  }
}

DataList.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  fullWidth: PropTypes.bool,
  hiddenColumns: PropTypes.oneOfType([PropTypes.array]),
  hideEmptyValues: PropTypes.bool,
  sort: PropTypes.bool
};

DataList.defaultProps = {
  data: null,
  fullWidth: false,
  hiddenColumns: [],
  hideEmptyValues: false,
  sort: false
};

export default DataList;
