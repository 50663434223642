import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

export const StyledBubble = styled.button`
  color: ${props => (props.fontColor ? props.fontColor : 'var(--color-white)')};
  position: absolute;
  padding: 0;
  border: none;
  top: -2px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  font-size: 1rem;
  line-height: 1.1em;
  min-width: 2em;
  text-align: center;
  border-radius: var(--radius-large);
  cursor: pointer;
  .bubble-tip {
    z-index: -1;
    position: absolute;
    border-right-color: transparent;
    border-left-color: transparent;
    border-bottom-color: transparent;
    border-style: solid;
    top: auto;
    bottom: -2px;
    left: 50%;
    margin-left: -5px;
    border-width: 5px 5px 0;
  };
  ${props => (props.color
    ? `
      background-color: ${props.color};
      .bubble-tip {
        border-top-color: ${props.color};
      }
    ` : `
      background-color: var(--color-primary);
      .bubble-tip {
        border-top-color: var(--color-primary);
      }
    `
  )};
  ${props => (props.onClick
    ? `
      &:hover {
        background-color: ${props.hoverColor || 'hsl(var(--color-secondary-hue), var(--color-secondary-saturation), calc(var(--color-secondary-lightness) + 12%))'};
        .bubble-tip {
          border-top-color: ${props.hoverColor || 'hsl(var(--color-secondary-hue), var(--color-secondary-saturation), calc(var(--color-secondary-lightness) + 12%))'};
        }
      }
    ` : `
    `
  )}
`;

export class Bubble extends React.PureComponent {
  render () {
    const { children, title } = this.props;
    return (
      <StyledBubble {...this.props} aria-label={title}>
        <div className="bubble-text">{children}</div>
        <div className="bubble-tip" />
      </StyledBubble>
    );
  }
}

Bubble.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  title: PropTypes.string
};

Bubble.defaultProps = {
  children: null,
  title: ''
};

export default Bubble;
