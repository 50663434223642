import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { withRouter } from './routing/withRouter';
import {
  Icon,
  TabbedComponentWrap,
  TabbedComponentTabsWrap,
  TabbedComponentContentWrap
} from '../css/_styledComponents';
import {
  isBool,
  isEmpty,
  roundIt,
  ignoreCase,
  toCamelCase,
  isEqual
} from './_helpers';
import TabContent from './TabContent';
import { ComboBox } from './ComboBox';
import { Button } from './Button';
import { icons } from '../images/_icons';

export class TabbedContent extends React.Component {
  constructor (props) {
    super(props);
    this.mounted = false;
    const { tabs } = this.props;
    this.initialTabDropdownList = this.getFormattedTabList();
    this.tabOnLoad = tabs.find(tab => tab.defaultTabOnLoad) || {};
    this.tabOnLoadIndex = tabs.findIndex(tab => tab.defaultTabOnLoad);
    this.state = {
      showTabDropdown: true,
      tabDropdownList: this.initialTabDropdownList,
      anchorPoints: [],
      tabIndex: !isEmpty(this.tabOnLoad) ? this.tabOnLoadIndex : 0,
      isScrolling: null,
      clickScrolling: false,
      showMobileView: false,
      selectedTabValue: !isEmpty(this.tabOnLoad)
        ? this.tabOnLoad.text
        : this.initialTabDropdownList[0]?.value
    };
  }

  componentDidMount () {
    this.mounted = true;
    const { useHref, sidebarNav } = this.props;
    if (useHref) {
      const index = this.getTabIndex({ initialize: true });
      (index || index === 0) && this.updateState({
        tabIndex: index
      });
    }
    if (sidebarNav) {
      window.addEventListener('scroll', this.handleScroll);
      setTimeout(() => this.setAnchors(), 400);
    }
    window.addEventListener('resize', this.setMobileView);
    setTimeout(() => {
      this.setMobileView();
    }, 1000);
  }

  componentDidUpdate (prevProps, prevState) {
    const { useHref, tabs } = this.props;
    if (useHref) {
      const index = this.getTabIndex({ initialize: true });
      if (index > -1 && prevState.tabIndex !== index) {
        (index || index === 0) && this.updateState({ tabIndex: index }, this.setMobileView);
      }
    }
    if (!isEqual(prevProps.tabs, tabs)) {
      const newList = this.getFormattedTabList();
      this.updateState({
        showTabDropdown: false,
        tabDropdownList: newList
      }, () => {
        this.updateState({ showTabDropdown: true }, this.setMobileView);
      });
    }
  }

  componentWillUnmount () {
    this.mounted = false;
    const { sidebarNav } = this.props;
    sidebarNav && window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleResize);
  }

  updateState = (state, callback) => {
    this.mounted && this.setState(state, callback);
  }

  getFormattedTabList = (options) => {
    const { includeComponent } = options || {};
    const { tabs } = this.props;
    return tabs.filter(tab => !isEmpty(tab))
      .map(tab => ({
        ...tab,
        component: includeComponent ? tab.component : null, // exclude only for ComboBox list
        title: tab.text,
        value: this.getTabValue(tab)
      }));
  }

  getTabValue = tab => (tab?.href || tab?.hash || toCamelCase(tab?.text || ''));

  setMobileView = () => {
    const { navigate, useHref } = this.props;
    const root = document.getElementById('root');
    const { width: rootWidth } = root?.getBoundingClientRect() || {};

    // if the page we're on exists within the tabs (it should), then
    // we'll display that in the dropdown
    this.updateState((prevState) => {
      const { selectedTabValue, tabDropdownList } = prevState;
      const newTab = useHref
        ? tabDropdownList.find(tab => tab.href === window.location.pathname) || {}
        : tabDropdownList.find(
          tab => this.getTabValue(tab) === selectedTabValue
        ) || tabDropdownList[0] || {};
      return {
        showMobileView: rootWidth < 400 && tabDropdownList.length > 2,
        selectedTabValue: this.getTabValue(newTab)
      };
    });
    const clearHash = !isEmpty(this.tabOnLoad) && window.location.hash;
    clearHash && navigate(window.location.pathname, { replace: true });
  }

  handleComboBox = (value) => {
    const { useHref, navigate } = this.props;
    const { tabDropdownList } = this.state;
    const tabIndex = this.getTabIndex({ value });
    const newTab = tabDropdownList[tabIndex] || {};
    const newTabValue = this.getTabValue(newTab);
    this.updateState({
      selectedTabValue: newTabValue,
      tabIndex,
      ...(useHref ? { clickScrolling: true } : {})
    }, useHref ? () => navigate(value) : () => this.setCurrentTab({ newTabValue }));
  }

  setCurrentTab = (options) => {
    const { newTabValue, newTabIndex } = options || {};
    const tabIndex = !isEmpty(newTabIndex) ? newTabIndex : this.getTabIndex({ value: newTabValue });
    const currentTabs = this.getFormattedTabList({ includeComponent: true });
    const tabMatch = (!isEmpty(newTabIndex)
      ? currentTabs[tabIndex]
      : currentTabs.find(tab => tab.value === newTabValue)
    ) || {};
    this.handleClick(null, tabIndex, tabMatch);
  }

  handleClick = (e, newIndex, tab) => {
    e && e.preventDefault();
    const { callback } = this.props;
    this.updateState((prevState) => {
      const { tabDropdownList } = prevState;
      const newTab = tabDropdownList[newIndex] || tabDropdownList[0] || {};
      const newTabValue = this.getTabValue(newTab);
      return {
        tabIndex: newIndex,
        selectedTabValue: newTabValue
      };
    });
    if (callback) {
      callback(e, newIndex, tab);
    }
  }

  handleNavClick = (e, index, hash, tab) => {
    const {
      callback,
      navigate
    } = this.props;
    if (!isEmpty(hash)) {
      const { pathname } = window.location;
      navigate(`${pathname}${hash}`);
      this.updateState((prevState) => {
        const { tabDropdownList } = prevState;
        const newTab = tabDropdownList[index] || {};
        const newTabValue = this.getTabValue(newTab);
        return {
          tabIndex: index,
          selectedTabValue: newTabValue,
          clickScrolling: true
        };
      });
    } else {
      this.updateState({
        tabIndex: index
      });
    }
    if (callback) {
      callback(e, index, tab);
    }
  }

  disableOnHash = (e, hash) => {
    if (!isEmpty(hash)) e.preventDefault();
  }

  setAnchors = () => {
    const { tabs } = this.props;
    const anchorPoints = [];
    tabs.forEach((tab, i) => {
      let elem;
      if (tab.hash) {
        elem = document.querySelector(`${tab.hash}`);
      }
      if (elem) {
        const rect = elem.getBoundingClientRect();
        anchorPoints.push({
          id: elem.id,
          top: roundIt(rect.top + window.pageYOffset, { precision: 0 })
        });
      }
    });
    this.updateState({ anchorPoints });
  }

  handleScroll = () => {
    const {
      isScrolling, clickScrolling, anchorPoints, tabIndex
    } = this.state;
    window.clearTimeout(isScrolling);
    this.updateState({ // track that window is scrolling
      isScrolling: setTimeout(() => {
        this.updateState({ clickScrolling: false });
      }, 40)
    });
    if (isEmpty(anchorPoints) || anchorPoints[anchorPoints.length - 1].top === 0) {
      // should not be 0, need to reload these
      this.setAnchors();
    } else if (!clickScrolling) {
      anchorPoints.forEach((tab, i) => {
        const topOffest = window.scrollY + 150;
        if (
          tabIndex !== i &&
          topOffest > tab.top &&
          (anchorPoints[i + 1] ? topOffest < anchorPoints[i + 1].top : true)
        ) {
          this.updateState({
            tabIndex: i
          });
        }
      });
    }
  }

  getTabIndex = (options) => {
    const { value, initialize } = options || {};
    const { useHref } = this.props;
    const { tabDropdownList } = this.state;
    return initialize && useHref
      ? tabDropdownList.findIndex(tab => window.location.pathname === tab.href ||
        (!isEmpty(window.location.hash) && window.location.hash === tab.href))
      : tabDropdownList.findIndex(tab => tab.value === value);
  }

  stripHtml = elem => (typeof elem === 'object' ? elem.props.children[1] : elem)

  handleViewPrevTab = () => {
    const { tabIndex } = this.state;
    const prevTaxIndex = tabIndex - 1;
    prevTaxIndex >= 0 && this.setCurrentTab({ newTabIndex: prevTaxIndex });
  }

  handleViewNextTab = () => {
    const { tabDropdownList, tabIndex } = this.state;
    const nextTaxIndex = tabIndex + 1;
    nextTaxIndex <= tabDropdownList.length && this.setCurrentTab({
      newTabIndex: nextTaxIndex
    });
  }

  render () {
    const {
      tabIndex,
      showMobileView,
      showTabDropdown,
      tabDropdownList,
      selectedTabValue
    } = this.state;
    const {
      id,
      tabs,
      useHref,
      sidebar,
      sidebarNav,
      styles,
      sidebarStyles,
      smallTabs,
      tabContentStyles,
      sticky,
      align,
      ariaLabel,
      fill,
      keepParams,
      child,
      className,
      submitButtonChild,
      validateTabs,
      useIcons,
      loadAllTabs
    } = this.props;
    const sideMode = sidebar || sidebarNav;
    const isHashed = useHref && !isEmpty(tabs) && tabs[0]?.hash;
    const tabMatch = isHashed
      ? tabs.find(({ href }) => href === window.location.pathname ||
        (!isEmpty(window.location.hash) && window.location.hash === href))
      : tabs[tabIndex];
    return (
      <TabbedComponentWrap
        role="article"
        aria-label={ariaLabel}
        className={[
          'tabSection',
          ...(!isEmpty(className) ? [className] : [])
        ].join(' ')}
        sideMode={!showMobileView && sideMode}
        smallTabs={smallTabs}
        style={styles}
        id={id}
      >
        <TabbedComponentTabsWrap
          sideMode={!showMobileView && sideMode}
          sticky={sticky}
          align={align}
          fill={fill}
          tabIndex={tabIndex}
          className={[
            'TabbedContent',
            sideMode ? ['tabbedSideContent'] : [],
            ...(validateTabs ? ['validate'] : [])
          ].join(' ')}
          style={{
            ...(sideMode && sidebarStyles?.wrapper)
          }}
        >
          <nav>
            <ul style={{ display: showMobileView ? 'block' : 'flex' }}>
              { !showMobileView ? tabs.filter(tab => !isEmpty(tab)).map((tab, i) => (
                <li
                  tabIndex={i}
                  className={[
                    `tabLink${i}`,
                    'tabMenuItem',
                    ...(tabIndex === i ? ['isActiveTab'] : []),
                    ...(useIcons && !isEmpty(tab.icon) ? ['withIcon'] : []),
                    ...(validateTabs && isBool(tab.isValid) && tab.inProgress
                      ? [tab.isValid ? 'valid' : 'invalid']
                      : []
                    )
                  ].join(' ')}
                  style={{
                    ...(sideMode && sidebarStyles?.link),
                    ...(smallTabs && { fontSize: '1.1rem', lineHeight: '1.1rem', padding: '7px 5px 5px' })
                  }}
                  key={this.stripHtml(tab.text).toLowerCase().replace(/ /g, '_')}
                  {...(!useHref
                    ? {
                      role: 'menuitem',
                      'aria-label': tab.text,
                      onClick: e => this.handleClick(e, i, tab),
                      onKeyDown: () => {}
                    }
                    : {
                      onClick: e => this.handleNavClick(e, i, tab.hash, tab),
                      onKeyDown: () => {}
                    }
                  )}
                  title={this.stripHtml(tab.text)}
                >
                  { useHref ? (
                    <NavLink
                      onClick={e => this.disableOnHash(e, tab.hash)}
                      to={{
                        pathname: `${tab.href}`,
                        search: keepParams ? `${window.location.search}` || '' : ''
                      }}
                      style={{
                        ...(sideMode && sidebarStyles?.link)
                      }}
                    >
                      <div
                        className={`${this.stripHtml(tab.text).toLowerCase().replace(/ /g, '_')} linkText${useIcons ? ' tabWithIconWrapper' : ''}`}
                      >
                        {useIcons && !isEmpty(tab.icon) && (
                          <Icon
                            icon={tab.icon.src_color}
                            color={tabIndex === i ? null : `var(--color-primary-triadic1)`}
                            useMask
                          />
                        )}
                        {!isEmpty(tab.mobileText) && (
                          <span className="tab-title mobile" style={{ display: 'none' }}>
                            {tab.mobileText}
                          </span>
                        )}
                        <span className={`tab-title ${!isEmpty(tab.mobileText) ? 'full' : 'mobileHide'}`}>
                          {tab.text}
                        </span>
                      </div>
                    </NavLink>
                  ) : (
                    <div className={`${useIcons ? ' tabWithIconWrapper' : ''}${useIcons && isEmpty(tab.mobileText) ? ' mobileIconOnly' : ''}`}>
                      {useIcons && !isEmpty(tab.icon) && (
                        <Icon
                          icon={tab.icon.src_color}
                          color={tabIndex === i ? null : `var(--color-primary-triadic1)`}
                          useMask
                        />
                      )}
                      {!isEmpty(tab.mobileText) && (
                        <span className="tab-title mobile" style={{ display: 'none' }} aria-label={tab.mobileText}>
                          {tab.mobileText}
                        </span>
                      )}
                      <span className={`tab-title ${!isEmpty(tab.mobileText) ? 'full' : 'mobileHide'}`} aria-label={tab.text}>
                        {tab.text}
                      </span>
                    </div>
                  )}
                </li>
              )) : (
                <div
                  className="tabLink0"
                  style={{
                    backgroundColor: 'var(--color-bg-alt)',
                    padding: '0.75em',
                    borderRadius: 'var(--radius-large) var(--radius-large) 0 0',
                    maxWidth: '40vw',
                    minWidth: '180px',
                    ...(sticky && {
                      position: 'fixed',
                      borderRadius: '0 0 var(--radius-large) var(--radius-large)',
                      top: 'calc(49px + var(--padding-header) + var(--padding-header))',
                      zIndex: 10
                    })
                  }}
                >
                  {showTabDropdown ? (
                    <ComboBox
                      list={tabDropdownList}
                      callback={this.handleComboBox}
                      id="tabs"
                      label="Switch View To:"
                      selected={selectedTabValue}
                      wrapperStyle={{
                        minHeight: '30px',
                        minWidth: '150px'
                      }}
                    />
                  ) : null}
                </div>
              )}
            </ul>
          </nav>
        </TabbedComponentTabsWrap>

        <TabbedComponentContentWrap
          className={[
            `tabbedContent content`,
            ...(sticky ? ['sticky'] : []),
            ...(validateTabs ? ['validate'] : [])
          ].join(' ')}
          sideMode={sideMode}
          sticky={sticky}
          child={child}
          fill={fill}
          style={{
            ...(sideMode && sidebarStyles?.wrapper?.width && {
              paddingLeft: sidebarStyles.wrapper.width
            })
          }}
        >
          {isHashed && (
            !isEmpty(tabMatch) && isEmpty(window.location.hash)
              ? tabMatch.component
              : tabs[0].component
          )}
          {(!isHashed && !isEmpty(tabs)) && !loadAllTabs && (
            <TabContent
              {...!isEmpty(tabs[tabIndex]?.text) && {
                id: ignoreCase(tabs[tabIndex]?.text).replace(/ /g, '-'),
                title: tabs[tabIndex]?.text
              }}
              customSectionStyle={tabContentStyles?.section}
              customStyle={tabContentStyles?.wrapper}
            >
              {tabs[tabIndex]?.component}
            </TabContent>
          )}
          {(!isHashed && !isEmpty(tabs)) && loadAllTabs && tabs.filter(
            tab => !isEmpty(tab)
          ).map((tab, index) => (
            <TabContent
              {...!isEmpty(tab.text) && {
                id: ignoreCase(tab.text).replace(/ /g, '-'),
                key: ignoreCase(tab.text).replace(/ /g, '-'),
                title: tab.text
              }}
              customStyle={{
                ...index === tabIndex ? {} : {
                  position: 'absolute', left: '-9999px', width: '0', height: '0', maxHeight: '0', overflow: 'hidden'
                },
                ...tabContentStyles?.wrapper
              }}
              customSectionStyle={tabContentStyles?.section}
            >
              {tab.component}
            </TabContent>
          ))}
          {validateTabs && (tabs.length > 1 || !isEmpty(submitButtonChild)) && (
            <div className="navigate-tabs-bar">
              {tabIndex !== 0 && (
                <div style={{ flex: '1' }}>
                  <Button
                    className="previous-tab-button"
                    type="text"
                    onClick={this.handleViewPrevTab}
                    icon={icons.arrowLeft}
                    disabled={tabIndex === 0}
                    style={{ float: 'left' }}
                  >
                    Previous
                  </Button>
                </div>
              )}
              {tabIndex === tabs.length - 1 && !isEmpty(submitButtonChild)
                ? submitButtonChild
                : (
                  <div style={{ flex: '1' }}>
                    <Button
                      className="next-tab-button"
                      icon={icons.arrowRight}
                      type="text"
                      onClick={this.handleViewNextTab}
                      disabled={tabIndex === tabs.length - 1}
                      style={{ float: 'right' }}
                      iconPos="right"
                    >
                      Next
                    </Button>
                  </div>
                )}
            </div>
          )}
        </TabbedComponentContentWrap>
      </TabbedComponentWrap>
    );
  }
}

TabbedContent.propTypes = {
  tabs: PropTypes.oneOfType([PropTypes.array]),
  useHref: PropTypes.bool,
  callback: PropTypes.func,
  sidebar: PropTypes.bool,
  sidebarNav: PropTypes.bool,
  smallTabs: PropTypes.bool,
  styles: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  sidebarStyles: PropTypes.shape({
    wrapper: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    link: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
  }),
  tabContentStyles: PropTypes.shape({
    wrapper: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    section: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
  }),
  id: PropTypes.string,
  ariaLabel: PropTypes.string,
  className: PropTypes.string,
  child: PropTypes.bool,
  sticky: PropTypes.bool,
  keepParams: PropTypes.bool,
  align: PropTypes.string,
  fill: PropTypes.string,
  navigate: PropTypes.func,
  useIcons: PropTypes.bool,
  validateTabs: PropTypes.bool,
  submitButtonChild: PropTypes.oneOfType([PropTypes.node]),
  loadAllTabs: PropTypes.bool
};

TabbedContent.defaultProps = {
  tabs: [],
  useHref: false,
  callback: () => {},
  sidebar: false,
  sidebarNav: false,
  smallTabs: false,
  styles: {},
  sidebarStyles: {
    wrapper: {},
    link: {}
  },
  tabContentStyles: {
    wrapper: {},
    section: {}
  },
  id: '',
  ariaLabel: null,
  className: null,
  child: false,
  sticky: false,
  keepParams: false,
  align: 'center',
  fill: 'var(--color-bg-alt)',
  navigate: () => {},
  useIcons: false, // if true, EVERY tab MUST have an icon
  validateTabs: false,
  submitButtonChild: null, // if using validateTabs, pass the submit button to appear on last page
  loadAllTabs: false
};

export default withRouter(TabbedContent);
