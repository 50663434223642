import axios from 'axios';
import { gzip } from 'pako';
import {
  getType,
  toUpperCase,
  envIsProd,
  envIsStageOnly,
  isValidUrl,
  isEmpty,
  useStubData,
  includeXsrfHeaders,
  includeWithCredentials,
  getErrorStatusCode,
  getParams,
  errorHandler,
  endpoint
} from './_helpers';
import rawVersionHeader from './version.txt';
import { getParsedJson } from './_templateHelpers';

const getVersionHeader = isProd => (isProd
  ? fetch(rawVersionHeader)
    .then(r => r.text())
    .then(t => (!isEmpty(t) ? { CORVIA_FE_VERSION: t } : {}))
    .catch(() => ({}))
  : {});

// The requestGuid header can contain multiple guid types, so first check each value
const requestGuidsAreEmpty = requestGuid => Object.values(requestGuid).every(
  (prop) => {
    // guids can be sent in an array. Ensure each element in the array is populated
    if (Array.isArray(prop)) {
      return prop.every(subProp => isEmpty(subProp) || subProp === 'null');
    }
    // Weirdly, and empty object can be a valid requestGuid value, so accept that
    if (JSON.stringify(prop) === JSON.stringify({})) {
      return false;
    }
    // Otherwise, just check if the guid value is defined
    return isEmpty(prop) || prop === 'null';
  }
);

const defaultSuccessStatusMap = {
  /**
   * Handle different success statuses based on
   * what we want as the default for the specific endpoint
   */
  [endpoint.crab.v1.application.tasks.websiteReview]: 200
};

export const apiCall = async (options, utils, requestBody) => {
  const {
    useRealApi = false,
    url,
    method = 'get',
    tokenRequired = true,
    requestGuid = null,
    config = {}
    // errorOptions = {} // optional -  options for handling error
  } = options || {};
  const {
    // isTokenValid, - required - for `canProceedWithRequest`
    // handleApiError, - required - for `getErrorResult`
    createCsrfHeader,
    fullPageLoader = false
  } = utils || {};
  const state = getState(options, utils);
  const isProd = envIsProd();
  const versionHeader = await getVersionHeader(isProd || envIsStageOnly());
  const csrfHeader = {
    ...(tokenRequired && {
      ...createCsrfHeader()
    })
  };
  const requestHeaders = {
    ...(tokenRequired && {
      ...csrfHeader,
      ...(requestGuid !== null && {
        RequestGuid: !isProd && csrfHeader['x-csrf-token'] === 'mockFrontendCsrfToken' // Must use this for all mock data csrfToken values
          // For FTs only, we need to send the unformatted requestGuid string
          ? JSON.stringify(requestGuid)
          : base64EncodeHeader(JSON.stringify(requestGuid))
      })
    }),
    ...versionHeader
  };
  const reqConfig = {
    ...(tokenRequired && {
      ...includeXsrfHeaders,
      ...includeWithCredentials
    }),
    ...(!isEmpty(requestHeaders) && { headers: requestHeaders }),
    ...config
  };
  fullPageLoader && fullPageLoader(true);
  if (!useStubData || (useStubData && useRealApi)) {
    // in any non-local-dev environment, the real axios call will be made
    // CAUTION - any updates here will impact ALL of our real API calls
    const validateRequest = canProceedWithRequest({ ...options, state }, utils);
    return validateRequest === true ? axiosCall({
      method,
      url,
      reqConfig
    }, requestBody)
      .then((res) => {
        fullPageLoader && fullPageLoader(false);
        logRequest('info', {
          method,
          url,
          requestGuid,
          config,
          requestBody,
          response: res
        });
        return {
          data: !isEmpty(res.data) ? res.data : null,
          state: { ...state.success, ...(res?.status && { status: res.status }) }
        };
      })
      .catch((err) => {
        const errorOpts = {
          ...options,
          state,
          ...(requestGuid !== null && {
            requestHeaders,
            uncompressedRequestGuid: JSON.stringify(requestGuid)
          })
        };
        const errorResult = getErrorResult(err, errorOpts, utils);
        logRequest('error', {
          method,
          url,
          requestGuid,
          config,
          requestBody,
          response: err
        });
        return errorResult;
      })
      : validateRequest;
  }
  // else, handle mock data for local development
  return mockTimeoutAction({ ...options, body: requestBody }, utils, state).then(res => res);
};

const mockTimeoutAction = (options, utils, state) => new Promise((resolve) => {
  handleMockTimeout(options, utils, state, resolve);
});

const getGuidValue = (guid) => { // get mock data guid value
  if (guid === null) return null;
  const guidValType = Object.entries(guid)?.[0]?.[1];
  if (Array.isArray(guidValType)) {
    const numKeys = Object.entries(guid).length; // number of key/value pairs
    return numKeys > 1 // multiple keys, eg. { list1: ['111'], list2: ['222'] }
      ? JSON.stringify(Object.values(guid)) // eg. "[["111"],["222"]]"
      : JSON.stringify(guidValType);
  }
  return JSON.stringify(guid) === '{}'
    ? `"{}"`
    : Object.values(guid)?.[0];
};

export const getMockStubDataMapData = (options) => {
  const {
    url,
    queryStringParams = null,
    config = {},
    body = {},
    method = 'get',
    requestGuid = null,
    stubData = {}
  } = options || {};
  const { email = null } = isSignIn(url) ? body : {};
  const paramsToConvert = !isEmpty(queryStringParams) ? queryStringParams : config.params || {};
  const uriParams = getParams({ getApiParams: true, apiParams: paramsToConvert });
  const hasMockData = stubData?.[method.toUpperCase()];
  const guid = getGuidValue(requestGuid) || email;
  const mockData = (hasMockData instanceof Function)
    // Any updates to `hasMockData` arguments also require corresponding changes to
    // shared `getMockDataFromMap` so the same options are passed for FTs
    ? hasMockData(body, uriParams, {
      guid,
      ...(!isEmpty(requestGuid) && { guidKey: Object.keys(requestGuid)?.[0] })
    })
    : hasMockData;
  const dataKey = mockData?.[guid] ? guid : `"{}"`;
  const data = guid ? (mockData?.[dataKey] || null) : (mockData || null);
  return data;
};

export const handleMockTimeout = (options, utils, state, resolve) => {
  const {
    url,
    queryStringParams = null,
    config = {},
    body = {},
    method = 'get',
    requestGuid = null,
    errorOptions = {}
  } = options || {};
  const {
    handleApiError,
    fullPageLoader = false
  } = utils || {};
  setTimeout(() => {
    const { email = null } = isSignIn(url) ? body : {};
    const paramsToConvert = !isEmpty(queryStringParams) ? queryStringParams : config.params || {};
    const uriParams = getParams({ getApiParams: true, apiParams: paramsToConvert });
    const guid = getGuidValue(requestGuid) || email;
    const data = getMockStubDataMapData(options);
    const mockError = {
      response: {
        status: 404 // change this to test specific error codes locally
      }
    };
    const response = data !== null
      ? {
        state: { ...state.success },
        data,
        mockError: false,
        errorDetails: null
      }
      : {
        state: { ...state.error, ...mockError.response },
        data: null,
        mockError: true,
        errorDetails: new Error(errorHandler(mockError)/* mock error, options not needed here */)
      };
    if (useStubData && data === null) {
      /* eslint-disable-next-line no-console */
      console.info(`%c [LOCAL DEV] MISSING MOCK DATA\nGUID: ${guid}\nMETHOD: ${method}\nENDPOINT: ${(url || '').split('.com').pop()}`, 'color: tomato; background-color: black;');
    }
    if (response.mockError) {
      handleApiError(mockError, {
        disableAllAlerts: !fullPageLoader,
        ...errorOptions
      });
    }
    logRequest(response.mockError ? 'error' : 'info', {
      method,
      url,
      uriParams,
      requestGuid,
      config,
      requestBody: body,
      response: data
    });
    fullPageLoader && fullPageLoader(false);
    resolve(response);
  }, 1000);
};

const isSignIn = (url) => {
  const { pathname = '' } = isValidUrl(url, { requireProtocol: true }) ? new URL(url) : {};
  return pathname.includes('/signIn');
};

const canProceedWithRequest = (options, utils) => {
  const {
    tokenRequired = true,
    requestGuid = null,
    errorOptions = {},
    state
  } = options || {};
  const { isTokenValid, handleApiError, fullPageLoader = false } = utils || {};
  const hasValidToken = isTokenValid instanceof Function && isTokenValid();
  if (tokenRequired && !hasValidToken) {
    const unauthorizedError = { response: { status: 401, message: 'Unauthorized request - missing required token', ...(!isEmpty(errorOptions?.originalRequestError) && { originalRequestError: errorOptions?.originalRequestError }) } };
    handleApiError(unauthorizedError, {
      disableAllAlerts: !fullPageLoader,
      ...errorOptions,
      isErrorLogger: errorOptions?.isErrorLogger || false,
      missingRequiredToken: true
    });
    fullPageLoader && fullPageLoader(false);
    return {
      data: null,
      state: { ...state.error, status: unauthorizedError.response.status },
      errorDetails: new Error()
    };
  }
  // If the requestGuid field is passed in but has no guids in it, return an error
  if (!isEmpty(requestGuid) && requestGuidsAreEmpty(requestGuid)) {
    fullPageLoader && fullPageLoader(false);
    const missingGuidError = new Error('Unable to find resource ID. Please try again.');
    missingGuidError.response = { status: 422, data: null };
    return {
      data: null,
      state: {
        status: 422,
        spinnerLoading: false,
        err: true
      },
      errorDetails: missingGuidError
    };
  }
  return true; // can proceed with api request
};

const getState = (options, utils) => {
  const { url } = options || {};
  const { fullPageLoader } = utils || {};
  return {
    error: {
      ...(!fullPageLoader && {
        spinnerLoading: false,
        err: true
      })
    },
    success: {
      ...(!fullPageLoader && {
        spinnerLoading: false,
        err: false,
        status: !isEmpty(defaultSuccessStatusMap[url]) ? defaultSuccessStatusMap[url] : 201
      })
    }
  };
};

const base64EncodeHeader = (header) => {
  const headerString = getType(header) === 'string' ? header : JSON.stringify(header);
  const utf8Data = decodeURIComponent(encodeURIComponent(headerString));
  const gzipData = gzip(utf8Data);
  const base64Encoded = Buffer.from(gzipData, 'utf8').toString('base64');
  return base64Encoded;
};

const axiosCall = (options, reqBody = {}) => {
  const {
    method,
    url,
    reqConfig = {}
  } = options;
  const reqMethod = method.toLowerCase();
  switch (reqMethod) {
    case 'put':
    case 'post':
    case 'patch':
      return axios[reqMethod](url, reqBody, reqConfig);
    case 'delete':
      return axios[reqMethod](url, {
        ...(!isEmpty(reqBody) && { data: reqBody }),
        ...reqConfig
      });
    case 'get':
    default:
      return axios[reqMethod](url, reqConfig);
  }
};

export const fetchCall = async (options, utils, requestBody) => {
  const {
    useRealApi = false,
    url,
    instanceMethod = 'blob', // required, one of [arrayBuffer, blob, clone, formData, json, text]
    reqConfig = {}, // optional - pass options (eg, headers) into ie: `fetch(url, options)`
    method // `get` or `post`
  } = options || {};
  const {
    // handleApiError, // required - for `getErrorResult`
    // createCsrfHeader, // required - for `isMockRequest`
    fullPageLoader
  } = utils || {};
  const useMockData = isMockRequest(utils);
  const state = getState(options, utils);
  if ((!useStubData || (useStubData && useRealApi)) && !useMockData) {
    // CAUTION - any updates here will impact ALL of our real API calls
    const validateRequest = canProceedWithRequest(options, utils);
    if (validateRequest === true) {
      try {
        const {
          headers,
          mode,
          credentials
        } = reqConfig || {};
        const reqMethod = toUpperCase(!isEmpty(method) ? `${method}` : 'get');
        const fetchOptions = {
          method: reqMethod,
          ...(!isEmpty(headers) && { headers }),
          ...(!isEmpty(mode) && { mode }),
          ...(!isEmpty(credentials) && { credentials })
        };
        fullPageLoader && fullPageLoader(true);
        const fetchRes = await fetch(url, {
          ...fetchOptions,
          ...(!isEmpty(requestBody) && { body: requestBody })
        }).then(async (res) => {
          fullPageLoader && fullPageLoader(false);
          const { status } = res || {};
          const fetchData = await res[instanceMethod]();
          return {
            data: fetchData,
            state: { ...state.success, status },
            errorDetails: null
          };
        }).catch(err => getErrorResult(err, { ...options, state }, utils));
        return fetchRes;
      } catch (err) {
        return getErrorResult(err, { ...options, state }, utils);
      }
    }
    return validateRequest;
  }
  // use mock requests
  return mockTimeoutAction({ ...options, body: requestBody }, utils, state).then(res => res);
};

const isMockRequest = (utils) => {
  const { createCsrfHeader } = utils || {};
  const isProd = envIsProd();
  const csrfHeader = createCsrfHeader ? { ...createCsrfHeader() } : {};
  return !isProd && csrfHeader['x-csrf-token'] === 'mockFrontendCsrfToken'; // Must use this for all mock data csrfToken values;
};

const getErrorResult = (err, options, utils) => {
  const {
    uncompressedRequestGuid,
    requestHeaders,
    state,
    errorOptions
  } = options || {};
  const { fullPageLoader, handleApiError } = utils || {};
  const { RequestGuid } = requestHeaders || {};
  const requestGuidBlob = !isEmpty(RequestGuid) ? new Blob([JSON.stringify(RequestGuid)]) : {};
  const guidDetails = [
    ...(!isEmpty(uncompressedRequestGuid) ? [`REQUEST GUID UNCOMPRESSED: ${uncompressedRequestGuid}`] : []),
    ...(!isEmpty(RequestGuid) ? [`REQUEST GUID COMPRESSED: ${JSON.stringify(RequestGuid)}`] : []),
    ...(!isEmpty(requestGuidBlob) ? [`REQUEST GUID COMPRESSED SIZE: ${requestGuidBlob.size}`] : [])
  ];
  const status = getErrorStatusCode(err);
  handleApiError(err, {
    disableAllAlerts: !fullPageLoader,
    requestGuidDetails: !isEmpty(guidDetails) ? guidDetails.join('- ') : null,
    ...errorOptions
  });
  fullPageLoader && fullPageLoader(false);
  return {
    data: null,
    state: { ...state.error, status },
    errorDetails: err
  };
};

const logRequest = (type, options) => {
  /**
   * To enable logs in non-prod env, open the console and run:
   * localStorage.setItem('showLogs', 'true')
   */
  const {
    method,
    url,
    requestGuid,
    config,
    requestBody,
    response,
    uriParams // Mock data only
  } = options || {};
  const isProd = envIsProd();
  const enableLogging = !isProd
    ? (typeof window !== 'undefined' && localStorage && (localStorage.getItem('showLogs') === 'true'))
    : false;
  const logType = type === 'error' ? 'error' : 'info';
  const methodColorMap = {
    GET: 'orange',
    POST: 'chartreuse',
    PUT: 'gold',
    DELETE: 'cyan'
  };
  const parsedRequestBody = enableLogging && !isEmpty(requestBody) && (
    // add any other specific key checks here
    getType(requestBody.userSettingsPayload) === 'string'
  )
  // to log stringified request body to json format
    ? getParsedJson(requestBody.userSettingsPayload)
    : null;
  // eslint-disable-next-line no-console
  enableLogging && console[logType](`%c ${type === 'error' ? 'ERROR' : 'SUCCESS'}: ${(method || '').toUpperCase()} ${(url || '').split('.com').pop()}`, `color: ${type === 'error' ? 'tomato' : methodColorMap[(method || '').toUpperCase()] || 'white'}; background-color: black;`, {
    METHOD: method,
    ENDPOINT: url,
    ...(!isEmpty(uriParams) && { PARAMS: uriParams }),
    ...(!isEmpty(config) && { CONFIG: config }),
    ...(!isEmpty(requestGuid) && { REQUEST_GUID: requestGuid }),
    ...(!isEmpty(requestBody) && {
      REQUEST_BODY: requestBody,
      ...(!isEmpty(parsedRequestBody) && { REQUEST_BODY_PARSED: parsedRequestBody })
    }),
    ...(!isEmpty(response) && { RESPONSE_DATA: response })
  });
};
