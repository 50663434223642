import { ignoreCase, monthNames, stateCodes } from './_helpers';
import { mccList } from './_mccCodesList';

const comboBoxProps = {
  fieldType: 'combobox',
  displaySearch: true,
  useBlockForm: true,
  formField: true
};

export const sharedFormFields = {
  ein: {
    id: 'ein',
    label: 'EIN',
    type: 'ein',
    fieldType: 'input'
  },
  mccCode: {
    ...comboBoxProps,
    id: 'mccCode',
    label: 'MCC Code',
    type: 'text',
    validationType: 'mcc',
    fieldType: 'combobox',
    required: true,
    editable: true,
    list: mccList
  },
  monthName: {
    ...comboBoxProps,
    id: 'monthName',
    label: 'Month',
    type: 'text',
    fieldType: 'combobox',
    required: true,
    list: monthNames.filter(m => m !== 'N/A').map(m => ({ title: m, value: ignoreCase(m) }))
  },
  state: {
    id: 'state',
    label: 'State',
    type: 'text',
    fieldType: 'combobox',
    required: true,
    formField: true,
    displaySearch: true,
    list: stateCodes.map(code => ({ title: code, value: code }))
  },
  countryDropdown: {
    ...comboBoxProps,
    id: 'country',
    label: 'Country',
    type: 'text',
    fieldType: 'combobox',
    required: true,
    formField: true,
    list: [
      { title: 'US', value: 'US' },
      { title: 'CA', value: 'CA' },
      { title: 'MX', value: 'MX' }
    ]
  },
  zip: {
    id: 'zip',
    label: 'Zip Code',
    type: 'zipcode',
    fieldType: 'input',
    required: true
  }
};

export const lessonPlanFormFields = {
  audience: {
    id: 'audience',
    label: 'Audience',
    type: 'text',
    list: [
      { title: 'Internal', value: 'internal' },
      { title: 'External', value: 'external' },
      { title: 'All', value: 'COMMON' }
    ],
    fieldType: 'dropdown'
  },
  group: {
    id: 'group',
    label: 'Group',
    type: 'text',
    list: [
      // list is defined within the component
    ],
    fieldType: 'dropdown'
  },
  title: {
    id: 'title',
    label: 'Title',
    type: 'text',
    fieldType: 'input',
    required: true
  },
  description: {
    id: 'description',
    label: 'Description',
    type: 'textarea',
    fieldType: 'input',
    required: true
  }
};
export const ticketStatusList = [
  { title: 'Open', value: 'open', isOpen: true },
  { title: 'In Progress', value: 'in_progress', isOpen: true },
  { title: 'Completed', value: 'completed', isOpen: false },
  { title: 'Will Not Complete', value: 'will_not_complete', isOpen: false },
  { title: 'Duplicate', value: 'duplicate', isOpen: false },
  { title: 'Approved', value: 'approved', isOpen: false },
  { title: 'Denied', value: 'denied', isOpen: false },
  { title: 'Snoozed', value: 'snoozed', isOpen: false },
  { title: 'Waiting On Partner', value: 'waiting_on_partner', isOpen: true },
  { title: 'Waiting On Bank', value: 'waiting_on_bank', isOpen: true }
];

export const ticketRequestDropdownFields = (options) => {
  const {
    userType // one of ['employee', 'partner', 'merchant']
  } = options || {};
  const isEmployee = userType === 'employee';
  const isPartner = userType === 'partner';
  return [
    { title: 'DDA/Bank Account Change', value: 'ddaOrBankAccountChange', type: 'merchant' },
    { title: 'Price Change/Add Card Type', value: 'priceChangeOrAddCardType', type: 'merchant' },
    { title: 'Equipment Order/Replacement/Gateway Change', value: 'equipmentOrderOrReplacementOrGatewayChange', type: 'merchant' },
    { title: 'Merchant Closure', value: 'merchantClosure', type: 'merchant' },
    { title: 'Statement Research Inquiry', value: 'statementResearchInquiry', type: 'merchant' },
    { title: 'Batch Funding', value: 'batchFunding', type: 'merchant' },
    { title: 'VAR Sheet/Terminal File Build', value: 'varSheetOrTerminalFileBuild', type: 'merchant' },
    { title: 'Refund Request', value: 'refundRequest', type: 'merchant' },
    { title: 'Merchant Online Portal (Elevate)', value: 'merchantOnlinePortalElevate', type: 'merchant' },
    { title: 'Address Change/Email Update', value: 'addressChangeOrEmailUpdate', type: 'merchant' },
    { title: 'Add or Remove Beneficial Owner', value: 'addOrRemoveBeneficialOwner', type: 'merchant' },
    { title: 'MCC Change Request', value: 'mccChangeRequest', type: 'merchant' },
    { title: 'Account or Fund Hold', value: 'accountOrFundHold', type: 'merchant' },
    { title: 'Pin Debit Enablement', value: 'pinDebitEnablement', type: 'merchant' },
    { title: 'Unable to Process/System Down', value: 'unableToProcessOrSystemDown', type: 'merchant' },
    { title: 'Daily Discount Request', value: 'dailyDiscountRequest', type: 'merchant' },
    { title: 'Gateway Password Reset', value: 'gatewayPasswordReset', type: 'merchant' },
    { title: 'Monthly Discount Request', value: 'monthlyDiscountRequest', type: 'merchant' },
    { title: 'PCI Inquiry', value: 'pciInquiry', type: 'merchant' },
    { title: 'Individual/Combined Funding', value: 'individualOrCombinedFunding', type: 'merchant' },
    { title: 'Next Day Funding Request', value: 'nextDayFundingRequest', type: 'merchant' },
    { title: 'Reserve Inquiry', value: 'reserveInquiry', type: 'merchant' },
    { title: 'AMV Increase Request', value: 'amvIncreaseRequest', type: 'merchant' },
    { title: '1099K Merchant', value: 'merchant1099K', type: 'merchant' },
    ...(isEmployee ? [ // Keep for internal purposes, portal users should not see these as options
      { title: 'General Inquiry', value: 'generalInquiry' }
    ] : []),
    ...(isEmployee || isPartner ? [
      {
        title: 'ACH Update', value: 'achUpdate', relationshipOnly: true, type: 'partner'
      },
      {
        title: '1099 NEC Request', value: 'necRequest1099', relationshipOnly: true, type: 'partner'
      },
      {
        title: 'Tax ID Update', value: 'taxIdUpdate', relationshipOnly: true, type: 'partner'
      },
      {
        title: 'Partner Training Request', value: 'partnerTrainingRequest', relationshipOnly: true, type: 'partner'
      },
      {
        title: 'Residual Questions', value: 'residualQuestions', relationshipOnly: true, type: 'partner'
      },
      {
        title: 'Add Internal Downline to Partner', value: 'addInternalDownlineToPartner', relationshipOnly: true, type: 'partner'
      },
      {
        title: 'Inactivate Relationship ID', value: 'inactivateRelationshipId', relationshipOnly: true, type: 'partner'
      },
      {
        title: 'Activate Relationship ID', value: 'activateRelationshipId', relationshipOnly: true, type: 'partner'
      },
      {
        title: 'Add Iris Integration',
        value: 'iris',
        noRelationshipOrMerchant: true, // no merchant/relationship dropdown
        requiresPartnerData: true, // include partner-level info
        type: 'partner'
      }
    ] : [])
  ];
};

export default sharedFormFields;
