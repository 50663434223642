import {
  boxshadow,
  borderRadius
} from '@f1/shared/src/_globals';

export const infoTableCSS = {
  wrapper: {
    display: 'flex',
    justifyContent: 'space-evenly',
    flexWrap: 'wrap',
    lineHeight: '2',
    fontSize: '1.4rem',
    width: '100%'
  },
  tableWrapper: {
    flex: '1',
    width: '100%',
    height: '100%',
    padding: '10px',
    border: '2px solid transparent',
    ...borderRadius(),
    ...boxshadow('0px', '0px', '3px', '0px', 'rgba(0, 0, 0, 0.1)')
  },
  table: {
    borderCollapse: 'collapse',
    width: '100%'
  },
  tdTitle: {
    textTransform: 'uppercase',
    fontSize: '1rem',
    color: 'var(--color-light-label)',
    lineHeight: '2.25rem'
  },
  tdValue: {
    wordBreak: 'break-word'
  }
};

export const relationshipCard = {
  wrap: {
    fontSize: '1.4rem',
    lineHeight: '1.7'
  },
  title: {
    color: '#cccccc'
  }
};

export const ticketStatusCss = {
  titleWrapper: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  title: {
    color: 'var(--color-text)',
    fontWeight: 'bold',
    fontSize: '1.7rem',
    marginBottom: '10px'
  },
  wrapper: {
    display: 'flex',
    alignItems: 'stretch',
    flexWrap: 'wrap',
    lineHeight: '2',
    fontSize: '1.4rem',
    width: '100%'
  },
  dataSubtitleWrapper: {
    display: 'flex',
    flexFlow: 'column wrap',
    justifyContent: 'center',
    padding: '10px',
    fontWeight: 'bold',
    ...borderRadius()
  },
  tdTitle: {
    textAlign: 'left',
    fontWeight: 'bold'
  },
  dataTableWrapper: {
    flex: '1',
    height: '100%',
    ...borderRadius()
  },
  dataTableItem: {
    backgroundColor: 'var(--color-databox-bg-alternate)',
    padding: '6px',
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: 'repeat(auto-fit, minmax(145px, 1fr))',
    columnGap: '0.25em',
    margin: '0 0 10px',
    width: 'inherit',
    ...borderRadius()
  },
  tdStatus: {
    // minWidth: '200px',
    position: 'relative',
    textTransform: 'capitalize',
    backgroundColor: 'var(--color-bg)',
    padding: '0px 35px 0 5px',
    margin: '2px 7px',
    textAlign: 'center',
    height: '42px',
    lineHeight: '42px',
    ...borderRadius()
  },
  tdNumber: {
    fontWeight: 'bold',
    position: 'absolute',
    right: '-12px',
    top: '-2px',
    width: '46px',
    height: '46px',
    float: 'right',
    border: '2px solid var(--color-white)',
    color: 'var(--color-white)',
    borderRadius: '50%',
    ...boxshadow('0px', '0px', '3px', '0px', 'rgba(0, 0, 0, 0.1)')
  }
};

export const ticketListCss = {
  titleWrapper: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  title: {
    color: 'var(--color-text)',
    fontWeight: 'bold',
    fontSize: '1.7rem',
    marginBottom: '10px'
  },
  colors: {
    open: 'var(--color-status-open)',
    in_progress: 'var(--color-status-in-progress)',
    'in progress': 'var(--color-status-in-progress)',
    completed: 'var(--color-status-completed)',
    will_not_complete: 'var(--color-status-will-not-complete)',
    'will not complete': 'var(--color-status-will-not-complete)',
    waiting_on_partner: 'var(--color-status-waiting-on-partner)',
    'waiting on partner': 'var(--color-status-waiting-on-partner)',
    waiting_on_bank: 'var(--color-status-waiting-on-bank)',
    'waiting on bank': 'var(--color-status-waiting-on-bank)',
    duplicate: 'var(--color-status-duplicate)',
    severity_5: 'var(--color-priority-very-low)',
    low: 'var(--color-priority-very-low)',
    severity_4: 'var(--color-priority-low)',
    severity_3: 'var(--color-priority-medium)',
    medium: 'var(--color-priority-medium)',
    severity_2: 'var(--color-priority-high)',
    high: 'var(--color-priority-high)',
    severity_1: 'var(--color-priority-very-high)',
    unprioritized: 'var(--color-priority-unprioritized)',
    yellow: 'var(--color-stoplight-yellow)',
    orange: 'var(--color-stoplight-orange)',
    red: 'var(--color-stoplight-red)',
    green: 'var(--color-stoplight-green)',
    grey: 'var(--color-status-closed)'
  }
};

export const ticketFiltersCss = {
  ticketFilterBar: {
    backgroundColor: 'var(--color-filter-bar)',
    color: 'var(--color-text)',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    fontSize: '0.9em',
    gap: '0.75em',
    marginBottom: '0.5em',
    padding: '0 0.5em 0.5em 0.5em',
    width: '100%',
    ...borderRadius()
  },
  ticketFilterDropdownsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  ticketFilterDropdowns: {
    width: 'unset',
    minWidth: '220px',
    flexGrow: '1',
    marginRight: '0.25em'
  },
  ticketFilterBubblesWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    lineHeight: '1.5em'
  },
  removeCustomFilter: {
    position: 'absolute',
    top: '-0.25em',
    right: '-0.25em',
    borderRadius: '50%',
    visibility: 'hidden'
  },
  ticketFilter: {
    backgroundColor: 'var(--color-whiteish)',
    borderRadius: '1em',
    color: '#7f807e',
    fontSize: '0.8em',
    margin: '0.25em 0.5em 0.25em 0',
    padding: '0 1em'
  },
  searchWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifySelf: 'end',
    marginRight: '0.5em',
    alignItems: 'flex-end'
  },
  searchInput: {
    width: 'unset',
    minWidth: '250px',
    marginRight: '0.5em'
  }
};

export const ticketAdvancedFiltersCss = {
  ticketFilterBar: {
    ...ticketFiltersCss.ticketFilterBar,
    padding: '0.5em',
    backgroundColor: 'var(--color-grey)'
  },
  title: {
    ...ticketListCss.title,
    fontSize: '1.5rem',
    position: 'relative',
    width: 'fit-content',
    marginBottom: '0px'
  }
};

export const dropzoneCSS = {
  wrap: {
    flex: '1',
    display: 'flex',
    flexWrap: 'wrap'
  },
  box: {
    minHeight: '200px',
    minWidth: '220px',
    backgroundColor: 'var(--color-bg)',
    border: '2px dashed var(--color-hr)',
    alignItems: 'center',
    justifyContent: 'center',
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    fontSize: '16px',
    ...borderRadius()
  },
  icon: {
    opacity: '0.3',
    height: '64px',
    width: '64px',
    margin: '0'
  },
  fileInput: {
    opacity: '0',
    height: '0'
  },
  highlight: {
    backgroundColor: 'rgb(188, 185, 236)'
  }
};

export const progressBarCSS = {
  wrap: {
    display: 'flex',
    flex: '1',
    flexDirection: 'row',
    alignItems: 'center'
  },
  checkmark: {
    opacity: '0.5',
    marginLeft: '32px'
  },
  barWrap: {
    width: '100%',
    height: '8px',
    backgroundColor: 'rgb(183, 155, 229)',
    ...borderRadius()
  },
  bar: {
    backgroundColor: 'rgba(103, 58, 183, 1)',
    height: '100%',
    margin: '0',
    ...borderRadius()
  }
};

export const uploadCSS = {
  wrap: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: '100%',
    alignItems: 'flex-start',
    textAlign: 'left',
    overflow: 'hidden',
    position: 'relative'
  },
  title: {
    marginBottom: '32px',
    color: '#555',
    flex: '100%'
  },
  content: {
    flex: '1',
    boxSizing: 'border-box',
    width: '100%',
    minWidth: '220px',
    overflowY: 'auto',
    position: 'relative',
    paddingBottom: '40px'
  },
  files: {
    width: '100%',
    overflowY: 'auto'
  },
  actions: {
    position: 'absolute',
    bottom: '0',
    left: '0',
    width: '100%'
  },
  filename: {
    zIndex: '1',
    fontSize: '1.4rem',
    color: '#555',
    paddingLeft: '0.5em'
  },
  entryTitle: {
    fontWeight: 'bold',
    paddingLeft: '10px'
  },
  row: {
    paddingLeft: '10px',
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '32px',
    overflow: 'hidden',
    boxSizing: 'border-box'
  },
  odd: {
    background: 'var(--color-data-border)'
  }
};
